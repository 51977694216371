import OptionTab from "../option_tab.component";
import Activity from "./activity.component";
import Paginator from "./paginator.component";

const ActvityLogTable = ({ data }) => {
    const tabOptions = [
        {
            option: "S/N"
        },
        {
            option: "Email address"
        },
        {
            option: "Role"
        },
        {
            option: "Login Date"
        },
        {
            option: "Login Time"
        },
        {
            option: "Action perfomed"
        },
    ];

    return (
        <>
            <div className="w-full " >

                <OptionTab options={tabOptions} />
                {data?.map(({ email, role, login_date, login_time, action_perfomed }, index) => (
                    <Activity key={Math.random() * 1000 + index} email={email} role={role} login_date={login_date} login_time={login_time} action_performed={action_perfomed} serialNo={index + 1} />
                ))}
                <Paginator />
            </div>
        </>
    );
}

export default ActvityLogTable;