import { useState } from "react";
import Button from "../../../components/common/button/button.component";
import ActvityLogTable from "../../../components/pages/superAdmin/activity_log/activity_log_table.component";
import TitleBar from "../../../components/pages/superAdmin/title_bar.component";
import { downloadCSV } from "../../../services/downloadCSV";

const ActivityLog = () => {
    const initialData = [
        {
            email: "john.doe@example.com",
            role: "Admin",
            login_date: "10/12/25",
            login_time: "09:30 am",
            action_perfomed: "Updated user settings",
        },
        {
            email: "jane.smith@example.com",
            role: "User",
            login_date: "10/12/25",
            login_time: "10:45 am",
            action_perfomed: "Viewed dashboard",
        },
        {
            email: "alice.jones@example.com",
            role: "Staff",
            login_date: "10/12/25",
            login_time: "12:15 pm",
            action_perfomed: "Added a new record",
        },
        {
            email: "bob.miller@example.com",
            role: "User",
            login_date: "10/12/25",
            login_time: "02:00 pm",
            action_perfomed: "Logged out",
        },
        {
            email: "charlie.wilson@example.com",
            role: "Admin",
            login_date: "10/12/25",
            login_time: "04:30 pm",
            action_perfomed: "Deleted user account",
        },

    ];

    const [data, setData] = useState(initialData);
    const [sortBy, setSortBy] = useState('None');


    const handleSortByChange = (event) => {
        const selectedSortBy = event.target.value;
        setSortBy(selectedSortBy);

        // Perform sorting based on the selected criteria
        if (selectedSortBy !== 'None') {
            const sortedData = [...initialData]?.sort((a, b) => {
                // Customize the sorting logic based on your requirements
                return a[selectedSortBy]?.localeCompare(b[selectedSortBy]);
            });

            setData(sortedData);
        } else {
            // Reset the data to the initial state if 'None' is selected
            setData(initialData);
        }
    };

    return (
        <>
            <div className="w-full h-full mb-24 pt-5 "  >

                <div className="w-full flex flex-row items-center justify-between" >
                    <div className=" w-2/3 " >  <TitleBar title={'Activity Log'} /></div>
                    <div className=" w-2/6 " >
                        <div className="w-1/2" >
                            <p className="font-bold text-base text-left" >Sort By</p>

                        </div>
                        <div className="w-full flex flex-row items-center justify-between">
                            <input
                                className="w-2/5 border p-3 outline-none rounded"
                                value={sortBy}
                                onChange={handleSortByChange}
                            />
                            <Button
                                onClick={() => {
                                    downloadCSV(data);
                                }}
                                btnTitle={"Download log"}
                                className={' bg-primary_color p-3 w-2/5 text-white text-xs rounded-lg'}
                            />
                        </div>
                    </div>
                </div>


                <ActvityLogTable data={data} />
            </div>

        </>
    );
}

export default ActivityLog;