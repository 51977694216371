export const sideBarLinks =[
    {
        name:"Dashboard",
        link:"/employee/dashboard"
    },
    {
        name:"HR",
        link:"/employee/hr"
    },
    {
        name:"Inventory",
        link:"/employee/dashboard"
    },
    {
        name:"Maintenance",
        link:"/employee/dashboard"
    },
    {
        name:"StellaPay",
        link:"/employee/dashboard"
    },
    {
        name:"Transportation",
        link:"/employee/dashboard"
    },
    {
        name:"Accounting",
        link:"/employee/dashboard"
    },
    {
        name:"Others",
        link:"/employee/dashboard"
    },
    {
        name:"Delete Account",
        link:"/employee/deleteaccount"
    },
    {
        name:"Log Out",
        link:"/"
    }
]