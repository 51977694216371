export const navlinks = [
  {
    name: "Home",
    path: "/"
  },
  {
    name: "Pricing",
    path: "/pricing"
  },
  {
    name: "Star Tracker",
    path: "/star"
  },
  {
    name: "Support",
    path: "/support"
  },
  {
    name: "Login",
    path: "/login"
  },
  {
    name: "Sign up",
    path: "/signup"
  }
];