import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../redux/slices/logout_slice"; // Correct import for logout
import { clearRegisterToken } from "../../../../redux/slices/register_slice"; // Correct import for clearing register token
import { FaSignOutAlt } from 'react-icons/fa';
import { CloseIcon, UserIcon } from '../../../common/icons/icons'; // Import the icons
import Logout from '../logout'; // Assuming you have this component
import { sideBarLinks } from "./sidebar_links";
import "./layout.css";

const SuperAdminSideBar = ({ toggleSideBar, setToggleSideBar }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    const handleLogout = async () => {
        try {
            // Dispatch the logoutUser action to clear login.logintoken, register.token, and selectedPlan
            await dispatch(logoutUser()).unwrap();
            // Redirect to the login page after logout
            navigate('/');
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    const openLogoutModal = (e) => {
        e.preventDefault();
        setIsLogoutModalOpen(true);
    };

    const closeLogoutModal = () => {
        setIsLogoutModalOpen(false);
    };

    const confirmLogout = () => {
        handleLogout();
        closeLogoutModal();
    };

    return (
        <>
            <aside className={`transition-transform ${!toggleSideBar ? 'translate-x-0' : '-translate-x-full'} h-full absolute z-50 overflow-x-hidden md:translate-x-0  md:relative md:z-0 lg:z-0 lg:relative bg-gradient-to-b from-primary_color via-primary_color to-pink-500 md:${toggleSideBar ? 'block' : 'hidden'} lg:w-1/5 p-2 lg:rounded-r-2xl overflow-y-auto pb-5 scrollbar-hidden`}>
                <div className="w-full flex items-center justify-between mt-12">
                    <CloseIcon onClick={() => { setToggleSideBar(!toggleSideBar) }} color={'#e7e7e7'} style={{ cursor: 'pointer' }} className={'absolute top-5 right-5 block md:hidden lg:hidden'} />
                    <div className="w-2/5 justify-end flex pr-4">
                        <div className="w-70px bg-white rounded-50% h-70px justify-center items-end overflow-hidden flex border-orange border-4">
                            <UserIcon size={50} color={'#e7e7e7'} />
                        </div>
                    </div>
                    <div className="w-3/5">
                        <p className="font-semibold text-base text-white">Hello,</p>
                        <p className="font-bold text-lg text-white"> Admin</p>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p style={{ fontSize: 10 }} className="text-white text-center">Welcome to the overview of your account</p>
                </div>

                <div className="w-full mt-6 pr-3 pl-3 relative">
                    {sideBarLinks.map(({ name, link }) => (
                        name === "Log Out" ? (
                        <Link
                            key={name}
                            to="/"
                            onClick={openLogoutModal}
                            className="w-full text-white bg-red-500 rounded-xl cursor-pointer justify-between items-center flex p-3 mb-3 hover:bg-red-600 hover:text-white relative hover:font-bold   hover:-left-8  hover:justify-evenly hover:w-282px"
                        >
                            <span>{name}</span>
                            <FaSignOutAlt className="ml-2" />
                        </Link>
                        ) : (
                        <Link
                            key={name}
                            to={link}
                            style={{ borderBottomWidth: '0.1px' }}
                            className={`w-full text-white border-faint_grey rounded-lg cursor-pointer justify-start items-center flex p-3 mb-3 hover:bg-white hover:text-primary_color relative hover:-left-8 hover:font-bold hover:w-282px hover:justify-center ${location.pathname === link ? 'font-bold text-primary_color' : ''}`}
                        >
                            {name}
                        </Link>
                        )
                    ))}
                    </div>

                <div className="mt-6 w-full flex justify-center items-center flex-col">
                    <div style={{ width: '40px', height: "40px", borderRadius: "50%", backgroundColor: 'white', display: "flex", justifyContent: "center", alignItems: "center", color: 'black' }}>
                        <p style={{ fontSize: 12 }} className="text-center text-black font-extrabold">
                            Logo
                        </p>
                    </div>
                    <p style={{ fontSize: 12 }} className="text-center text-white font-extrabold mt-4">
                        Name of School
                    </p>
                </div>
            </aside>

            {/* Logout Modal */}
            {isLogoutModalOpen && (
                <Logout
                    onClose={closeLogoutModal}
                    onConfirm={confirmLogout}
                />
            )}
        </>
    );
};

export default SuperAdminSideBar;
