import React, { useState, useEffect, useRef } from "react";
import { HorizontalMenu } from "../../../../common/icons/icons";
import Refund from "../refund/Refund"; // Ensure the path is correct

const TableInfoPayment = ({ serialNo, amount, user, date, plan, status }) => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false); // State to manage modal visibility
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setToggleMenu(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isRefundModalOpen) {
            // Disable scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling
            document.body.style.overflow = 'auto';
        }

        // Cleanup on component unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isRefundModalOpen]);

    const openRefundModal = () => {
        setIsRefundModalOpen(true);
        setToggleMenu(false); // Close the menu when opening the modal
    };

    const closeRefundModal = () => {
        setIsRefundModalOpen(false);
    };

    const handleRefundSave = () => {
        // Handle refund save logic
        closeRefundModal();
    };

    return (
        <>
            <div className="w-[700px] md:w-full mt-5 rounded-xl bg-gray-100 flex p-4 items-center justify-between relative">
                <div className="w-[5%] flex justify-start">
                    <p className="text-sm font-medium">{serialNo}</p>
                </div>
                <div className="w-[16%] flex justify-start">
                    <p className="text-sm font-medium">{amount}</p>
                </div>
                <div className="w-[21%] flex justify-start">
                    <p className="text-sm font-medium">{user}</p>
                </div>
                <div className="w-[16%] flex justify-start">
                    <p className="text-sm font-medium">{date}</p>
                </div>
                <div className="w-[16%] flex justify-start">
                    <p className="text-sm font-medium">{plan}</p>
                </div>
                <div className="w-[16%] flex justify-start">
                    <p className="text-sm font-medium">{status}</p>
                </div>
                <div className="w-[10%] flex justify-center items-center">
                    <HorizontalMenu cursor="pointer" onClick={() => setToggleMenu(!toggleMenu)} />
                </div>

                <div ref={menuRef} className={`${toggleMenu ? 'block' : 'hidden'} bg-white rounded-xl p-3 absolute z-10 shadow-lg shadow-gray-400 right-3`}>
                    <p onClick={openRefundModal} className="font-bold text-xs cursor-pointer text-black">Refund</p>
                </div>
            </div>

            {isRefundModalOpen && (
                <div style={{ position: 'fixed', inset: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 50 }}>
                    <Refund onClose={closeRefundModal} onSave={handleRefundSave} />
                </div>
            )}
        </>
    );
};

export default TableInfoPayment;
