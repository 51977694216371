import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../../common/button/button.component";
import { MailIcon, LockIcon } from "../../common/icons/icons";
import { setVerificationData } from '../../../redux/slices/otpverification_slice';

const VerifyForgetPassword = () => {
  const [otp, setOtp] = useState('');  // Holds the OTP entered by the user
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // State to track button loading

  // Retrieves the email stored in the Redux store (likely from a previous step)
  const email = useSelector((state) => state.forgetPassword.forgetPasswordEmail);

  // Function to handle OTP verification
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading to true when request starts

    try {
      // Makes a POST request to verify the OTP
      const response = await axios.post('https://api.mystarsonline.com/api/Auth/VerifyOtp', {
        email,
        otp,
      });

      // If successful, extract the token from the response
      const token = response.data.data;

      // Dispatch the token to the Redux store
      dispatch(setVerificationData({ token }));

      // Show success toast and navigate to the reset page after 2 seconds
      toast.success("OTP verification successful");

      setTimeout(() => {
        navigate('/reset');
      }, 2000);

    } catch (error) {
      // Display the server-provided error message or statusText if available
      const errorMessage = error.response?.data?.message || error.response?.statusText || error.message || "OTP verification failed";
      toast.error(errorMessage);
      setIsLoading(false); // Return button to normal state

    }
  };

  // Function to handle Resend OTP
  const handleResendOtp = async () => {
    try {
      // Makes a POST request to resend the OTP
      const response = await axios.post('https://api.mystarsonline.com/api/Auth/ResendOtp', {
        email,
      });

      // Show success toast with the server-provided message or a default success message
      toast.success(response.data.message || "OTP resent successfully to your email");

    } catch (error) {
      // Log error for debugging
      console.error("Resend OTP Error:", error);

      // Check for status text if no specific message is available
      const errorMessage = error.response?.data?.message || error.response?.statusText || error.message || "Failed to resend OTP";
      
      // Show the error message in the toast
      toast.error(errorMessage);
    }
  };

  return (
    <>
      {/* ToastContainer is used to display toast notifications */}
      <ToastContainer position='top-center' autoClose={2000} theme='light' pauseOnHover={false} />

      <div className=''>
        {/* Form to verify OTP */}
        <form className="bg-[#616161] shadow-2xl opacity-60 rounded-xl py-7 px-8 border md:py-7 md:px-8 xl:py-8 xl:px-10 2xl:py-20 2xl:px-20" onSubmit={handleSubmit}>
          <div className="flex flex-col justify-center text-center pt-5 mb-2 md:pt-0">
            <h4 className="font-bold text-center mb-6 text-2xl md:text-2xl xl:text-3xl 2xl:text-5xl text-[#fff]">Verify OTP</h4>
            <span className='flex flex-col justify-center text-[#fff] font-thin text-base md:text-base xl:text-lg 2xl:text-3xl'>
              <p>Please input your OTP from your </p>
              <p>email</p>
            </span>
          </div>

          {/* Email display (read-only) */}
          <div className="w-full flex items-center flex-col mb-2 mt-4 space-y-4 md:space-y-4 xl:space-y-3 2xl:space-y-6">
            <div className="w-full">
              <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                <div className="w-1/6 2xl:text-3xl">
                  <MailIcon color="black" />
                </div>
                <div className="w-full">
                  <input
                    type="email"
                    value={email}  // Display the email from the Redux store
                    readOnly
                    className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px] bg-transparent"
                    placeholder="Email Address"
                    required
                  />
                </div>
              </div>
            </div>

            {/* OTP input */}
            <div className="w-full">
              <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                <div className="w-1/6 2xl:text-3xl">
                  <LockIcon color="black" />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}  // Update the OTP state
                    pattern="\d*"  // Allow only digits
                    className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px] bg-transparent font-extralight"
                    placeholder="Input Code"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Submit button */}
           {/* Button with spinner */}
           <div className="w-full flex items-center justify-center pt-3 mb-1 cursor-pointer">
                        <div className={`w-full border border-primary_color rounded-xl p-1 text-sm flex items-center text-center text-primary_color font-semibold transition-all duration-300 md:text-sm xl:text-base 2xl:text-xl ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-white hover:bg-black hover:text-white'}`}>
                            <Button
                                type="submit"
                                className="w-full"
                                btnTitle={isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <svg className="animate-spin h-5 w-5 text-primary_color" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0h-2a6 6 0 00-12 0H4z"></path>
                                        </svg>
                                        <span>Loading...</span>
                                    </div>
                                ) : "Proceed"}
                                disabled={isLoading} // Disable button when loading
                            />
                        </div>
                    </div>

          {/* Resend OTP link */}
          <div className="w-full flex items-center justify-center pt-1 xl:pt-5 ">
            <button
              className="text-center text-[12px] md:text-[14px] lg:text-[14px] xl:text-[16px] 2xl:text-[22px] text-[#fff] cursor-pointer"
              onClick={handleResendOtp}  // Trigger resend OTP on click
            >
              Resend <span className=''>Otp</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default VerifyForgetPassword;
