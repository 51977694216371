import React from 'react'
import { FaUsers } from "react-icons/fa";
import { RiGraduationCapFill } from "react-icons/ri";
const Three = () => {
  return (
    <div>
        <div className='grid grid-cols-2 md:grid md:grid-cols-3 gap-3'>
            <div className='flex flex-row justify-between text-center items-center bg-[#f9fafb] border shadow-md p-4 '>
                <span className='flex flex-col text-start '>
                <h1><FaUsers className='text-4xl font-bold'/></h1>
                <h2 className='2xl:text-2xl 2xl:pt-3'>Users</h2>
                </span>
                <span className='text-[#9835D6] text-2xl 2xl:text-3xl font-bold'>
                        359
                </span>
            </div>
            <div className='flex flex-row justify-between text-center items-center bg-[#f9fafb] border shadow-md p-4 '>
                <span className='flex flex-col text-start '>
                <h1><RiGraduationCapFill className='text-4xl font-bold'/></h1>
                <h2 className='2xl:text-2xl 2xl:pt-3'>Active Schools</h2>
                </span>
                <span className='text-[#00AF46] text-2xl  2xl:text-3xl font-bold'>
                        120
                </span>
            </div>
            <div className='flex flex-row justify-between text-center items-center bg-[#f9fafb] border shadow-md p-4 '>
                <span className='flex flex-col text-start '>
                <h1><RiGraduationCapFill className='text-4xl font-bold'/></h1>
                <h2 className='2xl:text-2xl 2xl:pt-3'>InActive Schools</h2>
                </span>
                <span className='text-[#FF005C] text-2xl  2xl:text-3xl font-bold'>
                        35
                </span>
            </div>
        </div>
    </div>
  )
}

export default Three