import React from 'react';

const Terms = () => {
    return (
        <>
            <div className="px-10 w-full h-full md:px-24 py-6 pt-20">
                <div className="w-full mt-5 flex justify-center items-center">
                    <h1 className={"text-4xl text-center text-black font-bold"}>Terms and Conditions</h1>
                </div>

                <div className="w-full mt-6">
                    <p className="text-sm leading-7 mt-2 text-black font-medium">
                        Welcome to MyStar Online. By downloading, installing, or using our mobile application, you agree to be bound by these Terms and Conditions. Please read them carefully.
                    </p>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        1. Acceptance of Terms
                    </p>
                    <p className="text-sm leading-7 mt-2 text-black font-medium">
                        By accessing and using MyStar Online, you agree to comply with these Terms and Conditions and our Privacy Policy. If you do not agree with these terms, please do not use the app.
                    </p>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        2. Use of the App
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">Eligibility: You must be at least 13 years old to use MyStar Online. If you are under 18, you must have the consent of a parent or guardian.</p>
                        <p className="text-sm text-black font-medium mt-2">Account Registration: You may be required to register for an account to access certain features. You agree to provide accurate and complete information during registration and keep your account information updated.</p>
                        <p className="text-sm text-black font-medium mt-2">Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account.</p>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        3. User Conduct
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">You agree not to use MyStar Online to:</p>
                        <ul className="w-full mt-2">
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Violate any local, state, national, or international law.</li>
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Infringe upon the intellectual property rights of others.</li>
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Harass, abuse, or harm another person.</li>
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Upload or distribute viruses or any other malicious code.</li>
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Interfere with the operation or security of the app.</li>
                        </ul>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        4. Intellectual Property
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">All content, features, and functionality of MyStar Online, including text, graphics, logos, and software, are the exclusive property of MyStar Online or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        5. Payments and Subscriptions
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">Subscriptions: Some features of MyStar Online may require a subscription. By purchasing a subscription, you agree to pay the applicable fees and taxes.</p>
                        <p className="text-sm text-black font-medium mt-2">Billing: You authorize us to charge your payment method for any paid features or subscriptions you choose to purchase.</p>
                        <p className="text-sm text-black font-medium mt-2">Cancellation: You can cancel your subscription at any time, but refunds are subject to our refund policy.</p>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        6. Termination
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">We reserve the right to suspend or terminate your access to MyStar Online at any time, with or without cause, and with or without notice. Upon termination, your right to use the app will immediately cease.</p>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        7. Limitation of Liability
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">To the maximum extent permitted by law, MyStar Online and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
                        <ul className="w-full mt-2">
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Your use of or inability to use the app.</li>
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Any interruption or cessation of transmission to or from the app.</li>
                            <li className="list-disc left-5 relative text-black text-sm mb-3">Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the app by any third party.</li>
                        </ul>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        8. Dispute Resolution
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">Any disputes arising out of or relating to these Terms and Conditions or the use of MyStar Online shall be resolved through binding arbitration in accordance with the rules of [Arbitration Association]. The arbitration shall be conducted in [Location], and the arbitrator’s decision shall be binding and may be entered as a judgment in any court of competent jurisdiction.</p>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        9. Changes to Terms
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting the revised terms in the app. Your continued use of the app after the changes are posted constitutes your acceptance of the new terms.</p>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        10. Governing Law
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">These Terms and Conditions shall be governed by and construed in accordance with the laws of [State/Country], without regard to its conflict of law principles.</p>
                    </div>
                </div>

                <div className="w-full mt-6">
                    <p className="text-xl text-black font-bold">
                        11. Contact Us
                    </p>
                    <div className="w-full mt-2">
                        <p className="text-sm text-black font-medium">If you have any questions about these Terms and Conditions, please contact us at info@mystaronline.com.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Terms;
