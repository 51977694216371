import React from "react";

import software3 from "../../../assets/images/science_class_photo.jpg";
import software4 from "../../../assets/images/class_calendar_photo.jpg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

const Solution = () => {
	return (
		<>
			<div className="w-full my-10 lg:my-0 lg:mb-10 overflow-x-hidden ">
				<div className="w-full ">
					<p className="  text-center px-2 sm:px-0 font-bold my-3 sm:my-6 md:my-16">
						{/* <p className="px-10 text-xl text-center font-bold leading-5 md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-6xl capitalize"> */}
						BEST EDUTECH SOLUTION FOR YOUR SCHOOL
					</p>
				</div>

				{/* Splide slider */}
				<Splide
					options={{
						rewind: true,
						perPage: 1, // Show one image at a time
						gap: "2rem",
						arrows: true,
						pagination: true, // Enable pagination
						autoplay: true, // Enable autoplay
						interval: 3000, // Time between slides (in milliseconds)
					}}
				>
					<SplideSlide>
						<img
							alt="software-1"
							src={software3}
							className="w-full object-contain md:object-cover md:h-80 lg:h-auto"
						/>
					</SplideSlide>
					<SplideSlide>
						<img
							alt="software-2"
							src={software4}
							className="w-full object-contain md:object-cover md:h-80 lg:h-auto"
						/>
					</SplideSlide>
				</Splide>
			</div>
		</>
	);
};

export default Solution;
