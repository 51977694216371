import UserTypeForm from "../../../components/pages/login/user_type_form.component";
const Login = () => {
  
  const options = [
    {
      name: "Employee",
      route: "/employee",
    },
    {
      name: "Student",
      route: "/student/dashboard/profile",
    },
    {
      name: "Parent",
      route: "/parent/dashboard/profile",
    },
    {
      name: "Student Qr CODE",
      route: "/employee",
    },
  ]
  return (
    <>
      <div className="w-full h-full flex justify-end px-14" >
        <div className="  " >
          <UserTypeForm />
        </div>  
      </div>

    </>
  );
}

export default Login;