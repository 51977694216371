import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import login from '../../api/admin/login';

export const loginUser = createAsyncThunk('login/loginUser', async ({ email, password }, { rejectWithValue }) => {
  try {
    const data = await login(email, password);
    return data;
  } catch (error) {
    return rejectWithValue(error.message); // Capture and return the error message
  }
});

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: null,
    logintoken: null, // Store login token
    hasActiveSubscription: null, // Will be updated based on API response
    status: 'idle',
    error: null
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.logintoken = null; // Clear the token on logout
      state.hasActiveSubscription = null; // Clear the subscription status on logout
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null; // Clear previous errors
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload && action.payload.data) {
          state.user = action.payload.data;
          state.logintoken = action.payload.data.token; // Assign the token
          state.hasActiveSubscription = action.payload.data.hasActiveSubscription; // Assign hasActiveSubscription
        }
        state.error = null; // Clear previous errors
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; // Capture error message
      });
  }
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
