import { useNavigate } from "react-router-dom";
import login1 from "../assets/images/login1.jpg";
import backarrow from "../assets/images/backarrow.png"
const AuthLayout = ({ children }) => {
    const navigate = useNavigate();

    return (
        <div 
            className="bg-cover bg-center min-h-screen flex flex-col"
            style={{
                backgroundImage: `url(${login1})`,
            }}
        >
            <div className="bg-black/60  min-h-screen w-full flex flex-col">
                <div className="flex-none px-4">
                    <img 
                        src={backarrow} 
                        className="w-[40px] h-[32px] object-contain cursor-pointer" 
                        alt="logo" 
                        onClick={() => navigate('/')}
                    />
                </div>
                <div className="flex-grow flex items-center justify-center md:justify-center lg:justify-end 2xl:justify-end">
                    <div className=" ">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;
