import React from 'react';

const AboutTab = () => {
  return (
    <div className="p-6 ">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-semibold text-black mb-1">Body 1</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              className="w-full p-2"
              placeholder="Lorem Ipsum torem"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-semibold text-black mb-1">Body 2</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              className="w-full p-2"
              placeholder="Lorem Ipsum torem"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-semibold text-black mb-1">Body 3</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              className="w-full p-2"
              placeholder="Lorem Ipsum torem"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-semibold text-black mb-1">Body 4</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              className="w-full p-2"
              placeholder="Lorem Ipsum torem"
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-6">
        <div className="border border-gray-300 rounded-xl p-8  flex justify-center items-center">
          <div className="text-center">
            <img src="" alt="Upload" className="w-8 h-8 mx-auto" />
            <p className="text-gray-500 text-sm mt-2">upload image</p>
          </div>
        </div>
        <div className="border border-gray-300 rounded-xl p-8 flex justify-center items-center">
          <div className="text-center">
            <img src="" alt="Upload" className="w-8 h-8 mx-auto" />
            <p className="text-gray-500 text-sm mt-2">upload image</p>
          </div>
        </div>
        <div className="border border-gray-300 rounded-xl p-8 flex justify-center items-center">
          <div className="text-center">
            <img src="" alt="Upload" className="w-8 h-8 mx-auto" />
            <p className="text-gray-500 text-sm mt-2">upload image</p>
          </div>
        </div>
        <div className="border border-gray-300 rounded-xl p-8 flex justify-center items-center">
          <div className="text-center">
            <img src="" alt="Upload" className="w-8 h-8 mx-auto" />
            <p className="text-gray-500 text-sm mt-2">upload image</p>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6 space-x-3">
        <button type="button" className="bg-primary_color text-white px-4 py-2 rounded-xl">Save</button>
        <div className="border border-primary_color px-4 py-1 rounded-xl inline-block">
          <button type="button" className="text-primary_color w-full h-full">Reset</button>
        </div>
      </div>
    </div>
  );
};

export default AboutTab;
