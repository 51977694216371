import ContactForm from "./contact_form.component";

const ContactUs = () => {
    return (
        <>
            <div className="px-6 w-full md:px-6 lg:px-20 pb-10" >
            <div className="w-full bg-soft_grey mt-14 p-6 rounded-xl shadow-2xl " >
                <div className="w-full" >
                    <h3 className="text-left text-lg font-bold md:text-2xl lg:text-4xl " >
                      Contact Us
                    </h3>
                </div>

                <ContactForm/>
            </div>
            </div>
        </>
    );
}

export default ContactUs;