export const contactList = [
  {
    name: "Home"
  },
  {
    name: "Features"
  },
  {
    name: "Blog"
  },
  {
    name: "Patner"
  },
  {
    name: "Privacy Policy",
    path:"/privacy-policy"
  },
  {
    name: "Terms of Service",
    path:"/terms-conditions"
  },
  {
    name: "Software Service Agreement"
  },
  {
    name: "Delete Account",
    path:"delete-account"
  }
];
