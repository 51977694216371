import React from "react";
import software1 from "../../../assets/images/bullets_1.jpg";
import software2 from "../../../assets/images/image 6 (2).png";
import { Link } from "react-router-dom";
function CTASection() {
	return (
		<div className="w-full b-[#F9FAFB] flex flex-col my-10 sm:my-20  bg-[#F9FAFB]   lg:my-16 px-4 md:px-14">
			{/* <div className="relative flex py-20 z-10">
        <div className="w-full xl:w-1/2 bg-transparent flex flex-col items-start justify-center px-10 xl:px-0 xl:pl-14 2xl:pl-32 2xl:space-y-3 z-30">
          <h1 className="text-xl  md:text-xl xl:text-xl 2xl:text-4xl leading-[1.125] font-semibold md:font-black mb-2">
            Focus on what matters most - <span className="text-[#9835D6]">your students</span> with features
            that guarantee your peace of mind
          </h1>
          <h2 className="text-xl md:text-2xl xl:text-xl 2xl:text-4xl 2xl:pt-4 leading-[1.125] font-normal capitalize">
            Ready to unlock stellar potential of your school?
          </h2>
          <h3 className="text-xl xl:text-xl 2xl:text-4xl md:text-2xl font-bold text-starOath">
            Unleash a Galaxy of Benefits
          </h3>
          <ul className="text-xs md:text-sm xl:text-sm lg:text-base mt-5 space-y-4 lg:space-y-2 xl:pt-5 2xl:space-y-3 2xl:text-2xl">
            <li className=''>
              <span className="font-semibold pr-1">Effortless Administration:</span>
              Ditch the paperwork and automate tasks with a user-friendly system
              that manages student records, attendance, communication, and more.
            </li>
            <li>
              <span className="font-semibold pr-1">Boost Efficiency, Light Years Ahead:</span>
              Free up valuable time and resources for your staff, allowing them
              to dedicate their energy to student success.
            </li>
            <li>
              <span className="font-semibold pr-1">Data-Driven Decisions, Brighter Than a Supernova:</span>
              Gain valuable insights from comprehensive reports to personalize
              learning experiences and optimize school operations.
            </li>
            <li>
              <span className="font-semibold pr-1">Strengthen the Communication Constellation:</span>
              Foster seamless communication between teachers, parents, and
              students with robust tools that keep everyone connected.
            </li>
            <li>
              <span className="font-semibold pr-1">Scalability for Every Orbit:</span>
              Whether you're a small school or a sprawling campus,
              MyStar's secure, cloud-based platform adapts to your needs.
            </li>
          </ul>
          <div className='pt-5  2xl:pt-10'>
          <Link to="/pricing" className={
          "hover:border-[#9835D6] hover:bg-white  hover:text-[#9835D6] hover:border bg-primary_color p-2 mt-4  w-48 px-6  self-center text-sm text-white rounded-2xl cursor-pointer text-center md:text-start  md:self-start md:w-auto md:py-2 lg:w-46 2xl:px-10 2xl:text-[26px] 2xl:py-4"
        }>
          Get Started
          </Link>
          </div>

        </div>
        <div className="absolute top-0 left-0 right-0 bottom-0   bg-transparent  xl:relative w-full  lg:w-1/2">
          <img
            src={software1}
            alt="Background image"
            className="w-full  object-contain opacity-20 xl:opacity-100 rounded-xl"
          />
        </div>
      </div> */}
			<h2 className="text-center w-full font-bold px-2 text-sm sm:text-xl lg:text-2xl  ">
				Focus on what matters most -{" "}
				<span className="text-primary_color">your students</span> with features
				that guarantee your peace of mind
			</h2>

			<div className="w-full gap-5 flex flex-col sm:flex-row  sm:max-h-[40rem] p-2 sm:p-5  ">
				<div className="w-full  flex flex-col justify-center sm:px-10  ">
					<h3 className="text-primary_color font-bold ">
						Effortless Administration:{" "}
					</h3>
					<p>
						Ditch the paperwork and automate tasks with a user-friendly system
						that manages student records, attendance, communication, and more.
					</p>
				</div>
				<div className="w-full h-full  overflow-hidden sm:p-10 flex justify-center items-center ">
					<div className="  bg-white rounded-xl p-2  sm:p-10">
						<img
							src={software1}
							alt="Background image"
							className="w-full  object-contain rounded-xl"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CTASection;
