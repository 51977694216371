import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Paginator from "../../../superAdmin/activity_log/paginator.component";
import OptionTabPayment from "./option_tab";
import TableInfoPayment from "./Table";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton';
const Verified = () => {
    const token = useSelector(state => state.login.logintoken); // Access token from Redux
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [data, setData] = useState([]); // State to hold API data
    const [loading, setLoading] = useState(true); // State to manage loading state
    const pageSize = 5; // Set items per page

    const tabOptions = [
        { option: "S/N" },
        { option: "Amount" },
        { option: "User" },
        { option: "Date" },
        { option: "Plan" },
        { option: "Status" },
        { option: "Action" },
    ];

    const getStatusStyle = (status) => {
        return status === true ? "text-green" : "text-red-500";
    };

    // Fetch data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.mystarsonline.com/api/Subscription/get-tenant-subscriptions', {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                    },
                });
                if (response.data.code === 200) {
                    setData(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token]); // Fetch data when the component mounts and when the token changes

    // Calculate total pages based on the data length and page size
    const totalPages = useMemo(() => Math.ceil(data.length / pageSize), [data.length, pageSize]);

    // Get data for the current page
    const currentData = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        return data.slice(startIndex, startIndex + pageSize);
    }, [currentPage, data, pageSize]);

    // Handle Next and Previous page navigation
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    if (loading) {
        return <div><p className='pt-20'><Skeleton count={8} /> </p></div>; // Display loading state while fetching data
    }

    return (
        <div className="w-full mt-8 overflow-x-auto">
            <OptionTabPayment options={tabOptions} />
            {currentData.map((item, index) => (
                <TableInfoPayment
                    key={index}
                    serialNo={(currentPage - 1) * pageSize + index + 1} // Calculate serial number based on current page
                    amount={item.amount}
                    user={item.tenantName}
                    date={new Date(item.createdAt).toLocaleDateString()} // Convert date to readable format
                    plan={item.planName}
                    status={<span className={getStatusStyle(item.isPaymentSuccessful)}>{item.isPaymentSuccessful ? "successful" : "failed"}</span>}
                />
            ))}
            {/* Pagination Controls */}
            <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                totalItems={data.length}
                pageSize={pageSize}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
            />
        </div>
    );
};

export default Verified;
