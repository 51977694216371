const OptionTabAdminUserUnverified = ({ options }) => {
  const columnWidths = {
    "S/N": "5%",
    "Name": "21%",
    "Verified": "16%",
    "Package": "16%",
    "School": "16%",
    "Joined": "16%",
    "Action": "8%"
  };

  return (
    <div className="bg-primary_color mt-4 rounded-xl p-3 flex text-sm justify-between w-[700px] md:w-full ">
      {options.map(({ option }, index) => (
        <div 
          key={index} 
          style={{ width: columnWidths[option] || '15%' }} 
          className={`option-tab-item text-sm flex items-center`}
        >
          <p className="text-white text-sm 2xl:text-xl font-bold flex items-center">
            {option}
          </p>
        </div>
      ))}
    </div>
  );
};

export default OptionTabAdminUserUnverified;
