import { useState, useEffect } from "react";
import { HorizontalMenu } from "../../../common/icons/icons";
import Reply from './replyticket/reply'; // Assuming Reply is your modal component for full-screen usage

// Helper function to format dates as DD/MM/YYYY(HH:MM)
const formatDate = (dateString) => {
    if (!dateString || isNaN(new Date(dateString).getTime())) return "-"; // If dateString is null or invalid, return '-'
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year}(${hours}:${minutes})`;
};

const TableInfoTicket = ({ serialNo, ticket, email, subject, ticketstatus, updated, dateCreated }) => {
    const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility

    const handleStatusClick = () => {
        setIsModalOpen(true); // Open the full-screen modal when the status is clicked
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    // Prevent scrolling when modal is open
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling
        } else {
            document.body.style.overflow = 'auto'; // Restore scrolling
        }
    }, [isModalOpen]);

    return (
        <>
            <div className="w-[700px] md:w-full mt-5 rounded-xl bg-gray-100 flex p-4 items-center justify-between relative">
                <div className="w-[5%] flex justify-start">
                    <p className="text-sm font-medium">{serialNo}</p>
                </div>
                <div className="w-[12%] flex  justify-center ">
                    <p className="text-sm font-medium">{ticket || "-"}</p>
                </div>
                <div className="w-[18%]  flex overflow-hidden whitespace-nowrap text-ellipsis ">
                    <p className="text-sm font-medium">{email || "-"}</p>
                </div>
                <div className="w-[22%] justify-center flex overflow-hidden whitespace-nowrap text-ellipsis">
                    <p className="text-sm font-medium">{subject || "-"}</p>
                </div>
                <div className="w-[12%] flex justify-center ">
                    <p 
                        className="text-sm font-medium border border-primary_color px-2 py-1 text-primary_color rounded-xl cursor-pointer"
                        onClick={handleStatusClick} // Trigger modal on status click
                    >
                        {ticketstatus || "-"}
                    </p>
                </div>
                <div className="w-[15%] flex justify-center">
                    <p className="text-sm font-medium">{formatDate(updated)}</p>
                </div>
                <div className="w-[14%] flex justify-center">
                    <p className="text-sm font-medium">{formatDate(dateCreated)}</p>
                </div>
            </div>

            {/* Full-Screen Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50 overflow-hidden">
                    <div className="w-full h-full bg-white">
                        <Reply ticketNumber={ticket} subject={subject} email={email} ticketstatus={ticketstatus} dateCreated={dateCreated} updated={updated} onClose={handleCloseModal} />
                    </div>
                </div>
            )}
        </>
    );
};

export default TableInfoTicket;
