import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../../common/button/button.component";
import { LockIcon, MailIcon } from "../../common/icons/icons";

const VerifyForm = () => {
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // State to track button loading

  // Get the email from the Redux store (during registration)
  const email = useSelector((state) => state.register.email);

  // Get the saved plan from the Redux store
  const savedPlan = useSelector((state) => state.savedplan.selectedPlan);

  // Function to handle OTP verification
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading to true when request starts

    try {
      const response = await axios.post('https://api.mystarsonline.com/api/Signup/verify-otp-email', {
        email,
        code: otp,
      });

      const message = response.data.message || "OTP verification successful";
      toast.success(message);

      setTimeout(() => {
        // Check if a saved plan exists
        if (savedPlan) {
          // Navigate to the subscribe page with the saved plan
          navigate('/subscribe', { state: { plan: savedPlan.plan, isTrial: savedPlan.isTrial } });
        } else {
          // Navigate to the pricing page if no plan is saved
          navigate('/pricing');
        }
      }, 2000);

    } catch (error) {
      const errorMessage = error.response?.data?.message || "OTP verification failed";
      toast.error(errorMessage);
      setIsLoading(false); // Return button to normal state

    }
  };

  // Function to handle Resend OTP
  const handleResendOtp = async () => {
    try {
      const response = await axios.post('https://api.mystarsonline.com/api/Signup/resend-otp', {
        email,
      });

      // If the request is successful, display success message
      toast.success(response.data.message || "OTP resent successfully to your email");

    } catch (error) {
      console.error("Resend OTP Error:", error.response);
      // Extract the error message from the server response and display it in a toast
      const errorMessage = error.response?.data?.message || "Failed to resend OTP";
      toast.error(errorMessage);
    }
  };

  return (
    <>
      <ToastContainer position='top-center' autoClose={2000} theme='light' pauseOnHover={false} /> 
      <div className=''>
        <form className="bg-[#616161] shadow-2xl opacity-60 rounded-xl py-7 px-8 border md:py-7 md:px-8 xl:py-6 xl:px-10 2xl:py-20 2xl:px-20" onSubmit={handleSubmit}>
          <div className="flex flex-col justify-center text-center pt-5 mb-2 md:pt-0">
            <h4 className="font-bold text-center mb-6 text-2xl md:text-2xl xl:text-3xl 2xl:text-5xl text-[#fff]">Verify OTP</h4>
            <span className='flex flex-col justify-center text-[#fff] font-thin text-base md:text-base xl:text-lg 2xl:text-3xl'>
              <p>Please input your OTP</p>
              <p>from your email</p>
            </span>
          </div>

          {/* Email display (read-only) */}
          <div className="w-full flex items-center flex-col mb-2 mt-4 space-y-4 md:space-y-4 xl:space-y-3 2xl:space-y-6">
            <div className="w-full">
              <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                <div className="w-1/6 2xl:text-3xl">
                  <MailIcon color="black" />
                </div>
                <div className="w-full">
                  <input
                    type="email"
                    value={email}  // Display the email from the Redux store
                    readOnly
                    className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px] bg-transparent"
                    placeholder="Email Address"
                    required
                  />
                </div>
              </div>
            </div>

            {/* OTP input */}
            <div className="w-full">
              <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                <div className="w-1/6 2xl:text-3xl">
                  <LockIcon color="black" />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}  // Update the OTP state
                    pattern="\d*"  // Allow only digits
                    className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px] bg-transparent"
                    placeholder="OTP"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Button with spinner */}
          <div className="w-full flex items-center justify-center pt-3 mb-1 cursor-pointer">
                        <div className={`w-full border border-primary_color rounded-xl p-1 text-sm flex items-center text-center text-primary_color font-semibold transition-all duration-300 md:text-sm xl:text-base 2xl:text-xl ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-white hover:bg-black hover:text-white'}`}>
                            <Button
                                type="submit"
                                className="w-full"
                                btnTitle={isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <svg className="animate-spin h-5 w-5 text-primary_color" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0h-2a6 6 0 00-12 0H4z"></path>
                                        </svg>
                                        <span>Loading...</span>
                                    </div>
                                ) : "Proceed"}
                                disabled={isLoading} // Disable button when loading
                            />
                        </div>
                    </div>

          {/* Resend OTP link */}
          <div className="w-full flex items-center justify-center pt-1 xl:pt-5 ">
            <button
              className="text-center text-[12px] md:text-[14px] lg:text-[14px] xl:text-[16px] 2xl:text-[22px] text-[#fff] cursor-pointer"
              
              onClick={handleResendOtp}  // Trigger resend OTP on click
            >
              Resend <span className=''>Otp</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default VerifyForm;
