import { IoIosArrowRoundForward } from "react-icons/io"
import Button from "../../../components/common/button/button.component"
import TitleBar from "../../../components/pages/superAdmin/title_bar.component"
import { useNavigate, useNavigation } from "react-router-dom"
import LoginManagementTable from "../../../components/pages/superAdmin/login_management/login_management_table.component"
import { SearchIcon } from "../../../components/common/icons/icons"
const LoginManagement = () => {
    const navigate = useNavigate()
    const Actions = [
        {
            name: "Create New User",
            color: "#A63DF6",

        },
        {
            name: "Activity Log",
            color: "#D126FC",
            operation: () => {
                navigate("/employee/dashboard/login-management/activity-log")
            }
        },
        {
            name: "Security Settings",
            color: "#D272FF"
        },
    ]
    return (
        <>
            <div className={"flex flex-row items-baseline justify-between p-4 gap-5 "} >
                <div className="flex flex-col  w-9/12 " >
                    <div className="w-full flex flex-row items-center justify-between" >
                        <div className="  w-9/12 " >
                            <TitleBar title={'Login Management'} />
                        </div>
                        <div className=" w-1/4" >
                            <div className=" w-full md:w-2/5 lg:w-full  flex flex-row items-center justify-between border p-2 outline-none rounded" >
                                <input
                                    style={{ flex: 1 }}
                                    placeholder="Search parent"
                                    className="w-2/5 border-none outline-none  text-sm "

                                />
                                <SearchIcon />
                            </div>
                        </div>

                    </div>
                    <LoginManagementTable />
                </div>
                <div className="flex flex-col  w-1/4  ">

                    <div className="w-full mt-5" >
                        {Actions.map(({ name, color, operation }, index) => (
                            <div
                                onClick={operation}
                                key={index}
                                style={{ backgroundColor: color }}
                                className={` w-full md:w-2/5 lg:w-64 rounded-md p-5 mb-4 relative cursor-pointer`}
                            >
                                <p className="text-white text-sm uppercase">{name}</p>
                                <div className="w-full flex justify-end items-center mt-12 bottom-0 relative">
                                    <IoIosArrowRoundForward
                                        color="white"
                                        size={30}
                                        cursor={"pointer"}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}

export default LoginManagement;