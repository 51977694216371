import Activity from "../../superAdmin/activity_log/activity.component";
import OptionTab from "../../superAdmin/option_tab.component";
import Assignment from "./assignment.component";


const Assignment_Table = ({ data }) => {
    const tabOptions = [
        {
            option: "S/N"
        },
        {
            option: "Subject"
        },
        {
            option: "Start Date"
        },
        {
            option: "End Date"
        },
        {
            option: "Title"
        }
    ];



    return (
        <>
            <div className="w-full my-20 " >
                <div className="w-full mt-10 mb-5" >
                    <p className="font-bold text-base text-black" >Assignments & Projects</p>
                </div>
                <OptionTab options={tabOptions} />
                {data?.map(({ name, start_date, end_date, title }, index) => (
                    <Assignment serialNo={index + 1} name={name} start_date={start_date} end_date={end_date} title={title} />
                ))}

            </div>
        </>
    );
}

export default Assignment_Table;