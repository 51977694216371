const Button = ({ btnTitle, onClick, btnColor, icon, textColor, width, padding,className,disabled }) => {
  return (
    <button
    disabled={disabled}
    onClick={onClick}
      className={ className ? className : `${btnColor ? btnColor : "bg-primary_color"} ${
        width ? width : "w-fit"
      } ${padding ? padding : "p-3"} cursor-pointer text-base text-white`}
    >
      {btnTitle}
      {icon}
    </button>
  );
};

export default Button;
