import { useState, useEffect } from "react";
import { CloseIcon, MenuIcon } from "../icons/icons";
import Logo from "../logo/logo.component";
import { navlinks } from "./navlinks";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const NavBar = () => {
	const [toggleNavBar, setToggleNavBar] = useState(false);
	const logintoken = useSelector((state) => state.login.logintoken);
	const user = useSelector((state) => state.login.user);
	const hasActiveSubscription = useSelector(
		(state) => state.login.hasActiveSubscription
	);
	const navigate = useNavigate();

	// Disable scrolling when the navbar is toggled
	useEffect(() => {
		if (toggleNavBar) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [toggleNavBar]);

	// Function to close the navbar when clicking outside
	const handleOverlayClick = () => {
		setToggleNavBar(false);
	};

	// Determine dashboard link based on user role
	const getDashboardLink = () => {
		if (user && user.roles) {
			if (user.roles.includes("Super Admin")) {
				return "/admin/dashboard";
			} else if (user.roles.includes("Admin")) {
				return "/employee/dashboard";
			} else if (user.roles.includes("Parent")) {
				return "/login/parent/dashboard/profile";
			} else if (user.roles.includes("Student")) {
				return "/login/student/dashboard/profile";
			}
		}
		return "/";
	};

	return (
		<>
			<nav className="fixed w-full z-50 h-16 lg:h-24  bg-white flex justify-between items-center top-0  px-2 sm:px-10 lg:px-[5rem]">
				<div className=" flex justify-start items-center  ">
					{/* <div className="w-1/2 flex justify-start items-center md:w-1/3 lg:justify-center lg:w-1/5 2xl:w-1/2"> */}
					<Link to={"/"}>
						<Logo />
					</Link>
				</div>
				<div className=" flex justify-end items-center pr-4 lg:hidden">
					{toggleNavBar ? (
						<CloseIcon
							size={30}
							onClick={() => {
								setToggleNavBar(!toggleNavBar);
							}}
						/>
					) : (
						<MenuIcon
							size={30}
							onClick={() => {
								setToggleNavBar(!toggleNavBar);
							}}
						/>
					)}
				</div>
				{/* Regular Navbar for Medium and Larger Screens */}
				<div className="hidden lg:flex w-full lg:w-full justify-end ">
					{/* <div className="hidden lg:flex w-full lg:w-full justify-end md:pr-8 lg:pr-32 2xl:pr-[100px]"> */}
					<ul className="flex items-center w-full justify-end gap-10 font-bold">
						{navlinks
							.filter(({ name }) => {
								if (logintoken && hasActiveSubscription) {
									return name !== "Sign up" && name !== "Login";
								}
								return true;
							})
							.map(({ name, path }) => (
								<NavLink
									key={name}
									to={path}
									className={({ isActive }) =>
										isActive && name !== "Sign Up" && name !== "Login"
											? "before:scale-x-100 before:origin-left relative before:w-full before:h-1  before:transition-transform before:duration-300 before:absolute before:left-0 before:bottom-0 before:bg-primary_color before:top-5 2xl:before:top-6 "
											: // ? "before:scale-x-100 before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:absolute before:left-0 before:bottom-0 before:bg-primary_color before:top-5 2xl:before:top-10 "
											  ""
									}
								>
									<li
										className={`text-bold     text-center cursor-pointer ${
											// className={`md:text-[12px] xl:text-[15px] new:text-[30px] 2xl:text-[22px]  text-center cursor-pointer ${
											name.includes("Sign up")
												? "bg-primary_color w-full text-center text-white  rounded-2xl py-2 px-6  "
												: // ? "bg-primary_color w-full text-center text-white p-2 rounded-2xl md:w-fit md:px-4  md:py-0.6 lg:p-2 lg:w-24 xl:py-1 2xl:w-full 2xl:py-1 2xl:px-6 "
												name.includes("Login")
												? "bg-transparent w-full text-center border-primary_color border py-2 px-6 rounded-2xl md:bg-none "
												: // ? "bg-transparent w-full text-center border-primary_color border p-2 rounded-2xl md:w-fit md:bg-none md:px-4 md:py-1.2 lg:p-1.5 lg:w-24 xl:py-1 2xl:w-full 2xl:py-1 2xl:px-6"
												  "bg-none hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-400 before:scale-x-0 before:absolute before:left-0 before:bottom-0 before:bg-primary_color before:top-5 2xl:before:top-6"
											// "bg-none hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-400 before:scale-x-0 before:absolute before:left-0 before:bottom-0 before:bg-primary_color before:top-5 2xl:before:top-10"
										}`}
									>
										{name}
									</li>
								</NavLink>
							))}
						{/* Conditionally show dashboard link if user has a logintoken */}
						{logintoken && hasActiveSubscription && (
							<li
								className=" text-center cursor-pointer bg-primary_color w-auto text-white px-4 py-2 rounded-[10px]  "
								// className="md:text-[12px] xl:text-[15px] new:text-[30px] 2xl:text-[22px] text-center cursor-pointer bg-primary_color w-full text-white p-2 rounded-[10px] md:w-fit md:px-4  md:py-0.6 lg:p-2 lg:w-28 xl:py-1  2xl:w-full 2xl:py-1 2xl:px-6"
								onClick={() => navigate(getDashboardLink())}
							>
								Dashboard
							</li>
						)}
					</ul>
				</div>
			</nav>

			{/* Overlay */}
			{toggleNavBar && (
				<div
					className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
					onClick={handleOverlayClick}
				></div>
			)}

			{/* Slide-out Menu for Mobile Screens */}
			<div
				className={`fixed z-50 top-0 right-0 h-full w-[50%] md:w-[40%] bg-gradient-to-b from-primary_color to-grey rounded-tl-xl rounded-bl-xl transform transition-transform ease-in-out duration-300 ${
					toggleNavBar ? "translate-x-0" : "translate-x-full"
				} lg:hidden`}
			>
				<div className="p-4 flex flex-col h-full">
					<div className="flex justify-end mb-8">
						<CloseIcon
							size={20}
							onClick={() => {
								setToggleNavBar(false);
							}}
							className="text-white"
						/>
					</div>
					<ul className="flex-grow flex flex-col space-y-5 ">
						{navlinks
							.filter(({ name }) => {
								if (logintoken && hasActiveSubscription) {
									return name !== "Sign Up" && name !== "Login";
								}
								return true;
							})
							.map(({ name, path }) => (
								<NavLink
									key={name}
									to={path}
									className={({ isActive }) =>
										`flex items-center py-3 px-4 rounded-lg transition-all duration-300 ${
											isActive
												? "text-white underline"
												: "text-white hover:bg-gray-100 hover:text-black"
										}`
									}
									onClick={() => {
										setToggleNavBar(false);
									}}
								>
									<li className="text-sm md:text-xl font-bold">{name}</li>
								</NavLink>
							))}
						{logintoken && hasActiveSubscription && (
							<li
								className="text-sm md:text-xl font-bold text-white hover:bg-gray-100 hover:text-black p-3 rounded-lg"
								onClick={() => {
									navigate(getDashboardLink());
									setToggleNavBar(false);
								}}
							>
								Dashboard
							</li>
						)}
					</ul>
				</div>
			</div>
		</>
	);
};

export default NavBar;
