import PlanComparison from "../../../components/pages/pricing/plan_comparism.component";
import PricingPlans from "../../../components/pages/pricing/pricing_plans.component";
import ScheduleMeeting from "../../../components/pages/pricing/schedule_meeting.component";

import Pricing_tabComponent from "../../../components/pages/pricing/pricing_tab.component";
import PricingHeading from "../../../components/pages/pricing/pricing_heading";
const Pricing = () => {
  return (
    <>
      <div className=" w-full h-full bg-white ">
        <div className=" bg-white pb-24 xl:pb-0 px-4 md:px-4 lg:px-24">
          <div className="w-full py-20 xl:py-4 xl:pt-40  2xl:pt-40">
            <h1 className="text-center text-black font-bold text-2xl md:text-2xl lg:text-3xl   2xl:text-6xl  ">
              Our Plans
            </h1>
            <p className=" text-center text-pretty  text-black font-light text-xl md:text-xl lg:text-2xl  2xl:text-4xl   ">
              Choose a plan that suits your needs
            </p>
          </div>
          <Pricing_tabComponent/>
          <PricingHeading/> 
        </div>
         
        <PlanComparison />
        <ScheduleMeeting />
      </div>
    </>
  );
};

export default Pricing;
