import { Route, Routes } from "react-router-dom";
import Layout from "../layout/layout";
import AuthLayout from "../layout/authLayout";
import SuperAdminLayout from "../layout/superAdminLayout";
import SUPERADMINSETUP from "../pages/super_admin/setup";
import SessionManager from "../pages/super_admin/session_manager";
import TermManager from "../pages/super_admin/term_manager";
import Home from "../pages/landing_page/home";
import Pricing from "../pages/landing_page/pricing";
import Support from "../pages/landing_page/support";
import Suscribe from "../pages/landing_page/suscribe"
import LoginEmployee from "../pages/landing_page/login/login_employee";
import SignUp from "../pages/landing_page/sign_up/sign_up";
import Login from "../pages/landing_page/login/login";
import SignUpStart from "../pages/landing_page/sign_up/sign_up_start";
import StepOne from "../components/pages/sign_up/step_1.component";
import StepTwo from "../components/pages/sign_up/step_2.component";
import LoginStudent from "../pages/landing_page/login/login_student";
import Privacy from "../pages/landing_page/privacy_policy";
import DeleteAccountPage from "../pages/landing_page/delete_account";
import { useEffect } from "react";
import ActivityLog from "../pages/super_admin/activity_log";
import LoginManagement from "../pages/super_admin/login_management";
import ParentProfilePage from "../pages/parent/profile";
import StudentProfilePage from "../pages/student/profile";
import Dashboard from "../pages/admin_dashboard/dashboard/page";
import User from "../pages/admin_dashboard/users/page";
import AdminLayout from "../layout/adminDashboardLayout";
import Administration from "../pages/admin_dashboard/administration/page";
import Message from "../pages/admin_dashboard/message/page";
import Package from "../pages/admin_dashboard/package/page";
import Payment from "../pages/admin_dashboard/payments/page";
import Report from "../pages/admin_dashboard/reports/page";
import Ticket from "../pages/admin_dashboard/ticket/page";
import Website from "../pages/admin_dashboard/website/page";
import Account from "../pages/admin_dashboard/account/page";
import Real  from "../pages/admin_dashboard/account real/real";
import Verify from "../pages/landing_page/verify";
import ForgetPassword from "../pages/landing_page/forget_password";
import VerifyForgetPassword from "../pages/landing_page/verify_forgetpassword.jsx";
import Reset from "../pages/landing_page/reset_password/index.jsx";
import ProtectedRoute from "../auth/protectedroute.js";
import DeleteAccount from "../pages/super_admin/delete_account/index.jsx";
import StarTracker from "../pages/landing_page/star_tracker/index.jsx";
import Terms from "../pages/terms_conditions/index.jsx";
const AppRoute = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/pricing"
        element={
          <Layout>
            <Pricing />
          </Layout>
        }
      />
      <Route
        path="/star"
        element={
          <Layout>
            <StarTracker />
          </Layout>
        }
      />
      <Route
        path="/support"
        element={
          <Layout>
            <Support />
          </Layout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Layout>
            <Privacy />
          </Layout>
        }
      />
      
      <Route
        path="delete-account"
        element={
          <Layout>
            <DeleteAccountPage />
          </Layout>
        }
      />
        <Route
        path="/subscribe"
        element={
          <Layout>
            <Suscribe />
          </Layout>
        }
      />
       <Route
        path="/terms-conditions"
        element={
          <Layout>
            <Terms />
          </Layout>
        }
      />
      <Route
        path="/login"
        element={
          <AuthLayout>
            <Login />
          </AuthLayout>
        }
      />
       <Route
        path="/verifyotp"
        element={
          <AuthLayout>
            <Verify />
          </AuthLayout>
        }
      />
       <Route
        path="/forgetpassword"
        element={
          <AuthLayout>
            <ForgetPassword />
          </AuthLayout>
        }
      />
       <Route
        path="/verifyforgetpassword"
        element={
          <AuthLayout>
            <VerifyForgetPassword />
          </AuthLayout>
        }
      />
       <Route
        path="/reset"
        element={
          <AuthLayout>
            <Reset />
          </AuthLayout>
        }
      />

      <Route
        path="/login/student"
        element={
          <AuthLayout goBack>
            <LoginStudent />
          </AuthLayout>
        }
      />
      <Route
        path="/login/employee"
        element={
          <AuthLayout goBack>
            <LoginEmployee />
          </AuthLayout>
        }
      />
      <Route
        path="/signup"
        element={
          <AuthLayout>
            <SignUp />
          </AuthLayout>
        }
      />
      <Route
        path="/signup/employee"
        element={
          <AuthLayout goBack>
            <SignUpStart />
          </AuthLayout>
        }
      />
      <Route
        path="/signup/employee/details/step-1"
        element={
          <AuthLayout goBack overFlow={" overflow-scroll lg:overflow-hidden"}>
            <StepOne />
          </AuthLayout>
        }
      />
      <Route
        path="/signup/employee/details/step-2"
        element={
          <AuthLayout
            goBack
            overFlow={"overflow-scroll"}
            paddingTop={"pt-8"}
            paddingBottom={"pb-0"}
          >
            <StepTwo />
          </AuthLayout>
        }
      />
    
      {/* Admin dasboard routes */}
      <Route
        path="/admin/dashboard"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Dashboard/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
       <Route
        path="/admin/real"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Real/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      <Route
        path="/admin/user"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <User/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      <Route
        path="/admin/administration"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Administration/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      <Route
        path="/admin/message"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Message/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      <Route
        path="/admin/package"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Package/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      <Route
        path="/admin/payment"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Payment/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      <Route
        path="/admin/report"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Report/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      <Route
        path="/admin/ticket"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Ticket/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      <Route
        path="/admin/website"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Website/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
       <Route
        path="/admin/account"
        element={
          <ProtectedRoute>
          <AdminLayout>
            <Account/>
            </AdminLayout>
            </ProtectedRoute>
        }
      />
      {/*Spe Admin routes */}
      <Route
        path="/employee/dashboard"
        element={
          <SuperAdminLayout>
            <SUPERADMINSETUP />
          </SuperAdminLayout>
        }
      />
       <Route
        path="/employee/deleteaccount"
        element={
          <SuperAdminLayout>
            <DeleteAccount />
          </SuperAdminLayout>
        }
      />
      <Route
        path="/employee/dashboard/session-manager"
        element={
          <SuperAdminLayout>
            <SessionManager />
          </SuperAdminLayout>
        }
      />
      <Route
        path="/employee/dashboard/term-manager"
        element={
          <SuperAdminLayout>
            <TermManager />
          </SuperAdminLayout>
        }
      />
      <Route
        path="/employee/dashboard/login-management"
        element={
          <SuperAdminLayout>
            <LoginManagement />
          </SuperAdminLayout>
        }
      />
      <Route
        path="/employee/dashboard/login-management/activity-log"
        element={
          <SuperAdminLayout>
            <ActivityLog />
          </SuperAdminLayout>
        }
      />
      <Route
        path="login/parent/dashboard/profile"
        element={
          <SuperAdminLayout>
            <ParentProfilePage />
          </SuperAdminLayout>
        }
      />
      <Route
        path="login/student/dashboard/profile"
        element={
          <SuperAdminLayout>
            <StudentProfilePage />
          </SuperAdminLayout>
        }
      />
    </Routes>
  );
};

export default AppRoute;
