export const totalrevenue = {
    currentMonth: {
      month: 'February 2023',
      revenue: 2034532.02,
      currency: 'NGN',
    },
    previousMonth: {
      month: 'January 2023',
      revenue: 3234202.72,
      currency: 'NGN',
    },
    change: {
      percentage: -10,
      trend: 'down', // can be 'up' or 'down'
    },
  };
  