import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ClientRequest from "../../api";
interface props {
  email: string;
  password: string;
  navigate: any;
}
export const loginUser = createAsyncThunk(
  "user/auth/login",
  async (props: props, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("email", props.email);
    formData.append("password", props.password);
    try {
      const response = await axios.post(
        "https://162.0.222.79:7000/api/Auth/Login",
        {
          params: {
            email: props.email,
            password: props.password
          }
        }
      );
      // const response = await ClientRequest.post("Auth/Login", {
      //   email: props.email,
      //   password: props.password
      // });

      if (response.status === 200) {
        console.log(response.data);
        // return response.data.data;
      }
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.message);
    }
  }
);
