import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const deleteAccount = createAsyncThunk(
    'delete/deleteAccount',
    async (email, { rejectWithValue }) => {
        try {
            const response = await axios.delete('https://api.mystarsonline.com/api/Persona/delete-account', {
                data: { email }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const deleteSlice = createSlice({
    name: 'delete',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {
        clearDeleteState: (state) => {
            state.loading = false;
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteAccount.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(deleteAccount.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = false;
            });
    },
});

export const { clearDeleteState } = deleteSlice.actions;

export default deleteSlice.reducer;
