import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedPlan: null, // Store selected plan details here
};

const savedplanSlice = createSlice({
  name: 'savedplan',
  initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    clearSelectedPlan: (state) => {
      state.selectedPlan = null;
    },
  },
});

export const { setSelectedPlan, clearSelectedPlan } = savedplanSlice.actions;

export default savedplanSlice.reducer;