import React from 'react'

const About = () => {
  return (
    <div className='h-full w-full bg-background'>
        <div className='flex flex-col pt-10 justify-center text-center'>
                <div className='text-xl md:text-2xl lg:text-4xl 2xl:text-5xl font-bold'>
                    <h1>About My Star Tracker</h1>
                </div>
                <div className='flex flex-col text-start p-12 md:p-20 space-y-3 text-sm 2xl:text-xl'>
                    <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non esse sequi quibusdam. Nostrum iusto placeat quae architecto ducimus quaerat reiciendis dignissimos beatae itaque sequi! Alias quia amet aliquid. Repellendus, ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci harum, sunt reiciendis illum itaque veniam dolores asperiores libero omnis aut aliquid quaerat deserunt velit? Aperiam eveniet iusto nisi illum similique! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta repellendus molestiae tempore fugiat delectus debitis! Architecto hic molestiae neque, porro quae asperiores exercitationem nam temporibus nihil perferendis consequuntur magni pariatur?</span>
                    <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates distinctio non ab facilis voluptate dolorum temporibus iure quisquam magni aperiam tenetur reiciendis sed suscipit dicta eius repellendus, ipsam rem? Incidunt.</span>
                    <span>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam magni eius dicta obcaecati molestiae beatae blanditiis voluptatem, earum fugit veniam. Vero natus tempore cupiditate quas sit ratione modi reprehenderit incidunt.</span>
                </div>
                <div className='h-52 w-52 md:h-[300px] md:w-[500px] 2xl:h-[500px] 2xl:w-[700px] mx-auto bg-[#F9EF00] rounded-2xl'>

                </div>
        </div>
    </div>
  )
}

export default About