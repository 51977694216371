import SignUpForm from "../../../components/pages/sign_up/sign_up.component";
import img from "../../../assets/images/graduands.png";
const SignUp = () => {
    

    const options = [
        { name: "Employee", route: "/employee" },
        { name: "Student", route: "/student" },
        { name: "Parent", route: "/employee" }
    ];

    return (
        <div className="w-full h-full flex justify-center px-14 " >
        <div className=" " >
          <SignUpForm />
        </div>  
      </div>
    );
}

export default SignUp;
