// src/redux/slices/forgetPasswordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const forgetPasswordSlice = createSlice({
  name: 'forgetPassword',
  initialState: {
    forgetPasswordEmail: '',
  },
  reducers: {
    setForgetPasswordEmail: (state, action) => {
      state.forgetPasswordEmail = action.payload;
    },
  },
});

export const { setForgetPasswordEmail } = forgetPasswordSlice.actions;
export default forgetPasswordSlice.reducer;
