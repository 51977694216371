import axios from 'axios';

const login = async (email, password) => {
  try {
    const response = await axios.post('https://api.mystarsonline.com/api/Auth/Login', {
      email,
      password
    });

    return response.data; // Return the entire response data, which includes the message
  }catch (error) {
    if (error.response && error.response.data) {
    const message = error.response.data.message;
    throw new Error(message);
    } else {
    throw new Error('Network error');
    }
    }
    
};

export default login;
