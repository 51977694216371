import * as Collapsible from "@radix-ui/react-collapsible";
import { RowSpacingIcon, Cross2Icon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { IoIosAdd } from "react-icons/io";
import DisplayUser from "../../../components/pages/adminDashboard/user/handHeld/DisplayUser";

const UserHandheld = () => {
	const [isVerifiedOpen, setIsVerifiedOpen] = useState(false);
	const [isUnverifiedOpen, setIsUnverifiedOpen] = useState(false);

	const [dummyData, setDummyData] = useState([
		{
			Name: "john doe",
			Status: "Active",
			Package: "Free",
			School: "Example School",
			Date_Joined: "2023-12-27",
			isOpen: false,
			id: 1,
		},
		{
			Name: "sam doe",
			Status: "Active",
			Package: "Starter",
			School: "Example School",
			Date_Joined: "2023-12-27",
			isOpen: false,
			id: 2,
		},
		{
			Name: "jane doe",
			Status: "Inactive",
			Package: "Free",
			School: "Example School",
			Date_Joined: "2023-12-27",
			isOpen: false,
			id: 3,
		},
		{
			Name: "sarah doe",
			Status: "Inactive",
			Package: "Pro",
			School: "Example School",
			Date_Joined: "2023-12-27",
			isOpen: false,
			id: 4,
		},
		{
			Name: "GeeGee doe",
			Status: "Active",
			Package: "Standard",
			School: "Example School",
			Date_Joined: "2023-12-27",
			isOpen: false,
			id: 5,
		},
	]);

	const toggleUserDropdown = (userId) => {
		setDummyData((prevData) =>
			prevData.map((user) =>
				user.id === userId ? { ...user, isOpen: !user.isOpen } : user
			)
		);
	};

	return (
		<div className="flex flex-col gap-2 transition-all ease duration-500 ">
			<div className="flex justify-between items-center mt-2">
				<h2 className="font-extrabold  text-2xl">Users</h2>
				<button className="flex items-center bg-primary_color text-white rounded p-2  ">
					<IoIosAdd />
					Add Users
				</button>
			</div>

			{/* Verified  */}
			<Collapsible.Root
				className="CollapsibleRoot  border border-1  px-1 border-grey-200 rounded-xl"
				open={isVerifiedOpen}
				onOpenChange={setIsVerifiedOpen}
			>
				<Collapsible.Trigger asChild>
					<div
						className={`"  ${
							isVerifiedOpen && "border-b-2 border-b-gray-300 "
						} px-2 py-3 font-bold flex justify-between items-center "`}
					>
						<span className="Verified">Verified</span>
						<span className="bg-primary_color rounded-full p-1 text-white">
							{!isVerifiedOpen ? <FaAngleDown /> : <FaAngleUp />}
						</span>
					</div>
				</Collapsible.Trigger>
				<Collapsible.Content>
					<DisplayUser
						users={dummyData.filter((item) => item.Status === "Active")}
						onToggle={toggleUserDropdown}
					/>
				</Collapsible.Content>
			</Collapsible.Root>

			{/* unverified  */}
			<Collapsible.Root
				className="CollapsibleRoot  border border-1 px-1 border-grey-200 rounded-xl"
				open={isUnverifiedOpen}
				onOpenChange={setIsUnverifiedOpen}
			>
				<Collapsible.Trigger asChild>
					<div
						className={` ${
							isUnverifiedOpen && "border-b-2 border-b-gray-300 "
						} px-2 py-3 font-bold flex justify-between items-center`}
					>
						<span className="Unverified">Unverified</span>
						<span className="bg-primary_color rounded-full p-1 text-white">
							{" "}
							{!isUnverifiedOpen ? <FaAngleDown /> : <FaAngleUp />}
						</span>
					</div>
				</Collapsible.Trigger>
				<Collapsible.Content>
					<DisplayUser
						users={dummyData.filter((item) => item.Status === "Inactive")}
						onToggle={toggleUserDropdown}
					/>
				</Collapsible.Content>
			</Collapsible.Root>
		</div>
	);
};

export default UserHandheld;
