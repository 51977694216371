import React, { useState } from 'react';
import { AiOutlineArrowLeft, AiOutlinePlus } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';
import { BsFillPersonFill } from 'react-icons/bs';
import { HiOutlineTicket } from "react-icons/hi2";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux'; // Import useSelector to access the Redux store

const Reply = ({ ticketNumber, subject, email, ticketstatus, dateCreated, updated, onClose }) => {
    const [isOpen, setIsOpen] = useState(true); 
    const [responseMessage, setResponseMessage] = useState(''); // Store the input message here
    const token = useSelector(state => state.login.logintoken); // Access the token from the Redux store

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const formatDate = (dateString) => {
        if (!dateString || isNaN(new Date(dateString).getTime())) return "-"; 
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = String(date.getFullYear()).slice(-2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year}(${hours}:${minutes})`;
    };

    const handleReplySubmit = async () => {
       

        try {
            // Ensure we're sending the response message from the input field
            const requestBody = {
                responseMessage: responseMessage,
            };

            const response = await axios.post(
                `https://api.mystarsonline.com/api/SupportTicket/respond-to-ticket/${ticketNumber}`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${token}` // Add token from the Redux store in the Authorization header
                    }
                }
            );

            console.log('API Response:', response.data);
            // Further actions upon successful submission, if any

        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    };

    return (
        <div className="bg-gray-50 overflow-hidden p-8 px-8">
            {/* Page Header */}
            <div className="flex justify-between items-center mb-8">
                <div className="flex items-center space-x-4">
                    <div onClick={onClose}> 
                        <AiOutlineArrowLeft size={24} className="text-primary_color cursor-pointer" />
                    </div>
                    <p className="text-xl font-semibold">Ticket #{ticketNumber} - {subject}</p>
                </div>
                <button className={`px-4 py-1 rounded-xl ${ticketstatus.toLowerCase() === 'closed' ? 'bg-gray-400' : 'bg-pink-500'} text-white`}>
                    {ticketstatus}
                </button>
            </div>

            <div className="flex">
                {/* Left section - Ticket Information */}
                <div className="w-1/4 bg-gray-100 p-4 rounded-xl mr-4 space-y-5">
                    <div className='flex flex-row items-center space-x-2'>
                        <span><HiOutlineTicket /></span>
                        <span>
                            <h3 className="font-semibold text-gray-700 border-b-2">Ticket Information</h3>
                        </span>
                    </div>
                    <div className="mb-2">
                        <p className="text-sm text-purple-500 font-semibold border-b-2">Requester</p>
                        <p className="text-sm text-gray-600">{email}</p>
                    </div>
                    <div className="mb-2">
                        <p className="text-sm text-purple-500 font-semibold border-b-2">Department</p>
                        <p className="text-sm text-gray-600">Support</p>
                    </div>
                    <div className="mb-2">
                        <p className="text-sm text-purple-500 font-semibold border-b-2">Submitted on</p>
                        <p className="text-sm text-gray-600">{formatDate(dateCreated)}</p>
                    </div>
                    <div className="mb-2">
                        <p className="text-sm text-purple-500 font-semibold border-b-2">Last Updated</p>
                        <p className="text-sm text-gray-600">{formatDate(updated)}</p>
                    </div>
                    <div>
                        <p className="text-sm text-purple-500 font-semibold border-b-2">Status/Priority</p>
                        <p className="text-sm text-gray-600">{ticketstatus}</p>
                    </div>
                    <button
                        className="bg-purple-500 text-white text-center py-1 rounded-xl mt-4 w-full"
                        onClick={handleReplySubmit} // Submit reply on click
                    >
                        Reply
                    </button>
                </div>

                {/* Right section - Ticket Conversation */}
                <div className="w-3/4 pl-4">
                    {/* Accordion Header */}
                    <div 
                        className="flex justify-between items-center mb-4 cursor-pointer bg-gray-200 p-3 rounded-xl"
                        onClick={toggleAccordion}
                    >
                        <div className="flex items-center space-x-2">
                            <FiEdit2 size={20} className="text-gray-500" />
                            <p className="text-lg font-semibold text-gray-700">Reply</p>
                        </div>
                        <AiOutlinePlus 
                            size={20} 
                            className={`text-gray-500 transform transition-transform ${isOpen ? 'rotate-45' : ''}`} 
                        />
                    </div>

                    {/* Accordion Body - Conditional Rendering */}
                    {isOpen && (
                        <div className="bg-pink-100 p-4 mb-4 rounded-xl shadow-lg">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <BsFillPersonFill size={20} className="text-gray-500" />
                                    <div>
                                        <h4 className="font-semibold text-black">Paul support</h4>
                                        <p className="text-sm text-gray-500">Staff</p>
                                    </div>
                                </div>
                                <p className="text-sm text-gray-500 border-b-2">{formatDate(updated)}</p>
                            </div>
                            <div className="mt-8">
                                <label htmlFor="responseInput" className="block text-sm text-gray-700 font-semibold">Your Response:</label>
                                <input
                                    type="text"
                                    id="responseMessage"
                                    value={responseMessage} // Bind to state
                                    onChange={(e) => setResponseMessage(e.target.value)} // Update state on input change
                                    placeholder="Enter your message..."
                                    className="w-full p-2 mt-2 border rounded-md focus:ring focus:ring-pink-300 text-sm text-gray-700"
                                />
                            </div>
                        </div>
                    )}

                    {/* User's Reply - Always Visible */}
                    <div className="bg-green-100 p-4 rounded-xl shadow-lg">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center space-x-2">
                                <BsFillPersonFill size={20} className="text-green-500" />
                                <div>
                                    <h4 className="font-semibold text-black">John Doe</h4>
                                    <p className="text-sm text-gray-500">{email}</p>
                                </div>
                            </div>
                            <p className="text-sm text-gray-500">{formatDate(dateCreated)}</p>
                        </div>
                        <p className="text-sm text-gray-700 mt-6">
                            {subject}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reply;
