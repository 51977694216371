import { createSlice } from '@reduxjs/toolkit';

const resubscribeSlice = createSlice({
  name: 'resubscribe', 
  initialState: {
    resubscribetoken: null, // This will hold the token from the login response
  },
  reducers: {
    setResubscribeToken: (state, action) => {
      state.resubscribetoken = action.payload;
    },
    clearResubscribeToken: (state) => {
      state.resubscribetoken = null;
    }
  }
});

export const { setResubscribeToken, clearResubscribeToken } = resubscribeSlice.actions;
export default resubscribeSlice.reducer;
