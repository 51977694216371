import React, { useState } from "react";
import { FaBell, FaChevronDown, FaBars, FaEnvelope } from "react-icons/fa";
import Logo from "../../common/logo/logo.component";
import { Link } from "react-router-dom";
import MobileSidebar from "./mobile-sidebar";
import SearchBar from "./search-bar";
import { siteIcons } from "../../../assets/images/images";
function TopBar({ toggleSidebar }) {
	const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

	const toggleMobileSidebar = () => {
		setIsMobileSidebarOpen(!isMobileSidebarOpen);
	};

	return (
		<>
			<div className="w-full  fixed z-40 bg-white h-auto shadow-md flex items-center justify-between py-4  px-5 lg:px-20 ">
				{/* <div className="w-full fixed z-40 bg-white h-auto shadow-md flex items-center justify-between py-4 px-5 lg:px-20 flex-wrap"> */}
				{/* Mobile view: Menu button and logo */}
				<div className="w-full flex justify-between items-center lg:hidden">
					<div className="flex items-center gap-x-2">
						<button className="menu-button" onClick={toggleMobileSidebar}>
							<FaBars size={22} />
						</button>
						<Link to="/" className="w-24 md:w-40 h-auto">
							<img
								src={siteIcons.logo}
								alt="My Star Logo"
								className="w-full h-auto"
								style={{ width: 100, height: "auto" }}
							/>
						</Link>
					</div>
					<div className="w-8 h-auto rounded-full border-2 border-gray-300 overflow-hidden">
						<img
							src={siteIcons.profilePhoto}
							alt="Profile"
							className="w-full "
							style={{ width: 32, height: 32 }}
						/>
					</div>
				</div>

				{/* Desktop view: Full navigation */}
				<div className="w-full hidden lg:flex lg:justify-between items-center">
					<div className="flex items-center">
						<FaBars
							className="cursor-pointer"
							size="20"
							onClick={toggleSidebar}
						/>
					</div>
					<div className="flex items-center gap-x-16">
						<SearchBar />
						<div className="flex items-center gap-x-4 2xl:text-xl">
							<FaBell className="cursor-pointer" />
							<FaEnvelope className="cursor-pointer" />
							<FaChevronDown className="cursor-pointer" />
						</div>
						<div className="w-24 md:w-40 h-auto">
							<Link to="/">
								<Logo />
							</Link>
						</div>
					</div>
				</div>
			</div>
			<MobileSidebar
				isOpen={isMobileSidebarOpen}
				toggleModal={toggleMobileSidebar}
			/>
		</>
	);
}

export default TopBar;
