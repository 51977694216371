import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenants } from "../../../../../redux/slices/tenant_slice"; // Adjust the import path as needed
import OptionTabAdminUser from "./option_tab";
import TableInfoUser from "./Table";
import Paginator from "../../../superAdmin/activity_log/paginator.component";
import { AiOutlineSearch } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
const Verified = () => {
	const dispatch = useDispatch();
	const tenants = useSelector((state) => state.tenant.tenants);
	const status = useSelector((state) => state.tenant.status);
	const error = useSelector((state) => state.tenant.error);

	const [currentPage, setCurrentPage] = useState(1); // Current page
	const pageSize = 10; // Number of items per page
	const [searchQuery, setSearchQuery] = useState(""); // Search state

	useEffect(() => {
		dispatch(fetchTenants());
	}, [dispatch]);

	// Filter and search logic for verified tenants
	const filteredAndSortedTenants = useMemo(() => {
		let sortableTenants = tenants.filter(
			(tenant) => tenant.verification === true
		);

		// Search filtering
		if (searchQuery) {
			sortableTenants = sortableTenants.filter((tenant) => {
				// Convert the joined date to a searchable string format
				const formattedJoinedDate = new Date(tenant.joined).toLocaleDateString(
					"en-US",
					{
						day: "numeric",
						month: "numeric",
						year: "numeric",
					}
				);

				return (
					tenant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
					tenant.school.toLowerCase().includes(searchQuery.toLowerCase()) ||
					tenant.packages.toLowerCase().includes(searchQuery.toLowerCase()) ||
					formattedJoinedDate.includes(searchQuery) // Search within formatted joined date
				);
			});
		}

		return sortableTenants;
	}, [tenants, searchQuery]);

	const totalTenants = filteredAndSortedTenants.length;
	const totalPages = Math.ceil(totalTenants / pageSize);

	// Get tenants for the current page
	const currentTenants = useMemo(() => {
		const startIndex = (currentPage - 1) * pageSize;
		return filteredAndSortedTenants.slice(startIndex, startIndex + pageSize);
	}, [currentPage, filteredAndSortedTenants]);

	// Handle Next and Previous page navigation
	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage((prevPage) => prevPage - 1);
		}
	};

	const tabOptions = [
		{ option: "S/N" },
		{ option: "Name" },
		{ option: "Verified" },
		{ option: "Package" },
		{ option: "School" },
		{ option: "Joined" },
		{ option: "Action" },
	];

	const getPackageStyle = (packageType) => {
		switch (packageType) {
			case "Free":
				return { color: "green" };
			case "Starter":
				return { color: "blue" };
			case "Pro":
				return { color: "orange" };
			case "Standard":
				return { color: "purple" };
			default:
				return {};
		}
	};

	return (
		<div className="w-full  ">
			{/* Search Bar */}
			<div className="flex justify-end ">
				{/* <div className="border bg-[#EDF2F6] border-gray-300 rounded-full focus:outline-none relative w-full md:w-1/3">
          <input
            type="text"
            className="w-full p-2 pr-10"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FaSearch className="absolute right-3 top-3 font-bold text-gray-400" />
        </div> */}
			</div>
			{/* Table section*/}
			<div className="overflow-x-auto">
				{" "}
				{/* Add horizontal scrolling here */}
				<OptionTabAdminUser options={tabOptions} />
				{status === "loading" && (
					<p className="pt-20">
						<Skeleton count={8} />{" "}
					</p>
				)}
				{status === "failed" && <p>Error: {error}</p>}
				{status === "succeeded" &&
					currentTenants.map(
						(
							{ id, name, middleName, verification, packages, school, joined },
							index
						) => (
							<TableInfoUser
								key={id}
								serialNo={(currentPage - 1) * pageSize + index + 1} // Calculate serial number based on current page
								id={id} // Pass id to TableInfoUser
								name={name}
								middleName={middleName} // Pass middleName to TableInfoUser
								verified={verification}
								packages={
									<span style={getPackageStyle(packages)}>{packages}</span>
								}
								school={school}
								joined={joined}
							/>
						)
					)}
				<Paginator
					currentPage={currentPage}
					totalPages={totalPages}
					totalItems={totalTenants}
					pageSize={pageSize}
					onNextPage={handleNextPage}
					onPrevPage={handlePrevPage}
				/>
			</div>
		</div>
	);
};

export default Verified;
