import React from "react";
import pattern from "../../../assets/images/cycle pattern.png";
import Button from "../../common/button/button.component";
import { Link } from "react-router-dom";
import { management_list } from "./management_list";

const SchoolManagement = () => {
	return (
		<>
			<div className="w-full bg-white relative overflow-x-hidden ">
				<img
					src={pattern}
					alt="circle-pattern"
					className="absolute w-full -top-40 -left-40 md:w-2/3 md:-top-40 md:-left-60 lg:-top-40 lg:-left-60 lg:w-2/5"
				/>
				<div className="my-5  sm:my-10 lg:my-16 px-8 md:px-10 2xl:my-10 ">
					{/* <div className="mt-5 my-5 px-8 md:px-10 2xl:my-10"> */}
					<h3 className="text-center text-sm text-black font-bold sm:text-base lg:text-2xl sm:px-20  lg:px-[15rem]">
						{/* <h3 className="text-center text-sm text-black font-bold md:text-2xl lg:text-3xl xl:text-3xl 2xl:text-4xl"> */}
						Tired of juggling paperwork and spreadsheets?
						<br /> MyStar is the{" "}
						<span className="text-primary_color ">
							all-in-one student management software
						</span>{" "}
						designed to streamline your workflow and empower educators.
					</h3>
				</div>
				<section className="w-full flex flex-col sm:grid grid-cols-2 gap-4 sm:gap-2 px-2 sm:px-10 lg:gap-5 lg:px-[10rem] ">
					{/* <section className="w-full flex flex-col justify-center items-center flex-wrap p-5 lg:p-0 gap-8 md:flex-row md:justify-evenly 2xl:flex-row 2xl:gap-5 bg-red-900"> */}
					{management_list.map(({ title, image, color, message }) => (
						<div
							key={title}
							className="relative col-span-1  flex  h-auto w-full rounded-[20px]  sm:px-10 "
							// className="relative flex flex-col  md:flex-row md:items-center overflow-hidden rounded-2xl p-5 sm:w-72 sm:h-72 md:w-2/5 2xl:h-[430px] "
							style={{ background: color }}
						>
							<div className="relative pt-10 inset-0  z-10 p-5  text-black  bg-opacity-50  lg:pr-[6rem]  ">
								{/* <div className="absolute pt-10 inset-0 bg-black/70 z-10 p-5  text-white bg-opacity-50 bg-black xl:bg-transparent xl:relative xl:p-0 xl:text-start xl:text-black xl:flex-grow xl:pt-0"> */}
								<h4 className="text-center text-base font-semibold md:text-xl md:text-start  2xl:text-3xl">
									{title}
								</h4>
								<ul className="list-disc list-inside">
									{message.map(({ key, desc }, index) => (
										<li
											key={index}
											className="text-xs font-light mb-2 text-start md:text-start lg:pt-4 xl:pt-2 2xl:text-xl "
										>
											<b>{key}:</b> {desc}
										</li>
									))}
								</ul>
							</div>
							<div className=" hidden  absolute  lg:flex w-full h-full  justify-end   ">
								{/* <div className="relative md:ml-5   lg:w-3/4"> */}
								<img
									style={{ mixBlendMode: "luminosity" }}
									src={image}
									alt={title}
									className="   -z-1  relative right-10  "
									// className="h-60 w-60 md:w-full  xl:object-cover xl:h-full xl:w-full  object-contain 2xl:object-contain"
								/>
							</div>
						</div>
					))}
				</section>

				<div className="flex w-full justify-center my-9 2xl:pt-6">
					<div>
						<Link
							to="/pricing"
							className="hover:border-[#9835D6] hover:bg-white transition-all ease-in-out  duration-500  hover:text-primary_color hover:border bg-primary_color  rounded-[10px]  h-[2.5rem] lg:h-[52px]  flex justify-center items-center   text-white font-bold p-2 w-[16rem] lg:w-[422px]"
							// className="hover:border-[#9835D6] hover:bg-white  hover:text-primary_color hover:border bg-primary_color p-2  mt-4 w-40 text-center text-sm text-white rounded-2xl cursor-pointer lg:text-sm  xl:px-4 2xl:px-8 2xl:text-[26px] 2xl:py-4"
						>
							Click To Get Started
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default SchoolManagement;
