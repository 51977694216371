import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerUser } from '../../../redux/slices/register_slice';
import Button from '../../common/button/button.component';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import TermsAndConditions from './termsandconditions';
import { CgProfile } from "react-icons/cg";
import { IoSchoolOutline } from "react-icons/io5";
import { MdOutlinePhone } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { CiLock } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

const SignUpForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        schoolName: '',
        firstname: '',
        lastname: '',
        middleName: '',
        phoneNumber: ''
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // State to track button loading

    const { loading } = useSelector((state) => state.register);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        setIsLoading(true); // Set loading to true when request starts
        if (!checked) {
            toast.error("Please accept the terms and conditions before proceeding.");
            return;
        }

        try {
            const resultAction = await dispatch(registerUser(formData));
            if (registerUser.fulfilled.match(resultAction)) {
                const message = resultAction.payload.message || "Registration successful";
                toast.success(message);
                
                setTimeout(() => {
                    navigate('/verifyotp');
                }, 3000); // Wait 3 seconds before redirection to ensure toast is shown
               

            } else {
                const errorMessage = resultAction.payload.message || "Registration failed";
                toast.error(errorMessage);
                console.error('Error submitting form:', resultAction.payload);
                setIsLoading(false); // Return button to normal state

            }
        } catch (err) {
            toast.error(err.message || "An error occurred");
            console.error('Error submitting form:', err);
            setIsLoading(false); // Return button to normal state

        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleCheckboxChange = () => {
        if (!checked) {
            setShowModal(true);
        }
        setChecked(!checked);
    };

    return (
        <>
            <ToastContainer position='top-center' autoClose={2000} theme='light' pauseOnHover={false}   /> 
            <div className=''>
                <form className="bg-[#616161] shadow-2xl opacity-60 rounded-xl py-1 px-8 border md:py-1 md:px-8  lg:py-1 lg:px-2 xl:py-1 xl:px-2  2xl:py-20 2xl:px-20 "
                    onSubmit={handleSubmit}>
                    <div className="flex flex-col justify-center text-center pt-5  md:pt-0">
                        <h4 className=" font-bold text-center mb-1 text-2xl md:text-2xl xl:text-3xl xl:mb-1 2xl:text-5xl text-[#fff]">Sign Up</h4>
                        <span className='  flex flex-col justify-center text-[#fff] font-thin md:text-xl xl:text-lg 2xl:text-3xl'>
                            <p>Sign up for a </p>
                            <p>new account here!</p>
                        </span>
                    </div>
                    <div className="w-full flex items-center flex-col mb-2 mt-2 space-y-1 md:space-y-1 xl:space-y-1 2xl:space-y-6">
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-1 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <CgProfile color="black" />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="firstname"
                                        value={formData.firstname}
                                        onChange={handleChange}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="First Name"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-1 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <CgProfile color="black" />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="lastname"
                                        value={formData.lastname}
                                        onChange={handleChange}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="Last Name"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-1 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <CgProfile color="black" />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="middleName"
                                        value={formData.middleName}
                                        onChange={handleChange}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="Middle Name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-1 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <HiOutlineMail color="black" />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="Email"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-1 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <IoSchoolOutline color="black" />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="schoolName"
                                        value={formData.schoolName}
                                        onChange={handleChange}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="School Name"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-1 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <MdOutlinePhone color="black" />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="Phone Number"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-1 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <CiLock color="black" />
                                </div>
                                <div className="w-full flex items-center">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="Password"
                                        required
                                    />
                                    <div className="cursor-pointer ml-2 2xl:text-3xl" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FaRegEyeSlash color="black" /> : <FaRegEye color="black" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col text-xs 2xl:text-[18px] text-[#fff] mt-1">
                            <span>
                                Password must contain at least one capital letter,
                            </span>
                            <span>
                                one number, one special character, and be 8 characters long.
                            </span>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-1 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <CiLock color="black" />
                                </div>
                                <div className="w-full flex items-center">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="Confirm Password"
                                        required
                                    />
                                    <div className="cursor-pointer ml-2 2xl:text-3xl" onClick={toggleConfirmPasswordVisibility}>
                                        {showConfirmPassword ? <FaRegEyeSlash color="black" /> : <FaRegEye color="black" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex text-sm justify-center mt-1 mb-1 xl:mt-1 xl:mb-1">
                        <label className="relative flex items-center pr-1 rounded-full cursor-pointer" htmlFor="check">
                            <input
                            type="checkbox"
                            className="h-3 w-3 2xl:h-4 2xl:w-4 cursor-pointer appearance-none rounded-lg border bg-white border-black transition-all peer"
                            id="check"
                            onChange={handleCheckboxChange}
                            checked={checked}
                            />
                            <span className="absolute text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-1/4 -translate-y-2/4 -translate-x-1/4 peer-checked:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 2xl:h-4 2xl:w-4" viewBox="0 0 20 20" fill="currentColor"
                                stroke="currentColor" strokeWidth="1">
                                <path fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"></path>
                            </svg>
                            </span>
                        </label>
                        <label className="text-[#fff] cursor-pointer select-none text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[22px]" htmlFor="check">
                            Agree to our terms and conditions
                        </label>
                        </div>

                    {/* Button with spinner */}
                    <div className="w-full flex items-center justify-center pt-3 mb-1 cursor-pointer">
                        <div className={`w-full border border-primary_color rounded-xl p-1 text-sm flex items-center text-center text-primary_color font-semibold transition-all duration-300 md:text-sm xl:text-base 2xl:text-xl ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-white hover:bg-black hover:text-white'}`}>
                            <Button
                                type="submit"
                                className="w-full"
                                btnTitle={isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <svg className="animate-spin h-5 w-5 text-primary_color" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0h-2a6 6 0 00-12 0H4z"></path>
                                        </svg>
                                        <span>Loading...</span>
                                    </div>
                                ) : "Proceed"}
                                disabled={isLoading} // Disable button when loading
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-center pt-1 xl:pt-1 ">
                        <Link className="text-center text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[22px] text-[#fff] cursor-pointer" to="/login">
                            Already have an account? <span className='text-[#fff]'>Login here!</span>
                        </Link>
                    </div>
                </form>
            </div>

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <TermsAndConditions onClose={() => setShowModal(false)} />
                </div>
            )}
        </>
    );
};

export default SignUpForm;
