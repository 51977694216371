import { useState } from "react";
import Button from "../../../common/button/button.component";
import { CloseIcon } from "../../../common/icons/icons";
import ChangesSaved from "./changes_saved.component";
import Confirmation from "./confirmation.component";

const ResetPassword = ({ setShowModal }) => {
    const [saveChanges, setSaveChanges] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false)
    return (
        <div className="bg-white rounded-md py-3 lg:w-3/5 relative  shadow-md " >
            <div className="p-2 flex items-center justify-center absolute rounded-50% top-4 right-10 bg-primary_color" >
                <CloseIcon cursor={'pointer'} color={'white'} onClick={() => {
                    setShowModal(false)
                    setSaveChanges(false)
                    setShowConfirmation(false)
                    setShowForm(true)
                }} />
            </div>

            {showForm && <>
                <div className="w-full my-7" >
                    <h3 className="text-xl font-bold text-primary_color text-center uppercase" >Reset Password</h3>
                    <p className="text-lg font-bold text-black text-center" >Reset your password</p>
                </div>
                <div className="w-full px-9 mt-5 flex items-center justify-between flex-wrap gap-1 " >
                    <div style={{ width: "45%" }} >
                        <label className=" text-sm font-semibold text-black" >Current password</label>
                        <input value={"Mary Employee"} style={{ border: '1px solid #e3e3e3' }} type="password" className="w-full rounded-md p-3  outline-none text-ash text-sm  " />
                    </div>
                    <div style={{ width: "45%" }} >
                        <label className=" text-sm font-semibold text-black" >New password</label>
                        <input value={"Maths teacher"} style={{ border: '1px solid #ededed' }} type="password" className="w-full rounded-md p-3  outline-none text-ash text-sm  " />
                    </div>
                    <div style={{ width: "45%" }} className="mt-7" >
                        <label className=" text-sm font-semibold text-black" >Current Password</label>
                        <input value={"mary@gmail.com"} style={{ border: '1px solid #ededed' }} type="password" className="w-full rounded-md p-3  outline-none text-ash text-sm  " />
                    </div>
                    <div style={{ width: "100%" }} className="mt-7" >
                        <label className=" text-sm font-semibold text-black" >Reason for changing password</label>
                        <textarea value={"mary@gmail.com"} style={{ border: '1px solid #ededed' }} className="w-full rounded-md p-3  outline-none text-ash text-sm " />
                    </div>
                </div>
                <div className="mt-20 mb-5 px-9 flex items-center justify-end gap-2 " >
                    <Button onClick={() => { setShowForm(false); setShowConfirmation(true) }} btnTitle={'Reset Password'} className={'w-28 p-2 bg-primary_color rounded text-sm text-white text-center cursor-pointer '} />
                    <Button btnTitle={'Cancel'} btnColor={'bg-white'} className={'w-28 p-2 bg-white rounded border cursor-pointer border-primary_color text-sm text-center '} onClick={() => {
                        setShowModal(false)
                    }} />
                </div>
            </>}


            {showConfirmation && <Confirmation setSaveChanges={setSaveChanges} setShowForm={setShowForm} setShowConfirmation={setShowConfirmation} />}
            {saveChanges && <ChangesSaved message={'You have successfully reset Mary Employee’s password'} />}
        </div>
    );
}

export default ResetPassword;