import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { useState, useEffect } from "react";
import EditDetails from "../../../components/pages/adminDashboard/user/edit/edit_details";
import Verified from "../../../components/pages/adminDashboard/user/verified/verified_tab";
import Paginator from "../../../components/pages/superAdmin/activity_log/paginator.component";
import CreateDetails from "../../../components/pages/adminDashboard/user/create/create_details";

import ReportComponent from "../../../components/pages/adminDashboard/reports/ReportComponent";
const Report = () => {
	const [activeTab, setActiveTab] = useState("tab1");
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (isModalOpen) {
			// Disable scrolling
			document.body.style.overflow = "hidden";
		} else {
			// Enable scrolling
			document.body.style.overflow = "auto";
		}

		// Cleanup on component unmount
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isModalOpen]);

	const activeStyle = {
		color: "#9835D6",
		borderBottom: "2px solid #9835D6",
	};

	const handleAddUserClick = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className="w-full  ">
			<div className="w-full  bg-white  p-4 rounded-xl">
				<Tabs.Root
					className="TabsRoot"
					defaultValue="tab1"
					onValueChange={(value) => setActiveTab(value)}
				>
					<div className="flex justify-between items-center">
						<Tabs.List
							className="space-x-5 flex flex-row text-sm border-b-2 w-5/6 md:text-lg font-bold"
							aria-label="Manage"
						>
							<Tabs.Trigger
								style={activeTab === "tab1" ? activeStyle : {}}
								className="hover:text-primary_color cursor-pointer text-grey"
								value="tab1"
							>
								Reports
							</Tabs.Trigger>
						</Tabs.List>
					</div>

					<Tabs.Content className="" value="tab1">
						<div>
							<ReportComponent />
						</div>
					</Tabs.Content>
				</Tabs.Root>

				{isModalOpen && (
					<div
						style={{
							position: "fixed",
							inset: "0",
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							zIndex: "50",
						}}
					>
						<div>
							<CreateDetails onClose={handleCloseModal} />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Report;
