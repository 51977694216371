const Activity = ({ serialNo, email, role, login_date, login_time, action_performed }) => {
    return (
        <>
            <div className="w-full flex flex-row items-center justify-between p-5 bg-soft_grey mt-4 rounded-md " >
                <div style={{ width: "4%" }} >
                    <p className="text-sm font-medium">{serialNo}</p>
                </div>
                <div style={{ width: "20%" }} >
                    <p className="text-left text-sm font-medium "  >{email}</p>
                </div>
                <div style={{ width: "19%", }} className="flex flex-row items-center justify-start " >
                    <p className="text-sm font-medium" >{role}</p>
                </div>
                <div style={{ width: "19%", }} className="  flex flex-row items-center justify-start" >
                    <p className="p-0 m-0 text-sm font-medium left-4  relative"  >{login_date}</p>
                </div>
                <div style={{ width: "19%", }} className="flex flex-row items-center justify-between " >
                    <p className="text-right text-sm font-medium left-4  relative" >{login_time}</p>
                </div>
                <div style={{ width: "19%", }} className="flex flex-row items-center justify-center " >
                    <p className=" text-sm font-medium" >{action_performed}</p>
                </div>
            </div>
        </>
    );
}

export default Activity;