import React from 'react';
import { useLocation } from 'react-router-dom'; 
import { useSelector, useDispatch } from 'react-redux'; 
import axios from 'axios'; 
import { setSubscriptionId } from '../../../redux/slices/plan_slice'; 
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; // Import the toastify CSS

const Subscribe = () => {
  const location = useLocation();
  const dispatch = useDispatch(); 

  // Get the token and savedPlan from Redux store
  const registerToken = useSelector(state => state.register.token); 
  const resubscribeToken = useSelector(state => state.resubscribe.resubscribetoken); // Get resubscribe token
  const savedPlan = useSelector(state => state.savedplan.selectedPlan);

  // Use the plan from location.state or fallback to savedPlan
  const { plan, isTrial } = location.state || savedPlan || {};

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Prepare the request body
      const requestBody = {
        planCode: plan?.paystackPlanDetails?.planCode, // Get the plan code
        isTrial: isTrial, // Set to true as we're handling the trial logic only
      };

        // Determine the Authorization token: use registerToken if available, otherwise use resubscribeToken
        const authToken = registerToken ? registerToken : resubscribeToken;

      // Make the Axios POST request
      const response = await axios.post('https://api.mystarsonline.com/api/Subscription/subscribe-web', requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}` // Add the token to the Authorization header
        }
      });

      // Log the response from the API
      console.log("API Response:", response.data);

      // Save the subscription ID to the Redux store
      const subscriptionId = response.data.data.subscriptionId;
      dispatch(setSubscriptionId(subscriptionId));

      // Redirect to the authorization URL (Paystack checkout)
      window.location.href = response.data.data.authorizationUrl;

    }  catch (error) {
      // Handle error responses
      if (error.response && error.response.data) {
          const responseData = error.response.data;
  
          // If the error data is a string
          if (typeof responseData === 'string') {
              toast.error(responseData); // Display as a toast
          } 
          // If the error data is an object
          else if (typeof responseData === 'object') {
              const errorMessage = responseData.title || responseData.message || 'An error occurred';
              
              // If validation errors exist
              if (responseData.errors) {
                  const validationErrors = Object.values(responseData.errors).flat(); // Flatten array of errors
                  toast.error(`${errorMessage}: ${validationErrors.join(', ')}`); // Display as a toast
              } else {
                  toast.error(errorMessage); // Display as a toast
              }
          }
      } else {
          // Generic error alert
          toast.error('An unexpected error occurred.');
      }
  
      console.log("Error while subscribing:", error);
    }
  };

  const getPricingMessage = () => {
    return `You will be charged ₦${plan?.amount /100?.toLocaleString()} after your ${plan?.trialDuration || 0} days trial is over.`;
  };

  return (
    <div className="bg-white">
      <ToastContainer  position="top-center"/> 
      <main className="px-4 sm:px-6 md:px-10 lg:px-40 pt-10 2xl:pt-32">
        <div className="flex flex-col py-9">
          <h1 className="text-base md:text-3xl lg:leading-[75px] 2xl:text-5xl font-bold">
            Subscribe to <span className="text-primary_color">{plan?.name || 'Plan'}</span>
          </h1>
          <p className="text-base md:text-2xl 2xl:text-2xl 2xl:pt-3 font-normal">
            You'll need to provide us with some information before you can check out.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col items-start mt-9">
          <div className="w-full bg-[#F7F7F7] rounded-xl flex flex-col md:flex-row gap-y-4 md:gap-y-0 py-5">
            <div className="flex flex-col w-full md:w-1/2 flex-auto px-2 sm:px-6 md:px-10 lg:px-20">
              <label className="capitalize pb-2 text-base md:text-2xl 2xl:text-3xl font-semibold">
                Selected Package
              </label>
              <div className="border border-gray-300 rounded px-3 py-2 2xl:text-2xl">
                {plan?.name || 'No plan selected'}
              </div>
            </div>
            <div className="w-full md:w-1/2 flex-auto flex items-center px-2">
              <p className="max-w-[400px] 2xl:text-2xl">
                {getPricingMessage()}
              </p>
            </div>
          </div>

          <button type="submit" className="bg-[#9835D6] cursor-pointer text-white font-semibold py-2 px-24 my-8 rounded 2xl:text-xl">
            Submit
          </button>
        </form>
      </main>
    </div>
  );
};

export default Subscribe;
