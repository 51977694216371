import { RiExpandUpDownFill } from "react-icons/ri";

const OptionTabAdminTicket = ({ options }) => {
    const optionsWithIcon = ["Ticket No", "Email", "Subject", "Expiry Date", "Date Joined"];

    const columnWidths = {
        "S/N": "5%",
        "Ticket No": "12%", // Adjusted for better spacing
        "Email": "18%",
        "Subject": "18%",
        "Ticket Status": "12%", // Reduced to make space for other columns
        "Last Updated": "15%",
        "Date Created": "14%", // Added Date Created width
    };

    return (
        <div className="py-6 bg-primary_color mt-4 rounded-xl p-3 flex text-sm justify-between w-[700px] md:w-full">
            {options.map(({ option }, index) => (
                <div 
                    key={index} 
                    style={{ width: columnWidths[option] || '15%' }} 
                    className={`option-tab-item text-sm flex items-center justify-center`}
                >
                    <p className="text-white text-sm font-bold flex items-center">
                        {option}
                        {optionsWithIcon.includes(option) && <RiExpandUpDownFill className="ml-1" />}
                    </p>
                </div>
            ))}
        </div>
    );
}

export default OptionTabAdminTicket;
