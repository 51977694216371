import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import pattern from "../../../assets/images/cycle pattern.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { CiStar } from "react-icons/ci";
import { MdStarBorderPurple500 } from "react-icons/md";

const Testimonial = () => {
	const splideRef = useRef(null);
	const [testimonials, setTestimonials] = useState([]);

	useEffect(() => {
		// Fetch testimonials from the API
		axios
			.get("https://api.mystarsonline.com/api/Testimonial/list-testimonials")
			.then((response) => {
				const latestTestimonials = response.data.testimonials.slice(); // Get the latest 3 testimonials
				setTestimonials(latestTestimonials);
			})
			.catch((error) => {
				console.error("Error fetching testimonials:", error);
			});

		const interval = setInterval(() => {
			if (splideRef.current) {
				splideRef.current.splide.go(">"); // Go to next slide
			}
		}, 3000); // Change slide every 3000 milliseconds

		return () => clearInterval(interval); // Clear interval when component unmounts
	}, []);

	const [dimensions, setDimensions] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setDimensions(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	// Function to format the date
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString("en-GB", {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
		});
	};

	return (
		<>
			<style>
				{`
          @media (max-width: 768px) {
            .splide__arrows {
              display: none;
            }
          }
        `}
			</style>
			<div className="w-full pt-5 relative overflow-hidden flex flex-col pb-8 px-5 sm:px-10 ">
				<img
					src={pattern}
					alt="pattern"
					className="absolute -top-40 w-full md:-top-96 lg:-top-96 lg:w-2/3 self-center z-[-1]"
				/>
				<div className="w-full mt-32">
					<h1 className="text-center  w-full text-xl text-black lg:text-3xl 2xl:text-6xl font-semibold mt-10  lg:mb-10">
						Testimonials from partners
					</h1>
				</div>
				<div className="w-full flex justify-center  ">
					{/* <div className="w-full flex flex-row p-10 sm:p-5 items-center justify-evenly md:flex-row md:flex-wrap lg:flex lg:flex-row lg:flex-nowrap lg:px-24 gap-5 py-10 sm:py-5 2xl:py-10 2xl:px-40"> */}
					<Splide
						className="py-10"
						ref={splideRef}
						options={{
							rewind: true,
							perPage: dimensions > 1000 ? 3 : dimensions > 800 ? 2 : 1,
							gap: dimensions > 700 ? "1rem" : "1",
							arrows: true,
							Pagination: true,
						}}
					>
						{testimonials.map(
							({
								name,
								review,
								roleInSchool,
								schoolName,
								pictureUrl,
								rating,
								createdDate,
							}) => (
								<SplideSlide
									key={name + Math.random()}
									// className="w-full flex bg-red-900 justify-center flex-col items-center col-span-1 shadow-shadow_color shadow-lg"
									className="bg-white rounded-xl  flex w-full sm:w-1/2 lg:w-1/3  justify-center  flex-col items-center  shadow-shadow_color shadow-lg  gap-2 sm:gap-4  "
									// className="w-full flex bg-white justify-center flex-col items-center md:w-5/12 lg:w-1/3 shadow-shadow_color shadow-lg p-10 sm:p-4 z-10 2xl:p-20"
								>
									<img
										src={`https://api.mystarsonline.com${pictureUrl}`}
										alt="testimonial-img"
										className="rounded-full object-cover w-14 sm:w-10 2xl:w-40"
									/>
									<p className="text-center text-grey text-sm lg:text-base px-2 ">
										{/* <p className="text-center text-grey text-sm mt-8 w-full sm:px-2 sm:text-xs 2xl:text-3xl  px-4 "> */}
										{review}
									</p>
									<hr className="m-auto w-3/5 bg-background my-9" />
									<p className="text-center text-black text-sm mt-2 font-semibold sm:text-xs 2xl:text-3xl ">
										{name}
									</p>
									<p className="text-center text-black text-sm mt-2 font-semibold sm:text-xs 2xl:text-3xl">
										{roleInSchool}
									</p>
									<p className="text-center text-black text-sm mt-2 font-semibold sm:text-xs 2xl:text-3xl">
										{schoolName}
									</p>
									<p className="flex flex-row pt-2 text-center text-xs font-normal sm:text-xs 2xl:text-3xl">
										{[...Array(5)].map((_, index) => (
											<MdStarBorderPurple500
												key={index}
												className={
													index < rating ? "text-yellow-300 text-lg" : ""
												}
											/>
										))}
									</p>
									<p className="text-center pt-2 text-grey text-xs font-normal sm:text-xs 2xl:text-3xl">
										{formatDate(createdDate)}
									</p>
								</SplideSlide>
							)
						)}
					</Splide>
				</div>
			</div>
		</>
	);
};

export default Testimonial;
