import axios from 'axios';

const getTenant = async (token) => {
  try {
    const response = await axios.get('https://api.mystarsonline.com/api/Signup/get-tenants', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Network error');
    }
  }
};

export default getTenant;
