import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/slices/logout_slice";
import { FaSignOutAlt } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import { TbPackages } from "react-icons/tb";
import { IoIosGlobe } from "react-icons/io";
import { TiMessages } from "react-icons/ti";
import { HiOutlineTicket } from "react-icons/hi2";
import { GoReport } from "react-icons/go";
import { HiOutlineShieldCheck } from "react-icons/hi";
import { MdPayment } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import Logout from "./logout";

const routes = [
	{
		id: 1,
		label: "Dashboard",
		icon: MdOutlineDashboard,
		href: "/admin/dashboard",
	},
	{ id: 2, label: "Users", icon: FiUsers, href: "/admin/user" },
	{
		id: 3,
		label: "Account Manager",
		icon: GrUserManager,
		href: "/admin/account",
	},
	{ id: 4, label: "Packages", icon: TbPackages, href: "/admin/package" },
	{ id: 5, label: "Website", icon: IoIosGlobe, href: "/admin/website" },
	{ id: 6, label: "Messages", icon: TiMessages, href: "/admin/message" },
	{ id: 7, label: "Tickets", icon: HiOutlineTicket, href: "/admin/ticket" },
	{
		id: 8,
		label: "Administration",
		icon: HiOutlineShieldCheck,
		href: "/admin/administration",
	},
	{ id: 9, label: "Reports", icon: GoReport, href: "/admin/report" },
	{ id: 10, label: "Payments", icon: MdPayment, href: "/admin/payment" },
];

function Sidebar() {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

	const handleLogout = async () => {
		try {
			// Dispatch the logoutUser action to clear login.logintoken, savedplan, and perform logout
			await dispatch(logoutUser()).unwrap();
			// Redirect to the login page after logout
			navigate("/");
		} catch (error) {
			console.error("Logout failed:", error);
		}
	};

	const openLogoutModal = (e) => {
		e.preventDefault();
		setIsLogoutModalOpen(true);
	};

	const closeLogoutModal = () => {
		setIsLogoutModalOpen(false);
	};

	const confirmLogout = () => {
		handleLogout();
		closeLogoutModal();
	};

	return (
		<div className="   w-64  h-full  relative  ">
			<div className="  h-full w-full ">
				<nav className=" bg-white shadow-2xl p-4 rounded-xl w-full  ">
					{routes.map((route) => (
						<Link
							key={route.id}
							to={route.href}
							className={`flex items-center py-3 px-4 mb-2 rounded-lg transition-all duration-300  ${
								location.pathname === route.href
									? "text-primary_color "
									: "text-grey hover:bg-gray-100 hover:text-black"
							}`}
						>
							<route.icon className=" mr-4 font-extrabold" />
							<span className="text-sm  font-medium">
								{/* <span className="text-sm xl:text-sm 2xl:text-xl font-medium"> */}

								{route.label}
							</span>
						</Link>
					))}
					{/* Logout Link */}
					<Link
						to="/"
						onClick={openLogoutModal}
						className="flex items-center py-3 px-4 mt-6 text-red-700 hover:bg-red-100 hover:text-red-900 rounded-lg transition-all duration-300"
					>
						<FaSignOutAlt className="text-lg  mr-4" />
						<span className="text-sm 2xl:text-xl font-medium">Logout</span>
					</Link>
				</nav>
			</div>

			{/* Logout Modal */}
			{isLogoutModalOpen && (
				<Logout onClose={closeLogoutModal} onConfirm={confirmLogout} />
			)}
		</div>
	);
}

export default Sidebar;
