
import ForgetPasswordForm from "../../../components/pages/forgetpassword/forgetpassword_form";
const ForgetPassword = () => {
    

    const options = [
        { name: "Employee", route: "/employee" },
        { name: "Student", route: "/student" },
        { name: "Parent", route: "/employee" }
    ];

    return (
        <div className="w-full h-full flex justify-center px-14 " >
        <div className=" " >
          <ForgetPasswordForm />
        </div>  
      </div>
    );
}

export default ForgetPassword;
