import * as Tabs from "@radix-ui/react-tabs";
import VerifiedTicket from "../../../components/pages/adminDashboard/tickets/verified_tab";
import { useState } from "react";
import Paginator from "../../../components/pages/superAdmin/activity_log/paginator.component";
import { AiOutlineSearch } from "react-icons/ai";

const Ticket = () => {
	const [activeTab, setActiveTab] = useState("tab1");
	const [searchQuery, setSearchQuery] = useState("");

	const activeStyle = {
		color: "#9835D6",
		borderBottom: "2px solid #9835D6",
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	return (
		<div className="w-full  rounded-xl">
			{/* <div className="w-full mt-8  p-4 rounded-xl"> */}
			<Tabs.Root
				className="TabsRoot"
				defaultValue="tab1"
				onValueChange={(value) => setActiveTab(value)}
			>
				<div className="flex justify-between items-center">
					<Tabs.List
						className="space-x-5 flex flex-row text-sm border-b-2 w-5/6 md:text-lg font-bold"
						aria-label="Manage"
					>
						<Tabs.Trigger
							style={activeTab === "tab1" ? activeStyle : {}}
							className="hover:text-primary_color cursor-pointer text-grey"
							value="tab1"
						>
							Tickets
						</Tabs.Trigger>
					</Tabs.List>
				</div>
				<div className="flex justify-end mt-4">
					<div className="border border-gray-300 rounded-full focus:outline-none relative w-full md:w-1/3">
						<input
							type="text"
							className="w-full p-2 pr-10 "
							placeholder="Search..."
							value={searchQuery}
							onChange={handleSearchChange}
						/>
						<AiOutlineSearch className="absolute right-3 top-2 text-gray-400" />
					</div>
				</div>
				<Tabs.Content value="tab1">
					<VerifiedTicket />
				</Tabs.Content>
			</Tabs.Root>
		</div>
	);
};

export default Ticket;
