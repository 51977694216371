import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenants } from '../../../../../redux/slices/tenant_slice';
import TableInfoAllSchool from './Table';
import OptionTabAllSchool from './option_tab';
import Paginator from '../../../superAdmin/activity_log/paginator.component';
import { AiOutlineSearch } from 'react-icons/ai';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton';
const AllSchool = () => {
  const dispatch = useDispatch();
  const tenants = useSelector((state) => state.tenant.tenants);
  const status = useSelector((state) => state.tenant.status);
  const error = useSelector((state) => state.tenant.error);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  // Filter and search states
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [packageFilter, setPackageFilter] = useState('');
  const [schoolFilter, setSchoolFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');

  // Sort state to track sorting for each field
  const [sortConfig, setSortConfig] = useState({
    name: null,
    status: null,
    school: null,
    joined: null,
  });

  useEffect(() => {
    dispatch(fetchTenants());
  }, [dispatch]);

  // Handle sorting on click
  const handleSort = (type) => {
    setSortConfig((prevConfig) => {
      const nextConfig = { ...prevConfig };

      if (nextConfig[type] === 'asc') {
        nextConfig[type] = 'desc';
      } else if (nextConfig[type] === 'desc') {
        nextConfig[type] = null;
      } else {
        nextConfig[type] = 'asc';
      }

      return nextConfig;
    });
  };

  // Sorting and filtering logic
  const filteredAndSortedTenants = useMemo(() => {
    let sortableTenants = [...tenants];

    // Search filtering
    if (searchQuery) {
      sortableTenants = sortableTenants.filter((tenant) => {
        return (
          tenant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tenant.school.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tenant.packages.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tenant.status.toLowerCase().includes(searchQuery.toLowerCase()) // Enable search for status (active/inactive)
        );
      });
    }

    // Status filtering from the dropdown
    if (statusFilter) {
      sortableTenants = sortableTenants.filter((tenant) => tenant.status === statusFilter);
    }

    // Sorting by name
    if (sortConfig.name) {
      sortableTenants.sort((a, b) => {
        if (sortConfig.name === 'asc') {
          return a.name.localeCompare(b.name);
        } else if (sortConfig.name === 'desc') {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });
    }

    // Sorting by status
    if (sortConfig.status) {
      sortableTenants.sort((a, b) => {
        if (sortConfig.status === 'asc') {
          return a.status.localeCompare(b.status);
        } else if (sortConfig.status === 'desc') {
          return b.status.localeCompare(a.status);
        }
        return 0;
      });
    }

    // Sorting by school
    if (sortConfig.school) {
      sortableTenants.sort((a, b) => {
        if (sortConfig.school === 'asc') {
          return a.school.localeCompare(b.school);
        } else if (sortConfig.school === 'desc') {
          return b.school.localeCompare(a.school);
        }
        return 0;
      });
    }

    // Sorting by joined date
    if (sortConfig.joined) {
      sortableTenants.sort((a, b) => {
        if (sortConfig.joined === 'asc') {
          return new Date(a.joined) - new Date(b.joined);
        } else if (sortConfig.joined === 'desc') {
          return new Date(b.joined) - new Date(a.joined);
        }
        return 0;
      });
    }

    return sortableTenants;
  }, [tenants, sortConfig, searchQuery, statusFilter]);

  const totalTenants = filteredAndSortedTenants.length;
  const totalPages = Math.ceil(totalTenants / pageSize);

  const currentTenants = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return filteredAndSortedTenants.slice(startIndex, startIndex + pageSize);
  }, [currentPage, filteredAndSortedTenants]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const tabOptions = [
    { option: 'S/N' },
    { option: 'Name', sortable: true },
    { option: 'Status', sortable: true },
    { option: 'Package', sortable: true },
    { option: 'School', sortable: true },
    { option: 'Joined', sortable: true },
    { option: 'Action' },
  ];

  return (
    <div className="w-full pb-10 mt-8  relative">
      {/* Search Bar */}
      <div className="flex justify-end mt-4">
        <div className="border border-gray-300 rounded-full focus:outline-none relative w-full md:w-1/3">
          <input
            type="text"
            className="w-full p-2 pr-10"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <AiOutlineSearch className="absolute right-3 top-3 text-black" />
        </div>
      </div>

        {/* Table section*/}
      <div className="overflow-x-auto"> {/* Add horizontal scrolling here */}
      {/* Option Tabs */}
      <OptionTabAllSchool 
        options={tabOptions} 
        sortConfig={sortConfig} 
        handleSort={handleSort} 
      />

      {/* Table */}
      {status === 'loading' && <p className='pt-20'><Skeleton count={8} /> </p>}
      {status === 'failed' && <p>Error: {error}</p>}
      {status === 'succeeded' && currentTenants.map(({ id, name, status, packages, school, joined }, index) => (
        <TableInfoAllSchool
          key={id}
          serialNo={(currentPage - 1) * pageSize + index + 1}
          id={id}
          name={name}
          status={<span style={{ color: status === 'Active' ? 'green' : 'red' }}>{status}</span>}
          packages={<span style={{ color: packages === 'Free' ? 'green' : packages === 'Starter' ? 'blue' : packages === 'Pro' ? 'orange' : 'purple' }}>{packages}</span>}
          school={school}
          joined={joined}
        />
      ))}

      {/* Pagination Controls */}
      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalTenants}
        pageSize={pageSize}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
      </div>
    </div>
  );
};

export default AllSchool;
