import React from 'react';
import VerifiedFeatures from '../testimonial/testimonialtable/verified_features';

const FeaturesTab = () => {
  return (
    <div className="p-6 ">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-semibold text-black mb-1">Title</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              className="w-full p-2"
              placeholder="Lorem Ipsum torem"
            />
          </div>
        </div>
        <div className="flex flex-col items-start">
          <label className="block text-sm font-semibold text-black mb-1">Header Logo</label>
          <div className="border border-gray-300 rounded-xl p-8 flex justify-center items-center cursor-pointer">
            <img src="" alt="Upload" className="w-14 h-14" />
          </div>
        </div>
        
        <div>
          <label className="block text-sm font-semibold text-black mb-1">Sub Title</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              className="w-full p-2"
              placeholder="Lorem Ipsum torem"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6 space-x-3">
        <button type="button" className="bg-primary_color text-white px-4 py-2 rounded-xl">Submit</button>
        <div className="border border-primary_color px-4 py-1 rounded-xl inline-block">
          <button type="button" className="text-primary_color w-full h-full">Reset</button>
        </div>
      </div>

      <div className="">
        <h2 className="text-lg  text-grey border-b">Title List</h2>
            <VerifiedFeatures/>
      </div>
    </div>
  );
};

export default FeaturesTab;
