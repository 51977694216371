import { dateCreator } from "../../../utilities/dateCreator";
import { DropDownIcon, MenuIcon, MessageIcon, NotificationIcon, SearchIcon } from "../../common/icons/icons";
import Logo from "../../common/logo/logo.component";
import {Link} from "react-router-dom"
const SuperAdminNavbar = ({ setToggleSideBar, toggleSideBar }) => {
    const {todaysDate} = dateCreator()
    return (
        <>
            <nav className="bg-white text-white p-4 shadow-md  shadow-soft_grey flex">
                <div className=" w-2/5 md:w-1/2 lg:w-1/2 flex justify-start items-center gap-3" >
                    <MenuIcon color={'black'} size={20} style={{ cursor: "pointer" }} onClick={() => {
                        setToggleSideBar(!toggleSideBar)
                    }} />
                    <SearchIcon color={'#8D98AA'} size={18} style={{ cursor: "pointer" }} className={' black md:hidden lg:hidden'} />
                    <div className={` hidden md:flex lg:flex lg:items-center ${!toggleSideBar && 'm-auto'} ${!toggleSideBar ? 'w-3/5' : 'flex-1'} lg:justify-between  bg-light_blue rounded-2xl p-2 pr-5 pl-5`} >
                        <input placeholder="Search" style={{ backgroundColor: "transparent" }} className=" text-xs text-black border-0 outline-none flex-1 placeholder:text-xs" />
                        <SearchIcon color={'#8D98AA'} size={16} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div className=" w-3/5 md:w-1/2 flex items-center justify-evenly " >
                    <div className="flex w-2/5 items-center justify-end gap-4 relative " >
                        <div style={{ position: "relative" }} >
                            <NotificationIcon  style={{ cursor: "pointer" }} className={' text-lg md:text-xl'} color={'#bcbcbc'} />
                            <div style={{
                                position: "absolute",
                                width: "12px",
                                height: "12px",
                                borderRadius: '12px',
                                backgroundColor: "red",
                                right: '-3px',
                                top: "-1px",
                                fontSize: 9,
                                textAlign: "center",
                                color: "white",
                                zIndex: 1
                            }}>1</div>
                        </div>
                        <MessageIcon className={' text-lg md:text-xl'} style={{ cursor: "pointer" }} color={'#bcbcbc'} />
                        <DropDownIcon className={' text-lg md:text-xl'} style={{ cursor: "pointer" }} color={'#bcbcbc'} />

                    </div>
                    <div className=" w-3/5 flex-col flex items-center justify-center  " >
                       <Link to={"/"} >
                       <Logo className={' w-20 md:w-34 lg:w-40 object-contain lg:h-10'} /></Link>
                        <p style={{ fontSize: 10 }} className="text-black  m-0" >{todaysDate}</p>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default SuperAdminNavbar;