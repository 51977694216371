// Import images for each category
import schoolImage from "../../../assets/images/image-removebg-preview (14) 1.png";
import studentImage from "../../../assets/images/image-removebg-preview (13) 1.png";
import teachersImage from "../../../assets/images/image-removebg-preview (11) 1.png";
import parentsImage from "../../../assets/images/image-removebg-preview (12) 1.png";
import file from "../../../assets/images/file.png";


// Define the management list with detailed descriptions
export const management_list = [
  {
    title: "School",
    message: [
      { key: "Boost Efficiency", desc: "Save time and resources with automated tasks and centralized data" },
      { key: "Improve Communication", desc: "Foster a collaborative environment with seamless communication between teachers, parents, and administrators" },
      { key: "Data-Driven Decisions", desc: "Gain valuable insights to personalize learning experiences and make informed strategic decisions" }
    ],
    image: schoolImage,
    color: "#FFE1EC"
  },
  {
    title: "Students",
    message: [
      { key: "Stay Organized", desc: "Easily access assignments, track grades, and manage deadlines from one central location" },
      { key: "Enhanced Learning", desc: "Access online resources and participate in interactive learning activities (optional add-on)" },
      { key: "Improved Communications", desc: "Connect with teachers and peers more effectively" }
    ],
    image: studentImage,
    color: "#F7ECFF"
  },
  {
    title: "Teachers",
    message: [
      { key: "Save Time", desc: "Reduce administrative burdens and dedicate more time to personalized instruction" },
      { key: "Enhanced Organization", desc: "Manage class schedules, assignments, and grading more efficiently" },
      { key: "Improved Communication", desc: "Communicate effortlessly with students and parents, fostering a stronger learning community" }
    ],
    image: teachersImage,
    color: "#ECCCFF"
  },
  {
    title: "Parents",
    message: [
      { key: "Real-Time Visibility", desc: "Monitor student attendance and assignments with a dedicated parent portal (optional add-on)" },
      { key: "Streamlined Communication", desc: "Stay informed and connected with teachers regarding your child's education" },
      { key: "Active Participation", desc: "Engage in your child's learning journey more effectively" }
    ],
    image: file,
    color: "#FFF4D8"
  }
];

// Note: Ensure the image paths are correct and accessible from your project's structure
