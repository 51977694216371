import React, { useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import Web from '../../../components/pages/adminDashboard/package/web/Web';
import Mobile from '../../../components/pages/adminDashboard/package/mobile/Mobile';

const Package = () => {
  const [activeTab, setActiveTab] = useState('package'); // Correct initial state set here

  const activeStyle = {
    color: '#9835D6',
    borderBottom: '2px solid #9835D6'
  };

  return (
    <div className='w-full mt-8 bg-white p-4 rounded-xl'>
      <Tabs.Root 
         className="TabsRoot"
         value={activeTab}
         onValueChange={setActiveTab}
      >
        <Tabs.List className="space-x-2 flex flex-row text-sm border-b-2 w-full md:text-lg font-bold">
          <Tabs.Trigger 
              style={activeTab === 'package' ? activeStyle : {}}
              className="hover:text-primary_color cursor-pointer"
              value="package"
          >
            Package
          </Tabs.Trigger>
          <Tabs.Trigger 
               style={activeTab === 'starTracker' ? activeStyle : {}}
               className="hover:text-primary_color cursor-pointer"
               value="starTracker"
          >
            Star Tracker
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="package">
          <Web />
        </Tabs.Content>
        <Tabs.Content value="starTracker">
          <Mobile/>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
};

export default Package;
