import { useEffect, useRef, useState } from "react";
import Button from "../../common/button/button.component";
import PhoneInput from "react-country-phone-input";
import 'react-country-phone-input/lib/style.css';
import ContactModal from "./contact_modal.component";
import axios from 'axios';
import './styles.css';

const ContactForm = () => {
    const form = useRef();
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [schoolRole, setSchoolRole] = useState("");
    const [message, setMessage] = useState("");
    const [modal, setModal] = useState(false);
    const [btnColor, setBtnColor] = useState(false);

    const handleRadioChange = (option) => {
        setSelectedOption(option);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (name && email && selectedOption && schoolName && phoneNumber && schoolRole && message) {
            setLoading(true);

            const requestBody = {
                name,
                email,
                organizationType: selectedOption,
                phoneNumber,
                schoolName,
                roleInSchool: schoolRole,
                message,
            };

            try {
                const response = await axios.post('https://api.mystarsonline.com/api/SupportTicket/send-complaint', requestBody);
                console.log('Complaint sent successfully:', response.data);
                setModal(true); // Show the modal after successful submission
            } catch (error) {
                console.error('Error sending complaint:', error);
                console.log(error)
                // Handle the error if needed
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (name && email && selectedOption && schoolName && phoneNumber && schoolRole && message) {
            setBtnColor(true);
        } else {
            setBtnColor(false);
        }
    }, [name, email, selectedOption, schoolName, phoneNumber, schoolRole, message]);

    return (
        <>
            <form ref={form} onSubmit={handleSubmit} className="mt-6 lg:flex lg:flex-wrap lg:items-center lg:justify-between lg:px-2">
                <div className="w-full mb-6 lg:w-2/5">
                    <label className="text-sm font-bold text-left 2xl:text-lg">Your Name</label>
                    <input
                        required
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full bg-white rounded p-2 mt-4 outline-none text-sm"
                        placeholder="Enter your name"
                    />
                </div>
                <div className="w-full mb-6 lg:w-2/5">
                    <label className="text-sm font-bold text-left 2xl:text-lg">Your Email</label>
                    <input
                        required
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full bg-white rounded p-2 mt-4 outline-none text-sm"
                        placeholder="eg. amazingxtra@gmail.com"
                    />
                </div>
                <div className="w-full mb-6 lg:w-2/5">
                    <label className="text-sm font-bold text-left 2xl:text-lg">Select Organisation Type</label>
                    <div className="w-full flex items-center justify-between mt-2 md:justify-start md:gap-5">
                        <div className="flex items-center space-x-2">
                            <input
                                type="radio"
                                id="radioOption1"
                                name="radioGroup"
                                className="hidden"
                                checked={selectedOption === "Independent School"}
                                onChange={() => handleRadioChange("Independent School")}
                            />
                            <label
                                htmlFor="radioOption1"
                                className={`cursor-pointer flex items-center justify-center w-5 h-5 border ${
                                    selectedOption === "Independent School"
                                        ? "border-primary_color"
                                        : "border-primary_color bg-white hover:border-primary_color focus:bg-primary_color"
                                } rounded-full transition duration-300 focus:border-primary_color focus:outline-none`}
                            >
                                {selectedOption === "Independent School" && (
                                    <div className="w-3 h-3 bg-primary_color rounded-full"></div>
                                )}
                            </label>
                            <span className="text-grey text-xs 2xl:text-lg">Independent School</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <input
                                type="radio"
                                id="radioOption2"
                                name="radioGroup"
                                className="hidden"
                                checked={selectedOption === "Group of Schools"}
                                onChange={() => handleRadioChange("Group of Schools")}
                            />
                            <label
                                htmlFor="radioOption2"
                                className={`cursor-pointer flex items-center justify-center w-5 h-5 border ${
                                    selectedOption === "Group of Schools"
                                        ? "border-primary_color"
                                        : "border-primary_color bg-white hover:border-primary_color focus:bg-primary_color"
                                } rounded-full transition duration-300 focus:border-primary_color focus:outline-none`}
                            >
                                {selectedOption === "Group of Schools" && (
                                    <div className="w-3 h-3 bg-primary_color rounded-full"></div>
                                )}
                            </label>
                            <span className="text-grey text-xs 2xl:text-lg">Group of Schools</span>
                        </div>
                    </div>
                </div>
                <div className="w-full mb-6 lg:w-2/5">
                    <label className="text-sm font-bold text-left 2xl:text-lg">Your School Name</label>
                    <input
                        name="school_name"
                        required
                        value={schoolName}
                        onChange={(e) => setSchoolName(e.target.value)}
                        className="w-full bg-white rounded p-2 mt-4 outline-none text-sm"
                        placeholder="eg. Covatech Studios"
                    />
                </div>
                <div className="w-full mb-6 lg:w-2/5">
                    <label className="text-sm font-bold text-left 2xl:text-lg">Your Phone Number</label>
                    <input value={phoneNumber} name="phone_number" style={{ display: "none" }} />
                    <PhoneInput
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e)}
                        country={'ng'}
                    />
                </div>
                <div className="w-full mb-6 lg:w-2/5">
                    <label className="text-sm font-bold text-left 2xl:text-lg">Your role in the School</label>
                    <input
                        name="school_role"
                        required
                        value={schoolRole}
                        onChange={(e) => setSchoolRole(e.target.value)}
                        className="w-full bg-white rounded p-2 mt-4 outline-none text-sm"
                        placeholder="What is your role?"
                    />
                </div>
                <div className="w-full mb-6 lg:w-full">
                    <label className="text-sm font-bold text-left 2xl:text-lg">Your Message</label>
                    <textarea
                        name="message"
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-full bg-white rounded p-2 mt-4 h-32 pt-3 outline-none text-sm lg:h-60"
                        placeholder="What is your message?"
                    />
                </div>
                <div className="w-full my-6 flex justify-center text-center">
                    <Button
                        disabled={!btnColor || loading}
                        btnColor={!loading || btnColor ? 'bg-primary_color' : 'bg-grey'}
                        onClick={handleSubmit}
                        btnTitle={loading ? "Submitting" : 'Submit'}
                        width={'w-44'}
                        padding={'p-2'}
                    />
                </div>
            </form>
            <ContactModal
                setEmail={setEmail}
                setName={setName}
                setMessage={setMessage}
                setSelectedOption={setSelectedOption}
                setPhoneNumber={setPhoneNumber}
                setSchoolRole={setSchoolRole}
                modal={modal}
                setModal={setModal}
                setSchoolName={setSchoolName}
            />
        </>
    );
};

export default ContactForm;
