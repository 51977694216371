const Pocket = () => {
    return (
        <>
            <div className=" w-1/4 border-soft_grey border p-4 rounded-md flex flex-col justify-center relative "  >
                <div className="w-full absolute top-3" >
                    <p className="text-black text-lg font-bold">Pocket</p>
                </div>
                <div className="w-full" >
                    <p className="text-black text-lg font-bold" >NGN</p>
                    <p className="text-primary_color text-5xl font-bold">20,000</p>
                </div>
            </div>
        </>
    );
}

export default Pocket;