import axios from "axios";

export const deleteAccount = async() => {
  try {
    const response =  await axios.post(
      "http://162.0.22.79/api/Persona/delete-account"
    );
    console.log("delete account", response);
  } catch (error) {
    console.log("delete account error", error);
  }
};
