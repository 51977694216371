import Assignment_Table from "../../../components/pages/student/profile/assignments_projects_table.component";
import Pocket from "../../../components/pages/student/profile/pocket.component";
import StudentDataTab from "../../../components/pages/student/profile/student_data_tab.component";
import StudentProfileTab from "../../../components/pages/student/profile/student_profile_tab.component";
import TitleBar from "../../../components/pages/superAdmin/title_bar.component";
import schedule from "../../../assets/images/schedule.png"
import Results_Table from "../../../components/pages/student/profile/results_table.components";
const StudentProfilePage = () => {
    const assignmentData = [
        {
            name: "English",
            start_date: "01/01/2024",
            end_date: "01/01/2024",
            title: "Basic Phoenix for today"
        },
        {
            name: "English",
            start_date: "01/01/2024",
            end_date: "01/01/2024",
            title: "Basic Phoenix for today"
        },
        {
            name: "English",
            start_date: "01/01/2024",
            end_data: "01/01/2024",
            title: "Basic Phoenix for today"
        },
        {
            name: "English",
            start_date: "01/01/2024",
            end_date: "01/01/2024",
            title: "Basic Phoenix for today"
        },
    ]
    const resultData = [
        {
            name: "English",
            date: "01/01/2024",
            category: "Test",
            result: "50/60",
            classAverage: "47/60"
        },
        {
            name: "English",
            date: "01/01/2024",
            category: "Test",
            result: "50/60",
            classAverage: "47/60"
        },
        {
            name: "English",
            date: "01/01/2024",
            category: "Test",
            result: "50/60",
            classAverage: "47/60"
        },
        {
            name: "English",
            date: "01/01/2024",
            category: "Test",
            result: "50/60",
            classAverage: "47/60"
        },
        {
            name: "English",
            date: "01/01/2024",
            category: "Test",
            result: "50/60",
            classAverage: "47/60"
        },

    ]
    return (
        <>
            <div className="w-full my-5" >
                <div className="w-full my-10" >
                <TitleBar showBackIcon={false} title={'Dashboard'} />
                </div>

                <div className="w-full  mt-4 gap-5 " >
                    <div className=" w-full flex items-stretch justify-between gap-4" >
                        <StudentProfileTab />
                        <Pocket />
                    </div>
                    <div className=" w-full " >
                        <StudentDataTab />
                    </div>
                    <div className=" w-full " >
                        <Assignment_Table data={assignmentData} />
                    </div>
                    <div className=" w-full " >
                        <img src={schedule} alt="schedule" className="w-full mt-9" />
                    </div>
                    <div className=" w-full " >
                        <Results_Table data={resultData} />
                    </div>
                    {/* <div className=" w-1/4 " >

                    </div> */}
                </div>
            </div>
        </>
    );
}

export default StudentProfilePage;