import Footer from "../components/common/footer/footer.component";
import NavBar from "../components/common/navigation/navBar.component";

const Layout = ({ children }) => {
  return (
    <>
      <main className="w-full">
        <NavBar />
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;
