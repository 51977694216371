import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Confirm from '../../confirmcreatemodal/confirm';
const Edit = ({ onClose, onSave, selectedPlanDetails }) => {
  const token = useSelector(state => state.login.logintoken); // Access token from Redux
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(''); // Store selected color
  const [showConfirm, setShowConfirm] = useState(false); // State for the confirm modal

  const [formData, setFormData] = useState({
    amount: '0',
    currency: '',
    description: '',
    idealFor: '',
    keyFeatures: '',
    trialDuration: 0,
    colorCode: '',
    maxStudentCapacity: 0,
    paystackPlanCode: '', // Added paystackPlanCode field
  });

  const colors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];

  // Helper function to format number with commas
  const formatNumber = (value) => {
    if (value === '') {
      return ''; // Return an empty string if the input is empty
    }
  
    const numberValue = value.replace(/,/g, ''); // Remove commas
  
    if (!isNaN(numberValue)) {
      return parseFloat(numberValue).toLocaleString(); // Add commas back if it's a valid number
    }
  
    return value; // Return the original value if it's not a valid number
  };

  // Prefill form with selected plan details when selectedPlanDetails changes
  useEffect(() => {
    if (selectedPlanDetails) {
      console.log("Selected Plan Details:", selectedPlanDetails); // Log the selected plan details
      setFormData({
        amount: (selectedPlanDetails.amount / 100)?.toLocaleString() || '0',
        currency: selectedPlanDetails.currency || '',
        description: selectedPlanDetails.description || '',
        idealFor: selectedPlanDetails.idealFor || '',
        keyFeatures: selectedPlanDetails.keyFeatures || '',
        trialDuration: selectedPlanDetails.trialDuration || 0,
        colorCode: selectedPlanDetails.colorCode || '',
        maxStudentCapacity: selectedPlanDetails.maxStudentCapacity || 0,
        paystackPlanCode: selectedPlanDetails.paystackPlanCode || '', // Set paystackPlanCode
      });
      setSelectedColor(selectedPlanDetails.colorCode || '');
    }
  }, [selectedPlanDetails]);

  const toggleColorPicker = () => {
    setOpenColorPicker(!openColorPicker);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setFormData({ ...formData, colorCode: color });
    setOpenColorPicker(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Apply formatNumber only to the amount field
    const formattedValue = name === 'amount' ? formatNumber(value) : value;
    
    setFormData({ ...formData, [name]: formattedValue });
  };

  // This function is triggered when the form is submitted
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form behavior
    setShowConfirm(true); // Show confirm modal when the form is submitted
  };

  // This function is triggered when the user confirms the changes in the confirm modal
  const handleConfirmSubmit = async () => {
    setShowConfirm(false); // Close the confirm modal after confirmation

    try {
      // Prepare data before submission: uppercase currency
      const processedFormData = {
        ...formData,
        currency: formData.currency.toUpperCase(),
        amount: parseFloat(formData.amount.replace(/,/g, '')), // Convert amount back to number
      };

      // Make API put call with Authorization header
      const response = await axios.put(
        'https://api.mystarsonline.com/api/Subscription/update-MobilePlan',
        processedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}` // Attach token from Redux
          }
        }
      );
      if (response.status === 200) {
        console.log("Plan updated successfully:", response.data);
        onSave(); // Call onSave to handle form submission
        onClose(); // Close the modal after successful update
      }
    } catch (error) {
      console.error("Error updating plan:", error);
    }
  };

  return (
    <div className="bg-white rounded-2xl p-6 max-w-2xl mx-auto shadow-md h-auto">
      <div onClick={onClose} className="flex justify-end">
        <span className="bg-primary_color rounded-full p-2 cursor-pointer inline-flex items-center justify-center">
          <AiOutlineClose className="text-white"  />
        </span>
      </div>

      <div className="mb-3">
        <p className="text-center text-2xl font-bold text-primary_color">Edit An Existing Package</p>
        <p className="text-center text-sm font-bold text-black pt-2">Edit a Subscription Package</p>
      </div>

      {/* Scrollable content area */}
      <form onSubmit={handleFormSubmit} className="space-y-4 max-h-[60vh] overflow-y-auto scrollbar-thin scrollbar-thumb-primary_color scrollbar-track-white">
        <div className="flex space-x-8 flex-row items-center">
          <div className='w-[340px]'>
            <label className="block text-sm font-semibold text-black mb-1">Amount</label>
            <div className='border border-black rounded-xl'>
              <input 
                type="text" 
                className="w-full p-2" 
                placeholder="Enter Amount"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='flex space-x-3 items-center pt-5'>
              <div>Package Color:</div>
              <span className='flex text-center items-center bg-gray-200 px-2 rounded-2xl space-x-2 cursor-pointer' onClick={toggleColorPicker}>
                <GrStatusGoodSmall style={{ color: selectedColor }} />
                <span>{selectedColor || "Color"}</span>
              </span>
            </div>
            {openColorPicker && (
              <div className="flex space-x-2 pt-3 ">
                {colors.map(color => (
                  <span 
                    key={color}
                    style={{ backgroundColor: color }}
                    className="w-4 h-4 rounded-full cursor-pointer"
                    onClick={() => handleColorSelect(color)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Currency</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Currency (e.g., NGN, USD)"
              name="currency"
              value={formData.currency}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Description</label>
          <div className='border border-black rounded-xl'>
            <textarea 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows='3'
              style={{ overflow: 'hidden' }}
              onInput={(e) => {
                e.target.style.height = 'auto'; // Reset the height
                e.target.style.height = `${e.target.scrollHeight}px`; // Set it to scrollHeight
              }}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Ideal For</label>
          <div className='border border-black rounded-xl'>
            <textarea 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Ideal For"
              name="idealFor"
              value={formData.idealFor}
              onChange={handleInputChange}
              rows='3'
              style={{ overflow: 'hidden' }}
              onInput={(e) => {
                e.target.style.height = 'auto'; // Reset the height
                e.target.style.height = `${e.target.scrollHeight}px`; // Set it to scrollHeight
              }}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Key Features</label>
          <div className='border border-black rounded-xl'>
            <textarea
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Key Features"
              name="keyFeatures"
              value={formData.keyFeatures}
              onChange={handleInputChange}
              rows='3'
              style={{ overflow: 'hidden' }}
              onInput={(e) => {
                e.target.style.height = 'auto'; // Reset the height
                e.target.style.height = `${e.target.scrollHeight}px`; // Set it to scrollHeight
              }}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Trial Duration (Days)</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Trial Duration"
              name="trialDuration"
              value={formData.trialDuration}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Max Student Capacity</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Max Student Capacity"
              name="maxStudentCapacity"
              value={formData.maxStudentCapacity}
              onChange={handleInputChange}
            />
          </div>
        </div>

       

        <div className="flex justify-end space-x-3 mt-4">
          <button type="submit" className="bg-primary_color text-white px-3 py-1 rounded-xl">Save</button>
          <span className='border rounded-xl border-primary_color'>
            <button type="button" className="text-black px-3 py-1" onClick={onClose}>Cancel</button>
          </span>
        </div>
      </form>

      {/* Confirmation Modal */}
      {showConfirm && (
        <Confirm
          onClose={() => setShowConfirm(false)}
          onConfirm={handleConfirmSubmit}
        />
      )}
    </div>
  );
};

export default Edit;
