import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { setSearchQuery } from '../../../redux/slices/globalsearch_slice'; // Adjust the path

const SearchBar = () => {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();

  const handleSearch = () => {
    // Dispatch the search query to the Redux store
    dispatch(setSearchQuery(inputValue));
  };

  return (
    <div className="relative flex items-center">
      <input
        type="text"
        placeholder="Search..."
        className="min-w-[428px] 2xl:w-[600px] bg-[#EDF2F6] rounded-2xl p-2"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch(); // Trigger search when pressing Enter
          }
        }}
      />
      <FaSearch
        className="absolute right-3 text-gray-400 cursor-pointer"
        onClick={handleSearch}
      />
    </div>
  );
};

export default SearchBar;
