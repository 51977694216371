import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { logout } from './login_slice'; // Import the logout action
import { clearRegisterToken } from './register_slice'; // Import the clearRegisterToken action
import { clearSelectedPlan } from './savedplan_slice'; // Correct import for clearing the saved plan

export const logoutUser = createAsyncThunk('logout/logoutUser', async (_, { getState, rejectWithValue, dispatch }) => {
  try {
    const { login } = getState(); // Access the current state
    const logintoken = login.logintoken;

    // Make the API call to logout
    await axios.post('https://api.mystarsonline.com/api/Auth/Logout', {}, {
      headers: {
        Authorization: `Bearer ${logintoken}`,
      },
    });

    // Dispatch logout action to clear user and token from state
    dispatch(logout());

    // Dispatch action to clear register token
    dispatch(clearRegisterToken());

    // Dispatch action to clear the savedplan slice
    dispatch(clearSelectedPlan()); // Use the correct action here

    return true;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const logoutSlice = createSlice({
  name: 'logout',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.pending, (state) => {
        state.status = 'loading';
        state.error = null; // Clear previous errors
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; // Capture error message
      });
  }
});

export default logoutSlice.reducer;
