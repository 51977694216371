import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { register } from '../../api/admin/register';

// Async thunk to handle user registration
export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (userData, { rejectWithValue }) => {
        try {
            const response = await register(userData);
            // Return both the email and token from the response
            return {
                email: response.data.email,
                token: response.data.token
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const registerSlice = createSlice({
    name: 'register',
    initialState: {
        email: null,
        token: null,  // Initial state for the token
        loading: false,
        error: null,
    },
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        clearRegisterToken: (state) => {
            state.token = null; // Clear the token
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
                state.email = action.payload.email; // Store the email
                state.token = action.payload.token; // Store the token
                console.log(state.token)
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { clearError, clearRegisterToken } = registerSlice.actions;

export default registerSlice.reducer;
