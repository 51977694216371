import VerifyForm from "../../../components/pages/verify/verify_form";
const Verify = () => {
   

    const options = [
        { name: "Employee", route: "/employee" },
        { name: "Student", route: "/student" },
        { name: "Parent", route: "/employee" }
    ];

    return (
        <div className="w-full h-full flex justify-center px-14 " >
        <div className=" " >
          <VerifyForm/>
        </div>  
      </div>
    );
}

export default Verify;
