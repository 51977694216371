import Button from "../../../common/button/button.component";
import { CloseIcon } from "../../../common/icons/icons";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks.ts"
import { useCallback } from "react";
import { clearTermState, createTerm, termEndDateHandler, termStartDateHandler, termTitleHandler } from "../../../../redux/slices/term_slice.ts";
const CreateTerm = ({ setShowModal }) => {
    const dispatch = useAppDispatch()
    const title = useAppSelector(({ term }) => term.title)
    const start_date = useAppSelector(({ term }) => term.start_date);
    const end_date = useAppSelector(({ term }) => term.end_date);
    const titleCallback = useCallback((e) => {
        dispatch(termTitleHandler(e.target.value))
    }, [title])

    const startDateCallBack = useCallback((e) => {
        dispatch(termStartDateHandler(e.target.value))
    }, [start_date])
    const endDateCallBack = useCallback((e) => {
        dispatch(termEndDateHandler(e.target.value))
    }, [end_date])

    const createTermHandler = () => {
        const data = { title, start_date, end_date }
        dispatch(createTerm(data))
        dispatch(clearTermState())
        setShowModal(false)
    }
    return (
        <>
            <div className="bg-white rounded-md py-3 lg:w-3/5 relative  shadow-md " >
                <div className="p-2 flex items-center justify-center absolute rounded-50% top-4 right-10 bg-primary_color" >
                    <CloseIcon cursor={'pointer'} color={'white'} onClick={() => {
                        setShowModal(false)
                    }} />
                </div>
                <div className="w-full my-7" >
                    <h3 className="text-xl font-bold text-primary_color text-center" >Create Term</h3>
                    <p className="text-lg font-bold text-black text-center" >Create new term for your school</p>
                </div>
                <div className="w-full flex items-center justify-start gap-2  px-9 " >
                    <h3 className="text-lg font-bold text-primary_color text-left" >Term Data</h3>
                    <hr style={{ height: ".7px" }} className="flex-1 flex  bg-primary_color border-none" />
                </div>
                <div className="w-full px-9 mt-5 flex items-center justify-evenly gap-14" >
                    <div className=" w-1/2" >
                        <label className=" text-sm font-semibold text-black" >Term Title</label>
                        <input value={title} onChange={(e) => titleCallback(e)} style={{ border: '1px solid #e3e3e3' }} className="w-full rounded-md p-3  outline-none " />
                    </div>
                    <div className=" w-1/4" >
                        <label className=" text-sm font-semibold text-black" >Start Date</label>
                        <input value={start_date} onChange={(e) => startDateCallBack(e)} style={{ border: '1px solid #ededed' }} type="date" className="w-full rounded-md p-3  outline-none " />
                    </div>
                    <div className=" w-1/4" >
                        <label className=" text-sm font-semibold text-black" >End Date</label>
                        <input value={end_date} onChange={(e) => endDateCallBack(e)} style={{ border: '1px solid #ededed' }} type="date" className="w-full rounded-md p-3  outline-none " />
                    </div>
                </div>
                <div className="mt-40 mb-5 px-9 flex items-center justify-end gap-2 " >
                    <Button onClick={() => {
                        createTermHandler()
                    }} btnTitle={'Save'} className={'w-28 p-2 bg-primary_color rounded text-sm text-white text-center '} />
                    <Button btnTitle={'Cancel'} btnColor={'bg-white'} className={'w-28 p-2 bg-white rounded border border-primary_color text-sm '} onClick={() => {
                        setShowModal(false)
                        dispatch(clearTermState())
                    }} />
                </div>
            </div>
        </>
    );
}

export default CreateTerm;