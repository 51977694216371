import moment from "moment";
export const dateCreator = (date) => {
  if (date) {
    return null;
  } else {
    const today = moment();
    const todaysDate = today.format("dddd MMM DD, YYYY");

    return  { todaysDate:todaysDate };
  }
};
