import Button from "../../common/button/button.component";
import img from "../../../assets/images/video_placeholder.png"
// import { PlayIcon } from "../../common/icons/icons";
import pattern from "../../../assets/images/cycle pattern.png"
const SupportHero = () => {
    return (
        <>
            <div className="w-full  relative  bg-light_purple pt-20 px-4 pb-10 md:pt-20 overflow-hidden md:px-6 lg:px-20 2xl:pt-40 " >
                <img src={pattern} className="w-full -right-60 absolute  -top-40 md:w-96 md:-right-40 md:-top-32 lg:w-1/2 lg:-right-40  lg:-top-40" />
                <div className="w-full md:mt-10 " >
                    <h1 className="text-center text-2xl leading-10 font-extrabold md:text-left md:text-3xl lg:text-5xl " >
                        Hi, Welcome!
                    </h1>
                    <p className="text-center text-base leading-10 font-normal md:text-left md:text-lg md:leading-9 lg:text-2xl 2xl:text-3xl lg:leading-snug lg:mt-5" >
                        How may we help you?
                    </p>
                    <div className="cursor-pointer hover:bg-white hover:text-primary_color hover:border-primary_color border border-primary_color rounded-xl w-52 mt-5 m-0 md:m-0 md:mt-4 md:w-52">
                    <button className='rounded-lg w-full bg-transparent items-center justify-center text-primary_color p-3 text-base md:p-2 2xl:text-lg'>
                        + New Support Ticket
                    </button>
                    </div>

                </div>

                <div className="w-full mt-14 relative md:p-0 " >
                    {/* <PlayIcon size={50} color={'white'} className={'absolute z-10 left-40 scale-95  top-20 cursor-pointer md:top-1/2 md:left-1/2 md:scale-110  lg:scale-150'} />
                    <h4 className={'absolute z-10 left-10 text-white top-16 uppercase leading-7 text-lg md:top-40 md:left-10 md:text-4xl md:leading-snug lg:top-1/3 lg:text-5xl lg:left-28 lg:leading-normal '}>How to<br /> install<br /> MyStar</h4> */}
                    <img  src={img} className=" object-cover w-full " alt="support" />
                </div>
            </div>
        </>
    );
}

export default SupportHero;