import { useCallback, useEffect } from "react";
import Button from "../../../common/button/button.component";
import { CloseIcon } from "../../../common/icons/icons";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks.ts";
import { clearSessionState, createSession, endDateHandler, startDateHandler, titleHandler } from "../../../../redux/slices/session_slice.ts";

const CreateSession = ({ setShowModal }) => {
    const dispatch = useAppDispatch();
    const title = useAppSelector(({ session }) => session.title)
    const end_date = useAppSelector(({ session }) => session.end_date)
    const start_date = useAppSelector(({ session }) => session.start_date)
    const titleCallBack = useCallback((e) => {
        dispatch(titleHandler(e.target.value))
    }, [title])
    const startDateCallBack = useCallback((e) => {
        dispatch(startDateHandler(e.target.value))
    }, [start_date])
    const endDateCallBack = useCallback((e) => {
        dispatch(endDateHandler(e.target.value))
    }, [end_date])
    const createHandler = () => {
        const data = { title, start_date, end_date }
        dispatch(createSession(data))
        dispatch(clearSessionState())
        setShowModal(false)
    }
    useEffect(() => {
        return (() => {
            dispatch(clearSessionState())
        })
    }, [])

    return (
        <>
            <div className="bg-white rounded-md py-3 lg:w-3/5 relative  shadow-md " >
                <div className="p-2 flex items-center justify-center absolute rounded-50% top-4 right-10 bg-primary_color" >
                    <CloseIcon cursor={'pointer'} color={'white'} onClick={() => {
                        setShowModal(false)
                    }} />
                </div>
                <div className="w-full my-7" >
                    <h3 className="text-xl font-bold text-primary_color text-center" >Create Session</h3>
                    <p className="text-lg font-bold text-black text-center" >Create new session for your school</p>
                </div>
                <div className="w-full flex items-center justify-start gap-2  px-9 " >
                    <h3 className="text-lg font-bold text-primary_color text-left" >Session Data</h3>
                    <hr style={{ height: ".7px" }} className="flex-1 flex  bg-primary_color border-none" />
                </div>
                <div className="w-full px-9 mt-5 flex items-center justify-evenly gap-3" >
                    <div className=" w-1/2" >
                        <label className=" text-sm font-semibold text-black" >Session Title</label>
                        <input onChange={(e) => titleCallBack(e)} value={title} style={{ border: '1px solid #e3e3e3' }} className="w-full rounded-md p-3  outline-none " />
                    </div>
                    <div className=" w-1/4" >
                        <label className=" text-sm font-semibold text-black" >Start Date</label>
                        <input onChange={(e) => startDateCallBack(e)} value={start_date} style={{ border: '1px solid #ededed' }} type="date" className="w-full rounded-md p-3  outline-none " />
                    </div>
                    <div className=" w-1/4" >
                        <label className=" text-sm font-semibold text-black" >End Date</label>
                        <input onChange={(e) => endDateCallBack(e)} value={end_date} style={{ border: '1px solid #ededed' }} type="date" className="w-full rounded-md p-3  outline-none " />
                    </div>
                </div>
                <div className="mt-40 mb-5 px-9 flex items-center justify-end gap-2 " >
                    <Button onClick={() => { createHandler() }} btnTitle={'Create'} className={'w-28 p-2 bg-primary_color rounded text-sm text-white'} />
                    <Button btnTitle={'Cancel'} btnColor={'bg-white'} className={'w-28 p-2 bg-white rounded border border-primary_color text-sm '} onClick={() => {
                        setShowModal(false)
                        dispatch(clearSessionState())
                    }} />
                </div>
            </div>
        </>
    );
}

export default CreateSession;