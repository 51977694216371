import img from "../../../assets/images/FRONT PAGE copy 1.png"
import LoginForm from "../../../components/pages/login/login_form.component";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { LoginSchema } from "../../../infrastructure/schema/loginSchema";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../services/auth/login_user.ts";
const LoginEmployee = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LoginSchema),
    })
    const onSubmit = (values) => {
        navigate("/employee/dashboard")
        // const request = fetch("http://162.0.222.79:7000/api/Auth/Login", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         Accept: "application/json",
        //     },
        //     body: JSON.stringify({
        //         email: values?.email,
        //         password: values.password
        //     })
        // });
        // request
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log("response", data);
        //     })
        //     .catch(error => {
        //         console.error("Error during fetch:", error);
        //     });

        // dispatch(loginUser({
        //     email: values.email,
        //     password: values.password,
        //     navigate: navigate
        // }))

    }
    return (
        <>
            <div className="w-full h-full flex items-start lg:items-center justify-evenly min-[1025px]:items-center  " >
                <div className=" w-80 top-0 relative flex items-center justify-center  lg:w-300px lg:fixed z-10 md:relative md:w-96 md:top-0 md:left-0 lg:left-24 lg:top-36 " >
                    <LoginForm goToRoute={'/employee/dashboard'} message={"Sign In As An Employee"} placeholder1={'Email, adm no., phone no.'} placeholder2={"Password"} value1={'email'} value2={"password"} register={register} onSubmit={onSubmit} handleSubmit={handleSubmit} errors={errors} />
                </div>
                <div className=" hidden md:hidden lg:flex relative w-full ">
                    <img src={img} className="w-full fixed md:right-20 lg:-right-52 relative min-[1024px]:h-fit max-[ 1280px]:h-fit xl:h-80vh xl:object-contain min-[1281px]:-bottom-20" alt="login" />
                </div>
            </div>
        </>
    );
}

export default LoginEmployee;