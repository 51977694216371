import { FaRegCircleCheck } from "react-icons/fa6";

const ChangesSaved = ({ message }) => {
    return (
        <>
            <div className="w-full p-14 " >
                <div className="w-full" >
                    <p className="text-center text-primary_color font-bold  text-xl uppercase " >Changes Saved</p>
                </div>
                <div className="w-full my-4 flex justify-center items-center" >
                    <FaRegCircleCheck size={100} color="#9835D6" />
                </div>
                <div className="w-full" >
                    <p className="text-center text-black text-sm font-medium" >
                        {message}
                    </p>
                </div>
            </div>
        </>
    );
}

export default ChangesSaved;