import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"; // Import useDispatch from Redux
import { logoutUser } from "../../../redux/slices/logout_slice"; // Import logout action
import {
	FaUserTie,
	FaBoxOpen,
	FaGlobe,
	FaEnvelope,
	FaTicketAlt,
	FaShieldAlt,
	FaFileAlt,
	FaMoneyCheckAlt,
	FaSignOutAlt,
} from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { CloseIcon } from "../../common/icons/icons";
import Logout from "./logout"; // Ensure the logout modal component is available

const routes = [
	{
		id: 1,
		label: "Dashboard",
		icon: MdOutlineDashboard,
		href: "/admin/dashboard",
	},
	{ id: 2, label: "Users", icon: FiUsers, href: "/admin/user" },
	{ id: 3, label: "Account Manager", icon: FaUserTie, href: "/admin/account" },
	{ id: 4, label: "Packages", icon: FaBoxOpen, href: "/admin/package" },
	{ id: 5, label: "Website", icon: FaGlobe, href: "/admin/website" },
	{ id: 6, label: "Messages", icon: FaEnvelope, href: "/admin/messages" },
	{ id: 7, label: "Tickets", icon: FaTicketAlt, href: "/admin/ticket" },
	{
		id: 8,
		label: "Administration",
		icon: FaShieldAlt,
		href: "/admin/administration",
	},
	{ id: 9, label: "Reports", icon: FaFileAlt, href: "/admin/report" },
	{ id: 10, label: "Payments", icon: FaMoneyCheckAlt, href: "/admin/payment" },
];

function MobileSidebar({ isOpen, toggleModal }) {
	const location = useLocation();
	const dispatch = useDispatch(); // Redux dispatch hook
	const navigate = useNavigate();
	const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false); // Manage logout modal state

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isOpen]);

	const handleCloseSidebar = () => {
		toggleModal(); // This function should toggle the state of 'isOpen'
	};

	const openLogoutModal = (e) => {
		e.preventDefault();
		setIsLogoutModalOpen(true); // Open the logout modal
	};

	const closeLogoutModal = () => {
		setIsLogoutModalOpen(false); // Close the logout modal
	};

	const handleLogout = async () => {
		try {
			await dispatch(logoutUser()).unwrap(); // Dispatch the logout action
			navigate("/"); // Redirect to the login page after successful logout
		} catch (error) {
			console.error("Logout failed:", error);
		}
	};

	const confirmLogout = () => {
		handleLogout();
		closeLogoutModal();
	};

	return (
		<>
			{isOpen && (
				<div
					className="fixed inset-0 bg-black bg-opacity-50 z-50 lg:hidden"
					onClick={handleCloseSidebar}
				>
					<div
						className="fixed inset-y-0 left-0 w-64 bg-gradient-to-t from-primary_color to-grey rounded-tr-2xl rounded-br-2xl p-4 transform transition-transform duration-300 lg:hidden overflow-y-auto max-h-screen"
						onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the sidebar
					>
						<button
							onClick={handleCloseSidebar}
							className="text-white font-bold absolute top-5 right-5"
						>
							<CloseIcon />
						</button>
						<nav className="space-y-6 pt-4">
							{routes.map((route) => (
								<Link
									key={route.id}
									to={route.href}
									className={`text-white flex items-center py-3 px-4 mb-2 rounded-lg transition-all duration-500 ${
										location.pathname === route.href
											? "text-primary_color"
											: "text-gray-400 hover:bg-gray-100 hover:text-black"
									}`}
									onClick={handleCloseSidebar} // Close sidebar on route change
								>
									<route.icon className="text-lg mr-4" />
									<span className="text-sm font-medium">{route.label}</span>
								</Link>
							))}
							{/* Logout Link */}
							<Link
								to="/"
								className="flex items-center py-3 px-4 mt-6 text-red-700 hover:bg-red-100 hover:text-red-900 rounded-lg transition-all duration-300"
								onClick={openLogoutModal} // Open the logout modal on click
							>
								<FaSignOutAlt className="text-lg mr-4" />
								<span className="text-sm font-medium">Logout</span>
							</Link>
						</nav>
					</div>
				</div>
			)}

			{/* Logout Modal */}
			{isLogoutModalOpen && (
				<Logout
					onClose={closeLogoutModal} // Close modal handler
					onConfirm={confirmLogout} // Confirm logout handler
				/>
			)}
		</>
	);
}

export default MobileSidebar;
