import { useEffect, useRef } from "react";

const Modal = ({ showModal, setShowModal, children }) => {
    const modalRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowModal]);
    return (
        <>
            <div ref={modalRef} style={{
                background: "#000000a0"
            }} className={` ${showModal ? "flex" : "hidden"} w-full h-full overflow-hidden  items-center justify-center fixed z-10 top-0 left-0 `} >
                {children}
            </div>
        </>
    );
}

export default Modal;