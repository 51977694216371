import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import { FaCheck } from "react-icons/fa";
import Button from '../../common/button/button.component';
import "./styles.css"
const ContactModal = ({ modal, setModal, setName, setEmail, setPhoneNumber, setSchoolRole, setMessage, setSelectedOption,setSchoolName }) => {
    const closeHandler = () => {
        setModal(false);
        setName("")
        setEmail("")
        setPhoneNumber("")
        setSchoolRole("")
        setSelectedOption(null)
        setMessage("")
        setSchoolName("")
    }
    return (
        <>
            <PureModal
                closeButton={""}
                className=' w-40 md:w-40 lg:w-full bg-background  rounded-md relative'
                isOpen={modal}

            >
                <div className='absolute flex items-center justify-center left-28 -top-10 bg-primary_color rounded-50% w-70px h-70px ' >

                    <FaCheck size={18} color={'white'} />

                </div>
                <div className='w-full py-10' >
                    <h3 className='text-xl text-center text-black font-bold' >Submitted !</h3>
                    <p className=' text-xs mt-3 text-center text-black font-normal' >We will contact you soon</p>
                </div>
                <div className='w-full py-1' >
                    <Button onClick={closeHandler} btnColor={'bg-primary_color'} btnTitle={'Ok'} width={'w-full'} />
                </div>
            </PureModal>
        </>
    )
}

export default ContactModal