export const setupOptions = [
  {
    name: "School Systems",
    options: [
      {
        name: "Session Manager",
        color: "#A63DF6",
        path: "/employee/dashboard/session-manager"
      },
      {
        name: "Control Panel",
        color: "#D126FC"
      },
      {
        name: "Class Management",
        color: "#D272FF"
      },
      {
        name: "Master Report",
        color: "#79D9DF"
      },
      {
        name: "Club Manager",
        color: "#AE73DC"
      },
      {
        name: "Registration",
        color: "#B861FD"
      },
      {
        name: "Sport House Manager",
        color: "#FF2876"
      },
      {
        name: "Campus Manager",
        color: "#A276FF"
      },
      {
        name: "Hostel Management",
        color: "#73D6DC"
      },
      {
        name: "Group Manager",
        color: "#53AFE3"
      },
      {
        name: "Store Center",
        color: "#6B37D6"
      }
    ]
  },
  {
    name: "Student/Parent System",
    options: [
      {
        name: "School Center",
        color: "#6833ff"
      },
      {
        name: "Guardianship Management",
        color: "#FF78E1"
      },
      {
        name: "Course Manager",
        color: "#FFBA53"
      },
      {
        name: "Result Manager",
        color: "#FF4BBC"
      },
      {
        name: "Assessment",
        color: "#FF51D9"
      },
      {
        name: "ASSIGNMENT MANAGER",
        color: "#FF7DAC"
      },
      {
        name: "Attendance Management",
        color: "#A63DF6"
      },
      {
        name: "Parent Center",
        color: "#A335FA"
      },
      {
        name: "Hostel Management",
        color: "#009AAF"
      },
      {
        name: "Star Pickup & Drop Off",
        color: "#AE73DC"
      },
      {
        name: "Store Center",
        color: "#73D6DC"
      }
    ]
  },
  {
    name: "Report System",
    options: [
      {
        name: "EVENT CALENDAR",
        color: "#FF4B4B"
      },
      {
        name: "Feedback Survey",
        color: "#54D6FF"
      },
      {
        name: "Appraisal Management",
        color: "#4BF4FF"
      },
      {
        name: "Communication Book Management",
        color: "#2DADD6"
      },
      {
        name: "Staff & Student Evaluation Form Management",
        color: "#008E97"
      },
      {
        name: "Manage Children Progress Report",
        color: "#6B0969"
      }
    ]
  },
  {
    name: "Events Rewards",
    options: [
      {
        name: "Communication Center",
        color: "#FF4B8C"
      },
      {
        name: "Login Management",
        color: "#DE5B9A",
        path: "/employee/dashboard/login-management"
      },
      {
        name: "Transcript Management",
        color: "#855BDE"
      },
      {
        name: "Suspension FAQ & SETUP",
        color: "#4BFFFF"
      },
      {
        name: "Awards and Laurels Setup",
        color: "#A276FF"
      }
    ]
  },
  {
    name: "Transaction System",
    options: [
      {
        name: "Fee Management",
        color: "#FF6DA2"
      },
      {
        name: "Ecommerce",
        color: "#5BDEB7"
      },
      {
        name: "Pocket Money",
        color: "#DD7598"
      }
    ]
  }
];
