import * as Tabs from '@radix-ui/react-tabs';
import './styles.css';
import EmergencyContact from './emergency_contact.component';
import IdentificationDetails from './identification_details.component';
import ProgressReport from './progress_report.component';
import AssignmentHistory from './assignment_history.component';
const ProfileTable = () => {
    return (
        <>
            <div className="w-full mt-8 " >
                <Tabs.Root className="TabsRoot" defaultValue="tab1">
                    <Tabs.List  className="TabsList" aria-label="Manage your account">
                        <Tabs.Trigger className="TabsTrigger" value="tab1">
                            Academic
                        </Tabs.Trigger>
                        <Tabs.Trigger className="TabsTrigger" value="tab2">
                            Non-Academic
                        </Tabs.Trigger>
                        <Tabs.Trigger className="TabsTrigger" value="tab3">
                            Attendance
                        </Tabs.Trigger>
                        <Tabs.Trigger className="TabsTrigger" value="tab4">
                            Notes
                        </Tabs.Trigger>
                    </Tabs.List>





                    <Tabs.Content className="TabsContent" value="tab1">
                        <div className='w-full flex  items-stretch justify-between gap-5' >
                            <ProgressReport />
                            <AssignmentHistory />
                            {/* <EmergencyContact />
                            <IdentificationDetails /> */}
                        </div>
                    </Tabs.Content>



                </Tabs.Root>
            </div>
        </>
    );
}

export default ProfileTable;