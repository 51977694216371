import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to handle the resend OTP functionality
export const resendOtp = createAsyncThunk(
  'auth/resendOtp',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post('https://api.mystarsonline.com/api/Signup/resend-otp', { email });
      return response.data; // assuming the response has data you need
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to resend OTP';
      return rejectWithValue({ message: errorMessage, status: error.response?.status });
    }
  }
);

const resendOtpSlice = createSlice({
  name: 'resendOtp',
  initialState: {
    loading: false,
    success: false,
    message: '',
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.success = false;
      state.message = '';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendOtp.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload.message || 'OTP sent successfully!';
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload.message || 'Failed to resend OTP';
      });
  },
});

// Export actions and reducer
export const { resetState } = resendOtpSlice.actions;
export default resendOtpSlice.reducer;
