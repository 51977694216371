import { IoIosArrowRoundForward } from "react-icons/io"
import Button from "../../../components/common/button/button.component"
import { ChangeIcon } from "../../../components/common/icons/icons"
import SessionManagerTable from "../../../components/pages/superAdmin/session_manager/session_tab_manager.component"
import TitleBar from "../../../components/pages/superAdmin/title_bar.component"
import { useNavigate } from "react-router-dom"
import Modal from "../../../components/common/modal/modal.component"
import CreateSession from "../../../components/pages/superAdmin/session_manager/create_session.component"
import { useState } from "react"
import { useAppSelector } from "../../../redux/hooks.ts"

const SessionManager = () => {
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()
    const sessions = useAppSelector(({ session }) => session.sessions)
    const tabOptions = [
        {
            option: "S/N"
        },
        {
            option: "Session Title"
        },
        {
            option: "Start Date"
        },
        {
            option: "End Date"
        },
        {
            option: "Status"
        },
        {
            option: "Action"
        },
    ];
    const Actions = [
        {
            name: "Create Session",
            color: "#A63DF6",
            operation: () => {
                setShowModal(true)
            }
        },
        {
            name: "Generate Report for session/term",
            color: "#D126FC"
        },
        {
            name: "Assign session & term",
            color: "#D272FF"
        },
    ]
    return (
        <>
            <div className={"flex flex-row items-baseline justify-between p-4 gap-5 "} >
                <div className="flex flex-col  w-3/5 " >
                    <TitleBar title={'Session Manager'} />
                    {sessions.length > 0 ? <SessionManagerTable type={"session"} tabOptions={tabOptions} options={sessions} /> : <div className="w-full flex items-center justify-center mt-8" >
                        <h3 className="text-lg text-grey font-bold" >No sessions are available</h3>
                    </div>}
                </div>
                <div className="flex flex-col  w-2/5  ">
                    <Button onClick={() => {

                        navigate("/employee/dashboard/term-manager")
                    }} btnTitle={"View Terms"} className={' w-64 bg-dark_pink p-2 rounded-md text-white flex items-center justify-between gap-2'} icon={<ChangeIcon color={'white'} size={30} />} />

                    <div className="w-full mt-5" >
                        {Actions.map(({ name, color, operation }, index) => (
                            <div
                                onClick={operation}
                                key={index}
                                style={{ backgroundColor: color }}
                                className={` w-full md:w-2/5 lg:w-64 rounded-md p-5 mb-4 relative cursor-pointer`}
                            >
                                <p className="text-white text-sm uppercase">{name}</p>
                                <div className="w-full flex justify-end items-center mt-12 bottom-0 relative">
                                    <IoIosArrowRoundForward
                                        color="white"
                                        size={30}
                                        cursor={"pointer"}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            <Modal showModal={showModal} setShowModal={setShowModal} >
                <CreateSession setShowModal={setShowModal} />
            </Modal>
        </>
    )
}

export default SessionManager