import Faq from "./faq.component";

const FaqSection = () => {
    return (
        <>
            <div className="w-full mt-14 px-6 md:px-8 md:mt-24 lg:px-20 lg:mt-24" >
                <div className="w-full mb-6 "  >
                    <h3 className="text-left text-lg font-bold md:text-2xl lg:text-4xl 2xl:text-5xl" >
                        FAQS
                    </h3>
                </div>
                {[0].map(()=>(
                    <Faq/>
                ))}
            </div>
        </>
    );
}

export default FaqSection;