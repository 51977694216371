import Features from "../../../components/pages/home/features.component";
import HomeHero from "../../../components/pages/home/hero.component";
import SchoolManagement from "../../../components/pages/home/school_management.component";
import Solution from "../../../components/pages/home/solution.component";
import Testimonial from "../../../components/pages/home/testimonial.component";

const Home = () => {
    return ( 
        <>
        <div className=" w-full h-full " >
        <HomeHero/>
        <Solution/>
        <SchoolManagement/>
        <Features/>
        <Testimonial/>
        </div>
        </>
     );
}
 
export default Home;