import logo from "../../../assets/images/logo.png";
import { siteIcons } from "../../../assets/images/images";
const Logo = ({ width, height, className }) => {
	return (
		<img
			src={siteIcons.logo}
			alt="logo"
			className={className ? className : ` w-20 sm:w-32  h-auto `}
		/>
	);
	// return <img src={logo} alt="logo" className={className ? className:` object-contain w-34 h-10 2 2xl:h-20`} />;
};

export default Logo;
