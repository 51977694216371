import VerifyForgetPassword from "../../../components/pages/verfiyforgetpassword/verify_forgetpassword";
const OtpForgetPassword = () => {
   

    const options = [
        { name: "Employee", route: "/employee" },
        { name: "Student", route: "/student" },
        { name: "Parent", route: "/employee" }
    ];

    return (
        <div className="w-full h-full flex justify-center px-14 " >
        <div className=" " >
          <VerifyForgetPassword/>
        </div>  
      </div>
    );
}

export default OtpForgetPassword;
