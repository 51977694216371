import { useState } from "react";
import ChangesSaved from "./changes_saved.component";
import Button from "../../../common/button/button.component";
import { CloseIcon } from "../../../common/icons/icons";

const UpdateProfile = ({ setShowModal }) => {
    const [saveChanges, setSaveChanges] = useState(false)
    return (
        <div className="bg-white rounded-md py-3 lg:w-3/5 relative  shadow-md " >
            <div className="p-2 flex items-center justify-center absolute rounded-50% top-4 right-10 bg-primary_color" >
                <CloseIcon cursor={'pointer'} color={'white'} onClick={() => {
                    setShowModal(false)
                    setSaveChanges(false)
                }} />
            </div>
            {saveChanges ? <ChangesSaved message={"Changes made have been succesfully made and updated"} /> : <>
                <div className="w-full my-7" >
                    <h3 className="text-xl font-bold text-primary_color text-center uppercase" >Update Profile</h3>
                    <p className="text-lg font-bold text-black text-center" >Fill in the field below to edit user’s credentials</p>
                </div>
                <div className="w-full px-9 mt-5 flex items-center justify-between flex-wrap gap-1 " >
                    <div style={{ width: "45%" }} >
                        <label className=" text-sm font-semibold text-black" >Full name</label>
                        <input value={"Mary Employee"} style={{ border: '1px solid #e3e3e3' }} className="w-full rounded-md p-3  outline-none " />
                    </div>
                    <div style={{ width: "45%" }} >
                        <label className=" text-sm font-semibold text-black" >Email</label>
                        <input value={"Maths teacher"} style={{ border: '1px solid #ededed' }} className="w-full rounded-md p-3  outline-none " />
                    </div>
                    <div style={{ width: "45%" }} className="mt-7" >
                        <label className=" text-sm font-semibold text-black" >Phone number</label>
                        <input value={"mary@gmail.com"} style={{ border: '1px solid #ededed' }} className="w-full rounded-md p-3  outline-none " />
                    </div>
                </div>
                <div className="mt-40 mb-5 px-9 flex items-center justify-end gap-2 " >
                    <Button onClick={() => { setSaveChanges(true) }} btnTitle={'Save changes'} className={'w-28 p-2 bg-primary_color rounded text-sm text-white text-center cursor-pointer '} />
                    <Button btnTitle={'Cancel'} btnColor={'bg-white'} className={'w-28 p-2 bg-white rounded border cursor-pointer border-primary_color text-sm text-center '} onClick={() => {
                        setShowModal(false)
                    }} />
                </div></>}
        </div>
    );
}

export default UpdateProfile;