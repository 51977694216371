import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HorizontalMenu } from '../../../../common/icons/icons';
import { activateTenantThunk, deactivateTenantThunk } from '../../../../../redux/slices/tenant_slice';

const TableInfoActive = ({ serialNo, id, name, status, packages, school, joined }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const menuRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleActivate = () => {
    console.log('Activating Tenant ID:', id); // Log tenant ID here
    dispatch(activateTenantThunk(id));
    setToggleMenu(false);
  };

  const handleDeactivate = () => {
    console.log('Deactivating Tenant ID:', id); // Log tenant ID here
    dispatch(deactivateTenantThunk(id));
    setToggleMenu(false);
  };

  return (
    <>
      <div className="w-[700px] md:w-full mt-5 rounded-xl bg-gray-100 flex p-4 items-center justify-between relative">
        <div className="w-[5%] flex justify-start">
          <p className="text-sm 2xl:text-lg font-medium">{serialNo}</p>
        </div>
        <div className="w-[21%] flex px-5">
          <p className="text-sm 2xl:text-lg font-medium">{name}</p>
        </div>
        <div className="w-[16%] flex px-5">
          <p className={`text-sm 2xl:text-lg font-medium ${status === 'Active' ? 'text-green-500' : 'text-ash-500'}`}>{status}</p>
        </div>
        <div className="w-[16%] flex px-6 2xl:px-8">
          <p className="text-sm  2xl:text-lg font-medium">{packages}</p>
        </div>
        <div className="w-[16%] flex px-5">
          <p className="text-sm 2xl:text-lg font-medium ">{school}</p>
        </div>
        <div className="w-[16%] flex px-5">
          <p className="text-sm  2xl:text-lg font-medium">{joined}</p>
        </div>
        <div className="w-[8%] flex justify-center items-center 2xl:pr-8">
          <HorizontalMenu cursor="pointer" onClick={() => setToggleMenu(!toggleMenu)} />
        </div>

        <div ref={menuRef} className={`${toggleMenu ? 'block' : 'hidden'} bg-white rounded-2xl p-3 absolute z-10 shadow-lg shadow-gray-400 right-14 `}>
          <p onClick={handleActivate} className="border-t border-b py-2 font-bold text-xs mb-2 cursor-pointer text-black">Activate</p>
          <p onClick={handleDeactivate} className=" border-b py-2 font-bold text-xs mb-2 cursor-pointer text-black">Deactivate</p>
        </div>
      </div>
    </>
  );
};

export default TableInfoActive;
