import { Link, useNavigate } from "react-router-dom";
import Button from "../../common/button/button.component";
import { MailIcon } from "../../common/icons/icons";

const StartForm = () => {
    const navigate = useNavigate();
    const navigationHandler = () => {
        navigate("/signup/employee/details/step-1")
    }
    return (
        <>
            <div className=" top-0 left-0 w-80 md:w-3/5 md:left-0 md:top-0 lg:w-300px lg:z-10 lg:left-24 self-center relative bg-white bg-opacity-50 rounded-xl py-5 " >
                <div className="w-full px-6" >
                    <h4 className=" text-center text-xl  md:text-xl lg:text-2xl text-white">Hello!</h4>
                    <p className=" text-base text-center md:text-lg lg:text-lg text-white" >Please enter your email address</p>
                </div>
                <div className="w-full flex items-center justify-center flex-col px-5 mt-6 mb-10 " >
                    <div className="w-9/12 flex items-center justify-between border-white border rounded-md p-2 mt-6 " >
                        <div className=" w-1/6 " >
                            <MailIcon color={'white'} />
                        </div>
                        <div className=" w-11/12 " >
                            <input className="w-full outline-none text-white placeholder-white " style={{ background: "none" }} placeholder={"Email"} />
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-center mt-5 " >
                        <Button onClick={navigationHandler} className=" w-9/12 text-base bg-white cursor-pointer rounded-md p-3 flex items-center justify-center mb-5 text-primary_color md:text-lg lg:text-lg  font-semibold hover:bg-primary_color hover:text-white" btnTitle={'Proceed'} />
                    </div>
                </div>

                <div className="w-full flex items-center justify-center" >
                    <Link className="text-center text-sm md:text-base lg:text-base text-white mb-5 cursor-pointer" to={'/login'} >Alread have an account? Sign In!</Link>
                </div>
            </div>
        </>
    );
}

export default StartForm;