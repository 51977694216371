import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HorizontalMenu } from "../../../common/icons/icons";
import EditDetails from "../user/edit/edit_details"; // Import the EditDetails component
// Import the EditDetails component
import axios from "axios";
// import DeleteUser from "../usermodal/delete";
import DeleteUser from "../user/usermodal/delete";
const TableInfoUser = ({
	serialNo,
	id,
	name,
	middleName,
	verified,
	packages,
	school,
	joined,
}) => {
	const [toggleMenu, setToggleMenu] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State to control edit modal visibility
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State to control delete modal visibility
	const menuRef = useRef(null);
	const dispatch = useDispatch();
	const token = useSelector((state) => state.login.logintoken); // Get token from Redux store

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setToggleMenu(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// Disable scrolling when modals are open
	useEffect(() => {
		if (isEditModalOpen || isDeleteModalOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isEditModalOpen, isDeleteModalOpen]);

	// This is where we store the tenant's data
	const tenantData = {
		firstName: name.split(" ")[0], // assuming the first name is before the first space
		lastName: name.split(" ")[1], // assuming the last name is after the first space
		middleName: middleName, // this can be added based on how your data is structured
		schoolName: school,
	};

	const handleEditClick = () => {
		setIsEditModalOpen(true);
		setToggleMenu(false); // Close the menu when opening the modal
	};

	const handleCloseEditModal = () => {
		setIsEditModalOpen(false);
	};

	const handleDeleteClick = () => {
		setIsDeleteModalOpen(true); // Show the delete confirmation modal
		setToggleMenu(false); // Close the menu when opening the modal
	};

	const handleCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleConfirmDelete = async () => {
		try {
			const response = await axios.delete(
				`https://api.mystarsonline.com/api/Signup/delete-tenant/${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log("Deleted Tenant ID:", id);
		} catch (error) {
			console.error("Failed to delete tenant:", error);
		} finally {
			setIsDeleteModalOpen(false); // Close the delete modal after the operation
		}
	};

	return (
		<>
			<div className="w-[700px] md:w-full mt-5 rounded-xl bg-gray-100 flex p-4 items-center justify-between relative">
				<div className="w-[5%] flex justify-start">
					<p className="text-sm 2xl:text-lg font-medium">{serialNo} </p>
				</div>
				<div className="w-[21%] flex ">
					<p className="text-sm 2xl:text-lg font-medium">{name}</p>
				</div>
				<div className="w-[16%] flex ">
					<p
						className={`text-sm 2xl:text-lg font-medium ${
							verified ? "text-green" : "text-red-500"
						}`}
					>
						{verified ? "True" : "False"}
					</p>
				</div>
				<div className="w-[16%] flex ">
					<p className="text-sm 2xl:text-lg font-medium">{packages}</p>
				</div>
				<div className="w-[16%] flex  ">
					<p className="text-sm 2xl:text-lg font-medium ">{school}</p>
				</div>
				<div className="w-[16%] flex ">
					<p className="text-sm 2xl:text-lg font-medium">{joined}</p>
				</div>
				<div className="w-[8%] flex justify-center items-center 2xl:pr-8">
					<HorizontalMenu
						cursor="pointer"
						onClick={() => setToggleMenu(!toggleMenu)}
					/>
				</div>

				<div
					ref={menuRef}
					className={`${
						toggleMenu ? "block" : "hidden"
					} bg-white rounded-2xl p-3 absolute z-10 shadow-lg shadow-gray-400 right-14`}
				>
					<p
						onClick={handleEditClick}
						className="font-bold text-xs mb-2 cursor-pointer text-black"
					>
						Edit
					</p>
					<p
						onClick={handleDeleteClick}
						className="font-bold text-xs cursor-pointer text-black"
					>
						Delete
					</p>
				</div>
			</div>

			{isEditModalOpen && (
				<div
					style={{
						position: "fixed",
						inset: "0",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 50,
					}}
				>
					<EditDetails
						tenantId={id} // Pass the tenantId to EditDetails
						tenantData={tenantData} // Pass the tenantData directly to EditDetails
						onClose={handleCloseEditModal}
						onSave={() => setIsEditModalOpen(false)}
					/>
				</div>
			)}

			{isDeleteModalOpen && (
				<DeleteUser
					onClose={handleCloseDeleteModal}
					onConfirm={handleConfirmDelete}
				/>
			)}
		</>
	);
};

export default TableInfoUser;
