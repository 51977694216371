import { IoIosArrowRoundForward } from "react-icons/io";
import { DropDownIcon } from "../../../components/common/icons/icons";
import { setupOptions } from "./setupOptions";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const SUPERADMINSETUP = () => {
    const [toggleOptions, setToggleOptions] = useState(Array(setupOptions.length).fill(false));
    const navigate = useNavigate()
    const toggleHandler = useMemo(() => (index) => {
        setToggleOptions((prevToggleOptions) => {
            const newToggleOptions = [...prevToggleOptions];
            newToggleOptions[index] = !newToggleOptions[index];
            return newToggleOptions;
        })
    }, []);

    return (
        <>
            <div className="w-fulll p-2 md:p-6 lg:p-6">
                <div className="w-full">
                    <h1 className="text-xl font-bold md:text-2xl lg:text-3xl">SPE</h1>
                </div>
                <div className="w-full mt-4">
                    {setupOptions.map(({ name, options }, index) => (
                        <div className="w-full cursor-pointer" key={index} >
                            <div className="w-full rounded-2xl bg-primary_color p-3 mb-6 relative flex items-center justify-between" onClick={() => toggleHandler(index)}>
                                <p className="text-white text-lg md:left-6 relative">{name}</p>
                                <DropDownIcon
                                    // onClick={() => toggleHandler(index)}
                                    color={"white"}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div
                                className={`${toggleOptions[index] ? "flex" : "hidden"
                                    } w-full items-center gap-1 justify-start md:gap-9 flex-wrap mb-5`}
                            >
                                {options.map(({ name, color, path }, subIndex) => (
                                    <div
                                        onClick={() => {
                                            navigate(path)
                                        }}
                                        key={subIndex}
                                        style={{ backgroundColor: color }}
                                        className={` basis-full md:basis-2/5 lg:basis-1/4 rounded-2xl p-5 mb-4 relative`}
                                    >
                                        <p className="text-white text-sm uppercase ">{name}</p>
                                        <div className="w-full flex justify-end items-center mt-12 bottom-0 relative">
                                            <IoIosArrowRoundForward
                                                color="white"
                                                size={30}
                                                cursor={"pointer"}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default SUPERADMINSETUP;
