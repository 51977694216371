import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CreateDetails = ({ onClose, onSave }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Toggle state for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle state for confirm password visibility

  const token = useSelector((state) => state.login.logintoken);

  const handleSubmit = async (e) => {
    e.preventDefault();

   // Validate that all fields are filled
  if (!email || !password || !confirmPassword || !schoolName || !firstName || !lastName || !phoneNumber) {
    toast.error('Please fill in all the required fields!');
    return;
  }

  // Password confirmation validation
  if (password !== confirmPassword) {
    toast.error('Passwords do not match!');
    return;
  }

    const newTenantDetails = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      schoolName: schoolName,
      firstname: firstName,
      lastname: lastName,
      phoneNumber: phoneNumber,
      middleName: middleName
    };
    
    try {
      const response = await axios.post(
        'https://api.mystarsonline.com/api/Signup/superadmin/create-tenant',
        newTenantDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      console.log('Response:', response.data);

      if (response.data && response.data.status) {
        toast.success(response.data.message || 'Tenant created successfully!');

        setTimeout(() => {
          onClose(); // Close the modal
          window.location.reload(); // Refresh the page after the modal is closed
        }, 3000); // 3 seconds delay for the toast to show
      } else {
        toast.error(response.data.message || 'Failed to create tenant.');

        setTimeout(() => {
          onClose(); // Close the modal
          window.location.reload(); // Refresh the page after the modal is closed
        }, 3000); // 3 seconds delay for error toast
      }

      if (onSave) {
        onSave(response.data);
      }
    } catch (error) {
      console.error('Failed to create tenant:', error);
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred while creating the tenant.'
      );

      setTimeout(() => {
        onClose(); // Close the modal
        window.location.reload(); // Refresh the page after the modal is closed
      }, 3000); // 3 seconds delay for error toast
    }
  };

  return (
    <>
      <div className="h-[50vh] mt-[50vh] overflow-y-auto bg-white rounded-2xl p-6 max-w-xl mx-auto md:shadow-md md:h-auto md:mt-0">
        <div className="flex justify-end">
          <span onClick={onClose} className="bg-primary_color rounded-full p-2 cursor-pointer inline-flex items-center justify-center">
            <AiOutlineClose className="text-white"  />
          </span>
        </div>
        <div className="mb-3">
          <p className="text-center text-lg 2xl:text-3xl font-bold text-primary_color">Create Tenant</p>
          <p className="text-center text-sm 2xl:text-xl 2xl:pt-5 font-bold text-gray-700">Fill in the details below to create a new tenant.</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
            <div>
              <label className="block text-sm 2xl:text-xl 2xl:mb-2 font-semibold text-black mb-1">Email</label>
              <div className='border border-black rounded-xl'>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2" placeholder="john@example.com" required />
              </div>
            </div>
            <div>
              <label className="block text-sm 2xl:text-xl 2xl:mb-2 font-semibold text-black mb-1">First Name</label>
              <div className='border border-black rounded-xl'>
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}
                  className="w-full p-2" placeholder="John" required />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
            <div>
              <label className="block text-sm 2xl:text-xl 2xl:mb-2 font-semibold text-black mb-1">Password</label>
              <div className='relative border border-black rounded-xl'>
                <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-2" placeholder="Password" required />
                <span onClick={() => setShowPassword(!showPassword)} className="absolute top-3 right-4 cursor-pointer">
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
              <p className="text-xs 2xl:text-sm text-gray-600 mt-1">
                Password must contain at least one capital letter, one number, one special character, and be 8 characters long.
              </p>
            </div>
            <div>
              <label className="block text-sm 2xl:text-xl 2xl:mb-2 font-semibold text-black mb-1">Confirm Password</label>
              <div className='relative border border-black rounded-xl'>
                <input type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full p-2" placeholder="Confirm Password" required />
                <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="absolute top-3 right-4 cursor-pointer">
                  {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
            <div>
              <label className="block text-sm 2xl:text-xl 2xl:mb-2 font-semibold text-black mb-1">Middle Name</label>
              <div className='border border-black rounded-xl'>
                <input type="text" value={middleName} onChange={(e) => setMiddleName(e.target.value)}
                  className="w-full p-2" placeholder="Middle Name" />
              </div>
            </div>
            <div>
              <label className="block text-sm 2xl:text-xl 2xl:mb-2 font-semibold text-black mb-1">Last Name</label>
              <div className='border border-black rounded-xl'>
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}
                  className="w-full p-2" placeholder="Doe" required />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
           
            <div>
              <label className="block text-sm 2xl:text-xl 2xl:mb-2 font-semibold text-black mb-1">Phone Number</label>
              <div className='border border-black rounded-xl'>
                <PhoneInput
                  country={'ng'}
                  value={phoneNumber}
                  onChange={phone => setPhoneNumber(phone)}
                  inputClass="w-full "
                  placeholder="Enter phone number"
                  required
                  dropdownStyle={{
                    bottom: '100%',
                    top: 'auto',
                    left: '50%', // Move dropdown more to the left by decreasing this value
                    right: 'auto'
                  }}

                />
              </div>
            </div>
            <div>
              <label className="block text-sm 2xl:text-xl 2xl:mb-2 font-semibold text-black mb-1">School Name</label>
              <div className='border border-black rounded-xl'>
                <textarea type="text" value={schoolName} onChange={(e) => setSchoolName(e.target.value)}
                  className="w-full p-2" placeholder="School Name" required  rows="1" />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3 pt-3">
            <button type="button" className="cursor-pointer bg-primary_color text-white px-6 py-1 rounded-xl" onClick={onClose}>Cancel</button>
            <div className='cursor-pointer border border-primary_color px-6 py-1 rounded-xl inline-block'>
              <button type="submit" className="text-primary_color w-full h-full">Create</button>
            </div>
          </div>
        </form>
        <ToastContainer position='top-center' autoClose={2000} theme='light' pauseOnHover={false} /> 
      </div>
    </>
  );
};

export default CreateDetails;
