import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PlanComparisonAccordion from './pricing_accordion'; // Assuming this is another component

const PlanComparison = () => {
    const [activeTab, setActiveTab] = useState('monthly');
    const [monthlyPlans, setMonthlyPlans] = useState([]);
    const [annualPlans, setAnnualPlans] = useState([]);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get('https://api.mystarsonline.com/api/Subscription/get-WebPlans');
                if (response.data.code === 200) {
                    const allPlans = response.data.data;
                    const monthly = allPlans.map(plan => plan.monthlyPlan).filter(Boolean).slice(-3);
                    const annually = allPlans.map(plan => plan.annualPlan).filter(Boolean).slice(-3);
                    setMonthlyPlans(monthly);
                    setAnnualPlans(annually);
                }
            } catch (error) {
                console.error("Error fetching plans:", error);
            }
        };
        fetchPlans();
    }, []);

    const plansToDisplay = activeTab === 'monthly' ? monthlyPlans : annualPlans;
    const formatPrice = (price) => (
        <>
          <sup className="text-sm 2xl:text-xl">₦</sup>{(price / 100).toLocaleString()}
        </>
    );

    return (
        <div className="w-full  ">
            {/* First div without background */}
            <div className="flex justify-center items-center p-4">
                <div className="border-4 rounded-xl border-light_grey overflow-hidden flex">
                    <button
                        className={`font-bold cursor-pointer text-sm md:text-lg 2xl:text-2xl px-3 py-2 ${activeTab === 'monthly' ? 'bg-purple-600 text-white' : 'bg-faint_grey text-black'}`}
                        onClick={() => setActiveTab('monthly')}
                    >
                        Monthly Plans
                    </button>
                    <button
                        className={`font-bold cursor-pointer text-sm md:text-lg 2xl:text-2xl px-3 py-2 ${activeTab === 'annually' ? 'bg-purple-600 text-white' : 'bg-faint_grey text-black'}`}
                        onClick={() => setActiveTab('annually')}
                    >
                        Yearly Plans
                    </button>
                </div>
            </div>

            {/* Wrapper for background starting from the second div */}
            <div className="bg-background p-4 md:p-8 lg:p-24">
                {/* Second div with background */}
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 text-center 2xl:pt-8">
                    <div className="text-2xl 2xl:text-3xl font-semibold mb-6 text-center">Plan Comparison</div>
                    {plansToDisplay.map((plan, index) => (
                        <div key={index} className="bg-gray-100 p-4 shadow rounded-lg">
                            <h3 className="font-bold text-lg 2xl:text-3xl" style={{ color: plan.colorCode }}>{plan.name}</h3>
                            <p className="text-xl font-bold my-2 2xl:text-3xl">
                                {formatPrice(plan.amount)}
                            </p>
                            <button className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full cursor-pointer 2xl:px-6 2xl:py-3 2xl:text-xl">
                                Start Free Trial
                            </button>
                        </div>
                    ))}
                </div>

                {/* Third div with background */}
                <div className="pt-20">
                    <PlanComparisonAccordion />
                </div>
            </div>
        </div>
    );
};

export default PlanComparison;
