import { Link } from "react-router-dom";
import Button from "../../common/button/button.component";
import { EyeOpenIcon, LockIcon, MailIcon } from "../../common/icons/icons";
import { useNavigate } from "react-router-dom"
const LoginForm = ({ placeholder1, placeholder2, errors, onSubmit, message, goToRoute, handleSubmit, register, value1, value2 }) => {
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className=" w-full relative bg-white bg-opacity-50 rounded-xl py-5 " >
                <div className="w-full px-6" >
                    <h4 className=" text-center text-xl  md:text-xl lg:text-2xl text-white" >Welcome sa!</h4>
                    <p className=" text-base text-center md:text-lg lg:text-lg text-white" >{message}</p>
                </div>
                <div className="w-full flex items-center flex-col  mt-6 mb-9  " >
                    <div className="w-9/12" >
                        <div className="w-full flex items-center justify-between border-white border rounded-md p-2 mt-6 " >
                            <div className=" w-1/6 " >
                                <MailIcon color={'white'} />
                            </div>
                            <div className=" w-11/12 " >
                                <input {...register(value1)} className="w-full outline-none text-white placeholder-white text-sm " style={{ background: "none" }} placeholder={placeholder1} />

                            </div>
                        </div>
                        <p className=" text-left text-xs md:text-sm lg:text-xs text-dark_pink top-2 relative">{errors.email?.message}</p>
                    </div>
                    <div className="w-9/12" >
                        <div className=" w-full flex items-center justify-between border-white  border rounded-md p-2 mt-6 " >
                            <div className=" w-1/6 " >
                                <LockIcon color={'white'} />
                            </div>
                            <div className=" w-1/2 " >
                                <input {...register(value2)} className="w-full outline-none text-white placeholder-white text-sm " style={{ background: "none" }} placeholder={placeholder2} />
                            </div>
                            <div className=" w-4/12 flex items-center justify-end " >
                                <EyeOpenIcon color={'white'} />
                            </div>
                        </div>
                        <p className=" text-left text-xs md:text-sm lg:text-xs text-dark_pink top-2 relative ">{errors.password?.message}</p>
                    </div>
                </div>

                <div className="w-full flex items-center justify-center" >
                    <Button className=" w-9/12 text-base bg-white cursor-pointer rounded-md p-3 flex items-center justify-center mb-3 text-primary_color md:text-lg lg:text-lg  font-semibold hover:bg-primary_color hover:text-white" btnTitle={'Proceed'} />
                </div>
                {/* <div className=" w-9/12  m-auto " >
                    <p className=" text-left text-xs md:text-base lg:text-xs text-dark_pink mb-2 " >Invalid email or Password . Try Again !</p>
                </div> */}
                <div className=" flex flex-row items-center justify-between w-9/12  m-auto " >
                    <p className=" text-sm md:text-base lg:text-sm text-white mb-5 cursor-pointer" >Remember me </p>
                    <p className=" text-sm md:text-base lg:text-sm text-white mb-5 cursor-pointer">Forgot Password</p>
                </div>
                <div className="w-full flex items-center justify-center" >
                    <Link className="text-center text-sm md:text-base lg:text-sm text-white mb-5 cursor-pointer" to={'/signup'} >Don’t have an account? Sign up!</Link>
                </div>
            </form>
        </>
    );
}

export default LoginForm;