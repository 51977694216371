import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Confirm from './testimonialmodal/confirm';  
import VerifiedFeatures from './testimonialtable/verified_features';

const TestimonialTab = () => {
  const [rating, setRating] = useState('');
  const [image, setImage] = useState(null);
  const [review, setReview] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [roleInSchool, setRoleInSchool] = useState('');
  const [name, setName] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);  // State to control modal visibility
  
  // Get the token from Redux store
  const token = useSelector(state => state.login.logintoken);

  const handleRatingChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value <= 5 && value >= 0) {
      setRating(value);
    } else {
      toast.error('Rating must be between 0 and 5');
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      resizeImage(file, 56, 56, (resizedImage) => {
        setImage(resizedImage);
      });
    }
  };

  const resizeImage = (file, width, height, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(callback, 'image/jpeg');
      };
    };
  };

  const handleSubmit = () => {
    // Show confirmation modal before submission
    setShowConfirm(true);
  };

  const handleConfirmSubmit = async () => {
    if (!review || rating === '' || !image || !schoolName || !roleInSchool || !name) {
      toast.error("All fields are required");
      return;
    }

    // Prepare the form data
    const formData = new FormData();
    formData.append('Review', review);
    formData.append('Rating', rating);
    formData.append('Picture', image, 'resized_image.jpg');
    formData.append('SchoolName', schoolName);
    formData.append('RoleInSchool', roleInSchool);
    formData.append('Name', name);

    try {
      const response = await axios.post(
        'https://api.mystarsonline.com/api/Testimonial/create',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`, // Authorization header
            'Content-Type': 'multipart/form-data' // Ensure multipart/form-data
          },
        }
      );

      if (response.status === 200) {
        toast.success('Testimonial submitted successfully');
        handleReset(); // Clear fields after success
      } else {
        toast.error('Failed to submit testimonial');
      }
    } catch (error) {
      console.error('Error submitting testimonial:', error);
      toast.error('Error submitting testimonial');
    }

    // Close the modal after submission
    setShowConfirm(false);
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);  // Close the confirmation modal
  };

  const handleReset = () => {
    setReview('');
    setRating('');
    setImage(null);
    setSchoolName('');
    setRoleInSchool('');
    setName('');
  };

  return (
    <div className="p-6 ">
      <ToastContainer position="top-center" autoClose={3000} />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-x-[20px]">
        <div>
          <label className="block text-sm font-semibold text-black mb-1">Review</label>
          <div className="border border-gray-300 rounded-lg">
            <textarea
              type="text"
              value={review}
              onChange={(e) => setReview(e.target.value)}
              className="w-full p-2"
              placeholder="Write your review here"
              rows='1'
              style={{ overflow: 'hidden' }}
              onInput={(e) => {
                e.target.style.height = 'auto'; // Reset the height
                e.target.style.height = `${e.target.scrollHeight}px`; // Set it to scrollHeight
              }}
            />
          </div>
        </div>
        
        <div className="flex flex-col items-center">
          <label className="block text-sm font-semibold text-black mb-1">Header Logo</label>
          <div className="border border-gray-300 rounded-xl p-8 flex justify-center items-center cursor-pointer">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
              id="imageUpload"
            />
            <label htmlFor="imageUpload" className="cursor-pointer">
              {image ? (
                <img src={URL.createObjectURL(image)} alt="Uploaded" className="w-10 h-10 rounded-full object-cover" />
              ) : (
                <div className="w-10 h-10 flex items-center justify-center">
                  <span>Upload</span>
                </div>
              )}
            </label>
          </div>
        </div>
        
        <div>
          <label className="block text-sm font-semibold text-black mb-1">Rating (Out of 5)</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="number"
              value={rating}
              onChange={handleRatingChange}
              className="w-full p-2"
              placeholder="Rate out of 5"
              max="5"
              min="0"
              step="0.1"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-semibold text-black mb-1">School Name</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
              className="w-full p-2"
              placeholder="Enter School Name"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-semibold text-black mb-1">Role in School</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              value={roleInSchool}
              onChange={(e) => setRoleInSchool(e.target.value)}
              className="w-full p-2"
              placeholder="Enter Your Role in School"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-semibold text-black mb-1">Name</label>
          <div className="border border-gray-300 rounded-lg">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2"
              placeholder="Enter Your Name"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6 space-x-3">
        <button
          type="button"
          className="bg-primary_color text-white px-3 cursor-pointer py-1 rounded-xl"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <div className="border border-primary_color px-3 cursor-pointer py-1 rounded-xl inline-block">
          <button 
            type="button" 
            className="text-primary_color w-full h-full"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      </div>

      <div className="">
        <h2 className="text-lg text-grey border-b">Title List</h2>
        <VerifiedFeatures />
      </div>

      {/* Confirm Modal */}
      {showConfirm && (
        <Confirm 
          onClose={handleCloseConfirm} 
          onConfirm={handleConfirmSubmit} 
        />
      )}
    </div>
  );
};

export default TestimonialTab;
