import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import session_slice from "./slices/session_slice.ts";
import term_slice from "./slices/term_slice.ts";
import login_slice from "./slices/login_slice";
import tenant_slice from "./slices/tenant_slice";
import register_slice from "./slices/register_slice";
import forgetpassword_slice from "./slices/forgetpassword_slice.js";
import otpverification_slice from "./slices/otpverification_slice.js";
import logout_slice from "./slices/logout_slice.js";
import delete_slice from "./slices/delete_slice.js";
import resendotp_slice  from "./slices/resendotp_slice.js";
import plan_slice from "./slices/plan_slice.js";
import globalSearchReducer from './slices/globalsearch_slice.js';
import savedplan_slice from "./slices/savedplan_slice.js";
import resubscribe_slice from "./slices/resubscribe_slice.js";

// Combine your reducers into a rootReducer
const rootReducer = combineReducers({
  session: session_slice,
  term: term_slice,
  login: login_slice,
  logout:logout_slice,
  tenant: tenant_slice,
  register: register_slice,
  forgetPassword:forgetpassword_slice,
  otpVerification: otpverification_slice,
  delete: delete_slice,
  resendOtp: resendotp_slice,
  plan:     plan_slice,
  globalSearch: globalSearchReducer, 
   savedplan:     savedplan_slice,
   resubscribe: resubscribe_slice
});

// Configure persist with a key and storage
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session", "login", "forgetPassword", "otpVerification", "register", "savedplan", "resubscribe" ], // Specify which slices you want to persist
};

// Create a persisted reducer using persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store using configureStore
export const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor to handle the persistence logic
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
