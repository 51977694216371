import * as Tabs from "@radix-ui/react-tabs";
import VerifiedTicket from "../../../components/pages/adminDashboard/tickets/verified_tab";
import { useState } from "react";
import Paginator from "../../../components/pages/superAdmin/activity_log/paginator.component";
import { AiOutlineSearch } from "react-icons/ai";
import MessageList from "../../../components/pages/adminDashboard/messages/messagelist";

const Message = () => {
	const [activeTab, setActiveTab] = useState("tab1");
	const [searchQuery, setSearchQuery] = useState("");

	const activeStyle = {
		color: "#9835D6",
		borderBottom: "2px solid #9835D6",
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	return (
			<div className="w-full mt-8 bg-white  p-4 rounded-xl"> 
			<Tabs.Root
				className="TabsRoot"
				defaultValue="tab1"
				onValueChange={(value) => setActiveTab(value)}
			>
				<div className="flex justify-between items-center">
					<Tabs.List
						className="space-x-5 flex flex-row text-sm border-b-2 w-5/6 md:text-lg font-bold"
						aria-label="Manage"
					>
						<Tabs.Trigger
							style={activeTab === "tab1" ? activeStyle : {}}
							className="hover:text-primary_color cursor-pointer text-grey"
							value="tab1"
						>
							Messages
						</Tabs.Trigger>
					</Tabs.List>
				</div>
				<Tabs.Content  value="tab1">
					<MessageList />
				</Tabs.Content>
			</Tabs.Root>
		</div>
	);
};

export default Message;
