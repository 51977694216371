import React from 'react'

const PricingHeading = () => {
  return (
    <div className="mt-3 p-4 md:p-8 lg:p-24 text-center ">
    <div className="flex flex-col ">
    <h1 className="text-4xl 2xl:text-6xl font-bold capitalize">
      plan comparison chart
    </h1>
      <h2 className="text-xl 2xl:text-4xl pt-4">
      We offer a variety of subscription plans to cater to the diverse needs of schools,
      from small institutions to large campuses
      </h2>
    </div>
  </div>
  )
}

export default PricingHeading