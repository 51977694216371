import { useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { FaSearch, FaChevronDown } from 'react-icons/fa';

// Dummy messages data
const messages = [
  { id: 1, name: 'Peter HR', role: 'Teacher', timestamp: 'Today', details: 'This is a special request by the board of directors.' },
  { id: 2, name: 'Change DND', role: 'New role: Admin', timestamp: '17 mins ago', details: 'Details about Change DND...' },
  { id: 3, name: 'How do I fix it?', role: 'Role Change: Admin', timestamp: '23 mins ago', details: 'Details about How do I fix it...' },
  { id: 4, name: 'Tim Admin', role: 'New role: Tim Admin', timestamp: '1 hour ago', details: 'Details about Tim Admin...' },
  { id: 5, name: 'Mary Teacher', role: 'New role: Mary Teacher', timestamp: '10 days ago', details: 'Details about Mary Teacher...' },
];

const MessageList = () => {
  const [selectedMessage, setSelectedMessage] = useState(null); // State to track the selected message

  // When a message is clicked
  const handleMessageClick = (message) => {
    setSelectedMessage(message); // Set the selected message
  };

  // Go back to the message list
  const handleBackClick = () => {
    setSelectedMessage(null); // Deselect the message
  };

  return (
    <div className={`pt-20 flex transition-all duration-300 ${selectedMessage ? 'w-full' : 'w-[35%]'}`}>
      {/* Left Sidebar - Messages List */}
      <div className={`p-4 rounded-xl bg-gray-100 h-full transition-all duration-300 ${selectedMessage ? 'w-full' : 'w-full'}`}>
        {!selectedMessage ? (
          <>
            {/* Search and Sort UI */}
            <div className="flex justify-between">
              {/* Search Bar */}
              <div className="w-2/3 flex items-center border rounded-lg px-1 bg-gray-100">
                <FaSearch className="text-black" />
                <input
                  type="text"
                  placeholder="Search"
                  className="ml-2 bg-transparent focus:outline-none"
                />
              </div>

              {/* Sort Dropdown */}
              <div className="flex items-center border rounded-lg bg-gray-100">
                <button className="flex items-center">
                  <span className="text-gray-500">Sort by:</span>
                  <FaChevronDown className="text-gray-400" />
                </button>
              </div>
            </div>

            {/* List of Messages */}
            <div className="space-y-2">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`p-2 hover:bg-primary_color rounded-lg cursor-pointer ${selectedMessage?.id === message.id ? 'bg-purple-500' : ''}`}
                  onClick={() => handleMessageClick(message)}
                >
                  <p className="font-bold">{message.name}</p>
                  <p className="text-sm text-gray-600">{message.role}</p>
                  <p className="text-xs text-gray-400">{message.timestamp}</p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            {/* Message Details View */}
            <div className="bg-white p-6 rounded-xl shadow-lg">
              {/* Back Button */}
              <button onClick={handleBackClick} className="mb-4 text-purple-500 hover:text-purple-700">
                &larr; Back
              </button>

              {/* Header Section */}
              <div className="pb-4 border-b mb-6">
                <h2 className="text-2xl font-bold">{selectedMessage.name}</h2>
                <p className="text-sm text-gray-600">Current role: {selectedMessage.role}</p>
              </div>

              {/* Main Content Section */}
              <div className="space-y-6">
                <div className="bg-gray-100 p-4 rounded-lg mb-4 shadow">
                  <p><strong>New Role:</strong> Teacher</p>
                  <p><strong>Old Role:</strong> Admin</p>
                  <p><strong>Request by:</strong> Peter HR</p>
                  <p><strong>Date:</strong> 01/01/23</p>
                  <p><strong>Note:</strong> {selectedMessage.details}</p>
                </div>
              </div>

              {/* Input Section */}
              <div className="mt-8 flex items-center">
                <input
                  type="text"
                  placeholder="Type a message..."
                  className="flex-1 p-2 border rounded-lg focus:outline-none"
                />
                <button className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full ml-4">
                  <FaPaperPlane size="24px" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MessageList;
