import { HorizontalMenu } from "../../../common/icons/icons";
import Modal from "../../../common/modal/modal.component";
import OptionTab from "../option_tab.component";
import React, { useEffect, useRef, useState } from 'react';
import EditSession from "./edit_session.component";
import EditTerm from "../term_manager/edit_term.component";
import { useAppDispatch } from "../../../../redux/hooks.ts";
import { editTitleHandler, editStartDateHandler, editEndDateHandler } from "../../../../redux/slices/session_slice.ts";
import {
    editTermEndDateHandler,
    editTermStartDateHandler,
    editTermTitleHandler,
} from "../../../../redux/slices/term_slice.ts";
const SessionManagerOption = ({ serialNo, title, startDate, endDate, type, index }) => {
    const dispatch = useAppDispatch()
    const [status, setStatus] = useState(false)
    const [openEditor, setOpenEditor] = useState()
    const [toggleErrorMessage, setToggleErrorMessage] = useState(false)
    const [currentColor, setCurrentColor] = useState('#DCDCDC');
    const [toggleMenu, setToggleMenu] = useState(false)
    const errorMessageRef = useRef(null);

    // Function to toggle between lightgrey and white
    const toggleColor = () => {
        setCurrentColor((prevColor) => (prevColor === '#DCDCDC' ? 'white' : '#DCDCDC'));
    };

    useEffect(() => {
        toggleColor();
    }, []);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (errorMessageRef.current && !errorMessageRef.current.contains(event.target)) {
                setToggleErrorMessage(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const statusChecker = () => {
        const date = new Date();
        const check = date.toISOString().split('T')[0] > startDate && date.toISOString().split('T')[0] < endDate ? true : false;
        setStatus(check)
    }
    const setEditHandler = () => {
        if (type === "session") {
            dispatch(editTitleHandler(title))
            dispatch(editStartDateHandler(startDate))
            dispatch(editEndDateHandler(endDate))
        }
        else {
            dispatch(editTermTitleHandler(title));
            dispatch(editTermStartDateHandler(startDate))
            dispatch(editTermEndDateHandler(endDate))
        }
    }

    const statusHandler = () => {
        setStatus(!status)
        setToggleMenu(!toggleMenu)
    }
    useEffect(() => {
        statusChecker()

    }, [startDate,endDate])
    return (
        <>
            <div style={{
                background: currentColor
            }} className={`w-full mt-5 rounded-md bg-${currentColor} flex p-4 items-center justify-between relative`}>
                <p className="font-bold text-base">{serialNo}</p>
                <p className="font-bold text-base">{title}</p>
                <p className="font-bold text-base">{startDate}</p>
                <p className="font-bold text-base">{endDate}</p>
                <p onClick={() => {
                    if (status === false) {
                        setToggleErrorMessage(true)
                    }

                }} className={`${status ? 'bg-green' : 'bg-grey'} w-28 text-center text-white rounded-md p-1 cursor-pointer`}>
                    {status ? "Active" : "Inactive"}
                </p>
                <HorizontalMenu cursor={'pointer'} onClick={() => {
                    setToggleMenu(!toggleMenu)
                }} />
                <div className={`${toggleMenu ? 'block' : 'hidden'} bg-white rounded-md p-3 absolute z-10 shadow-lg shadow-shadow_color -right-20 top-0 `} >
                    <p onClick={() => {
                        setToggleMenu(!toggleMenu)
                        setOpenEditor(true)
                        setEditHandler()

                    }} className="font-bold text-xs mb-2 cursor-pointer text-black">Edit</p>
                    <p onClick={() => { statusHandler() }} className="font-bold text-xs cursor-pointer text-black">{status ? "Deactivate" : "Activate"}</p>
                </div>
                <div ref={errorMessageRef} className={`${toggleErrorMessage ? 'block' : 'hidden'} bg-faint_pink w-20 rounded-xl p-3 absolute z-10 shadow-lg shadow-shadow_color right-1 top-0 `} >
                    <p style={{ fontSize: '10px' }} className="font-bold cursor-pointer text-black">Date error. Date overlaps an already existing!</p>
                </div>
            </div>
            <Modal setShowModal={setOpenEditor} showModal={openEditor} >
                {type === "session" ? <EditSession setOpenEditor={setOpenEditor} title={title} endDate={endDate} startDate={startDate} index={index} /> : <EditTerm title={title} endDate={endDate} startDate={startDate} setOpenEditor={setOpenEditor} index={index} />}
            </Modal>
        </>
    );
};






const SessionManagerTable = ({ tabOptions, options, type }) => {

    return (
        <div className="w-full mt-5" >
            <OptionTab options={tabOptions} />
            {options?.map(({ title, start_date, end_date, status }, index) => (
                <SessionManagerOption key={index} type={type} index={index} serialNo={index + 1} title={title} startDate={start_date} endDate={end_date} status={status} />
            ))}

        </div>
    );
}

export default SessionManagerTable;