import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Button from "../../common/button/button.component";
import { MailIcon } from "../../common/icons/icons";
import { Link } from 'react-router-dom';
import { setForgetPasswordEmail } from '../../../redux/slices/forgetpassword_slice';

const ForgetPasswordForm = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false); // State to track button loading

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Set loading to true when request starts

        dispatch(setForgetPasswordEmail(email));

        try {
            const response = await axios.post('https://api.mystarsonline.com/api/Auth/ForgotPassword', { email });

            if (response.status === 200) {
                toast.success('Check your email!');
                setTimeout(() => {
                    navigate('/verifyforgetpassword');
                }, 2000);
            } else {
                toast.error('An error occurred. Please try again!');
                setIsLoading(false); // Return button to normal state

            }
        } catch (error) {
            toast.error('An error occurred. Please try again!');
            setIsLoading(false); // Return button to normal state

        }
    };

    return (
        <>
       <ToastContainer position='top-center' autoClose={2000} theme='light' pauseOnHover={false}   /> 
            <div className=''>
                <form className="bg-[#616161] shadow-2xl opacity-60 rounded-xl py-7 px-8 border md:py-7 md:px-8 xl:py-6 xl:px-10 2xl:py-20 2xl:px-20" onSubmit={handleSubmit}>
                    <div className="flex flex-col justify-center text-center pt-5 mb-2 md:pt-0">
                        <h4 className="font-bold text-center mb-6 text-2xl md:text-2xl xl:text-3xl 2xl:text-5xl text-[#fff]">Forgot Password</h4>
                        <span className='w-full flex flex-col justify-center text-[#fff] font-thin text-base md:text-base lg:text-base xl:text-lg 2xl:text-3xl'>
                            <p>Enter your email to</p>
                            <p>reset your password</p>
                        </span>
                    </div>
                    <div className="w-full flex items-center flex-col mb-2 mt-4 space-y-4 md:space-y-4 xl:space-y-3 2xl:space-y-6">
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <MailIcon color="black" />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="Email Address"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                      {/* Button with spinner */}
          <div className="w-full flex items-center justify-center pt-3 mb-1 cursor-pointer">
                        <div className={`w-full border border-primary_color rounded-xl p-1 text-sm flex items-center text-center text-primary_color font-semibold transition-all duration-300 md:text-sm xl:text-base 2xl:text-xl ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-white hover:bg-black hover:text-white'}`}>
                            <Button
                                type="submit"
                                className="w-full"
                                btnTitle={isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <svg className="animate-spin h-5 w-5 text-primary_color" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0h-2a6 6 0 00-12 0H4z"></path>
                                        </svg>
                                        <span>Loading...</span>
                                    </div>
                                ) : "Send Password Reset"}
                                disabled={isLoading} // Disable button when loading
                            />
                        </div>
                    </div>

                    <div className="w-full flex items-center justify-center pt-4">
                        <Link className="text-center text-xs md:text-sm xl:text-base 2xl:text-[22px] text-[#fff] cursor-pointer" to="/login">
                            Remembered your password? <span className='text-[#fff]'>Log in!</span>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default ForgetPasswordForm;
