import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getTenant from '../../api/admin/get_tennant';
import { activateTenant, deactivateTenant } from '../../api/admin/tenant_actions';

export const fetchTenants = createAsyncThunk('tenant/fetchTenants', async (_, { getState, rejectWithValue }) => {
  const state = getState();
  const token = state.login.logintoken;

  if (!token) {
    return rejectWithValue('No token found');
  }

  try {
    const data = await getTenant(token);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const activateTenantThunk = createAsyncThunk('tenant/activateTenant', async (id, { getState, rejectWithValue }) => {
  const state = getState();
  const token = state.login.logintoken;

  if (!token) {
    return rejectWithValue('No token found');
  }

  try {
    const data = await activateTenant(id, token);
    return { id, data };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const deactivateTenantThunk = createAsyncThunk('tenant/deactivateTenant', async (id, { getState, rejectWithValue }) => {
  const state = getState();
  const token = state.login.logintoken;

  if (!token) {
    return rejectWithValue('No token found');
  }

  try {
    const data = await deactivateTenant(id, token);
    return { id, data };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: {
    tenants: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenants.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTenants.fulfilled, (state, action) => {
        if (Array.isArray(action.payload.data)) {
          state.tenants = action.payload.data.map(tenant => ({
            
            id: tenant.id,
            name: tenant.admin ? `${tenant.admin.firstName} ${tenant.admin.lastName}` : 'N/A',
            middleName: tenant.admin?.middleName || '',  // Check if this is being included
            status: tenant.isActive ? 'Active' : 'Inactive',
            verification: tenant.verification ? true : false,
            packages: 'Free',
            school: tenant.name,
            joined: new Date(tenant.created).toLocaleDateString(),
          }));
        } else {
          state.tenants = [];
        }
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(fetchTenants.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(activateTenantThunk.fulfilled, (state, action) => {
        const tenantIndex = state.tenants.findIndex(t => t.id === action.payload.id);
        if (tenantIndex !== -1) {
          state.tenants[tenantIndex].status = 'Active';
        }
      })
      .addCase(deactivateTenantThunk.fulfilled, (state, action) => {
        const tenantIndex = state.tenants.findIndex(t => t.id === action.payload.id);
        if (tenantIndex !== -1) {
          state.tenants[tenantIndex].status = 'Inactive';
        }
      });
  }
});

export default tenantSlice.reducer;
