import React from 'react'
import Faq from '../support/faq.component'

const StarHero = () => {
  return (
    <div className='h-full w-full'>
            <div className='flex flex-row pt-10'>
                    <div className='bg-grey h-52 md:h-[300px] lg:h-[410px] 2xl:h-[800px] w-full'>

                    </div>
                    <div className='h-52 md:h-[300px] lg:h-[410px] 2xl:h-[800px] bg-white w-full pt-0 md:pt-20 flex justify-center items-center '>
                        <div className='flex flex-col self-center'>
                        <span className='md:text-2xl lg:text-6xl text-black font-bold'>Star </span>
                        <span className='md:text-2xl lg:text-6xl text-black font-bold'>Tracker</span>
                        </div>
                    </div>
            </div>
    </div>
  )
}

export default StarHero