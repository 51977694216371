import { createSlice } from '@reduxjs/toolkit';

const planSlice = createSlice({
  name: 'plan',
  initialState: {
    subscriptionId: null, // This will store the subscriptionId
  },
  reducers: {
    setSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload;
    },
  },
});

export const { setSubscriptionId } = planSlice.actions;

export default planSlice.reducer;
