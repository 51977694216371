import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenants } from '../../../../../redux/slices/tenant_slice';
import Paginator from "../../../superAdmin/activity_log/paginator.component";
import TableInfoInactive from './Table';
import OptionTabInactive from './option_tab';
import { TbCaretDown, TbCaretUpDown } from "react-icons/tb"; // Caret icons
import { AiOutlineSearch } from 'react-icons/ai';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton';
const InActive = () => {
  const dispatch = useDispatch();
  const tenants = useSelector((state) => state.tenant.tenants);
  const status = useSelector((state) => state.tenant.status);
  const error = useSelector((state) => state.tenant.error);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  // Search state
  const [searchQuery, setSearchQuery] = useState('');

  // Sort state to keep track of current sorting for each field
  const [sortConfig, setSortConfig] = useState({
    name: null,
    school: null,
    joined: null,
  });

  useEffect(() => {
    dispatch(fetchTenants());
  }, [dispatch]);

  // Handle sorting on click
  const handleSort = (type) => {
    setSortConfig((prevConfig) => {
      const nextConfig = { ...prevConfig };

      if (nextConfig[type] === "asc") {
        nextConfig[type] = "desc";
      } else if (nextConfig[type] === "desc") {
        nextConfig[type] = null;
      } else {
        nextConfig[type] = "asc";
      }

      return nextConfig;
    });
  };

  // Sorting and filtering logic
  const filteredAndSortedTenants = useMemo(() => {
    let sortableTenants = tenants.filter(tenant => tenant.status === "Inactive");

    // Search filtering
    if (searchQuery) {
      sortableTenants = sortableTenants.filter((tenant) => {
        return (
          tenant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tenant.school.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tenant.packages.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tenant.joined.toLowerCase().includes(searchQuery.toLowerCase()) // Add more fields as necessary
        );
      });
    }

    // Sorting by name
    if (sortConfig.name) {
      sortableTenants.sort((a, b) => {
        if (sortConfig.name === 'asc') {
          return a.name.localeCompare(b.name);
        } else if (sortConfig.name === 'desc') {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });
    }

    // Sorting by school
    if (sortConfig.school) {
      sortableTenants.sort((a, b) => {
        if (sortConfig.school === 'asc') {
          return a.school.localeCompare(b.school);
        } else if (sortConfig.school === 'desc') {
          return b.school.localeCompare(a.school);
        }
        return 0;
      });
    }

    // Sorting by joined date
    if (sortConfig.joined) {
      sortableTenants.sort((a, b) => {
        if (sortConfig.joined === 'asc') {
          return new Date(a.joined) - new Date(b.joined);
        } else if (sortConfig.joined === 'desc') {
          return new Date(b.joined) - new Date(a.joined);
        }
        return 0;
      });
    }

    return sortableTenants;
  }, [tenants, sortConfig, searchQuery]);

  const totalTenants = filteredAndSortedTenants.length;
  const totalPages = Math.ceil(totalTenants / pageSize);

  const currentTenants = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return filteredAndSortedTenants.slice(startIndex, startIndex + pageSize);
  }, [currentPage, filteredAndSortedTenants]);

  // Handle Next and Previous page navigation
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const tabOptions = [
    { option: "S/N" },
    { option: "Name", sortable: true },
    { option: "Status" },  // Status is no longer sortable
    { option: "Package", sortable: true },
    { option: "School", sortable: true },
    { option: "Joined", sortable: true },
    { option: "Action" },
  ];

  return (
    <div className="w-full pb-10 mt-8 relative">
      {/* Search Bar */}
      <div className='flex justify-end mt-4'>
        <div className="border border-gray-300 rounded-full focus:outline-none relative w-full md:w-1/3">
          <input
            type="text"
            className="w-full p-2 pr-10"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <AiOutlineSearch className="absolute right-3 top-3 text-black" />
        </div>
      </div>
          {/* Table section*/}
      <div className="overflow-x-auto"> {/* Add horizontal scrolling here */}
      <OptionTabInactive 
        options={tabOptions} 
        sortConfig={sortConfig} 
        handleSort={handleSort}
      />

      {/* Table */}
      {status === 'loading' && <p className='pt-20'><Skeleton count={8} /> </p>}
      {status === 'failed' && <p>Error: {error}</p>}
      {status === 'succeeded' && currentTenants.map(({ id, name, status, packages, school, joined }, index) => (
        <TableInfoInactive
          key={id}
          serialNo={(currentPage - 1) * pageSize + index + 1}
          id={id}
          name={name}
          status={<span style={{ color: status === 'Active' ? 'green' : 'red' }}>{status}</span>}
          packages={<span style={{ color: packages === 'Free' ? 'green' : packages === 'Starter' ? 'blue' : packages === 'Pro' ? 'orange' : 'purple' }}>{packages}</span>}
          school={school}
          joined={joined}
        />
      ))}
      
      {/* Pagination Controls */}
      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalTenants}
        pageSize={pageSize}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
      </div>
    </div>
  );
};

export default InActive;
