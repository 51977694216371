import React, { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdCheckCircle } from 'react-icons/md';
import { FaPlus, FaMinus } from "react-icons/fa6";

export default function PlanComparisonAccordion() {
    const [expanded, setExpanded] = useState([]);

    // Updated structure to indicate in which column the checkmark appears
    const sections = [
        {
            title: "SPE (Student, Parent, Employee)",
            features: [
                { name: "Employee Records Management", columns: [true, true, true, true] },
                { name: "Session Manager", columns: [true, true, true, true] },
                { name: "Campus Management", columns: [true, true, true, true] },
                { name: "Sport House Manager", columns: [true, true, true, true] },
                { name: "Control Panel", columns: [true, true, true, true] },
                { name: "Student Parent System", columns: [true, true, true, true] },
                { name: "Entrance Exam", columns: [true, true, true, true] },
                { name: "Classroom Management", columns: [true, true, true, true] },
                { name: "Parent Communication", columns: [true, true, true, true] },
                { name: "Progress Evaluation", columns: [true, true, true, true] },
                { name: "Personalized News Feed With Filtering", columns: [false, true, true, true] },
                { name: "Student Tracking System", columns: [false, true, true, true] },
                { name: "Mass Messaging", columns: [false, true, true, true] },
                { name: "One-Click Class Report", columns: [false, true, true, true] },
                { name: "Online Admissions", columns: [false, true, true, true] },
                { name: "Timetable", columns: [true, true, true, true] },
                { name: "Gradebooks", columns: [true, true, true, true] },
                { name: "Student Profiles", columns: [true, true, true, true] },
                { name: "Hostel Manager", columns: [false, true, true, true] },
            ]
        },
        {
            title: "Human Resources",
            features: [
                { name: "Employee Records Management", columns: [true, true, true, true] },
                { name: "Time-Off Management", columns: [true, true, true, true] },
                { name: "Application Tracking System", columns: [false, true, true, true] },
                { name: "Onboarding", columns: [true, true, true, true] },
                { name: "Offboarding", columns: [false, true, true, true] },
                // Add more features and their columns as needed
            ]
        },
        {
            title: "Accounting",
            features: [
                { name: "Fee Management (including online payments)", columns: [true, true, true, true] },
                { name: "Invoice Generation & Management", columns: [false, true, true, true] },
                { name: "Expense Management", columns: [false, true, true, true] },
                { name: "Budget Tracking & Reporting", columns: [false, true, true, true] },
                { name: "Financial Reports (Customizable)", columns: [false, true, true, true] },
            ]
        },
        {
            title: "Inventory Management (Optional)",
            features: [
                { name: "School Supply Inventory Management", columns: [true, true, true, true] },
                { name: "Library Book Inventory Management", columns: [true, true, true, true] },
            ]
        },
        {
            title: "Maintenance Management (Optional)",
            features: [
                { name: "Work Order Management", columns: [true, true, true, true] },
                { name:  "Preventive Maintenance Scheduling", columns: [true, true, true, true] },
                { name:  "Asset Management(Tracking School Equipment)", columns: [true, true, true, true] },    
            ]
        },
        {
            title: "Bus Service (Transport or Logistic Service)",
            features: [
                { name:  "Bus Route Management", columns: [true, true, true, true] },
                { name:   "Bus Tracking (Real-time, if applicable)", columns: [true, true, true, true] },
                { name:  "Student Rider Management (including permissions)", columns: [true, true, true, true] },
            ]
        },
        {
            title: "Stella Pay (Payment Gateway) Integration",
            features: [
                { name:  "Stella Pay", columns: [true, true, true, true] },
            ]
        },
        {
            title: "Additionals",
            features: [
                { name:  "Mobile App", columns: [false, true, true, true] },
                { name:  "Email Support", columns: [true, true, true, true] },
                { name:  "Phone & Chat Support (higher tiers)", columns: [false, false, true, true] },
                
            ]
        },
        // Add more sections as needed
    ];

    const handleAccordionChange = (keys) => {
        setExpanded(keys);
    };

    return (
        <div className="md:p-5">
            {/* Non-accordion section with enrollment information */}
            <div className="border-b pb-5">
                <div className="grid grid-cols-5 text-start gap-4 md:grid md:grid-cols-5 md:text-center font-semibold text-sm md:text-base items-center ">
                    <div>Students Enrollment</div>
                    <div className=''>(1-50 Students)</div>
                    <div>(1,000 Students)</div>
                    <div>(3,000 Students)</div>
                    <div>Unlimited</div>
                </div>
            </div>

            {/* Accordion for plan features */}
            <Accordion allowZeroExpanded onChange={handleAccordionChange}>
                {sections.map((section, index) => (
                    <AccordionItem className='border-b' key={index} uuid={index.toString()}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="font-bold text-sm md:text-base  flex justify-between items-center bg-transparent border-none py-2 2xl:py-6">
                                {section.title}
                                <span>{expanded.includes(index.toString()) ? <FaMinus /> : <FaPlus />}</span>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="text-gray-600 py-2">
                            <ul className="list-none">
                                {section.features.map((feature, idx) => (
                                    <li key={idx} className="grid grid-cols-5 items-center border-b space-y-4">
                                        {/* Feature Name */}
                                        <div className="mr-4 space-y-4 2xl:text-xl">{feature.name}</div>
                                        
                                        {/* Checkmark or Empty space for each column */}
                                        {feature.columns.map((hasCheck, i) => (
                                            hasCheck ? (
                                                <MdCheckCircle key={i} className="text-purple-500 ml-2 justify-self-center" size="24px" />
                                            ) : (
                                                <div key={i} className="ml-2 w-6 h-6 justify-self-center" /> // Empty space when no checkmark
                                            )
                                        ))}
                                    </li>
                                ))}
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
}