import Activity from "../../superAdmin/activity_log/activity.component";
import OptionTab from "../../superAdmin/option_tab.component";
import Assignment from "./assignment.component";
import Result from "./results.component";


const Results_Table = ({ data }) => {
    const tabOptions = [
        {
            option: "S/N"
        },
        {
            option: "Subject"
        },
        {
            option: "Category"
        },
        {
            option: "Date"
        },
        {
            option: "Title"
        },
        {
            option: "Result"
        },
        {
            option: "Class Average"
        },
    ];



    return (
        <>
            <div className="w-full mb-20" >
                <div className="w-full mt-10 mb-5" >
                    <p className="font-bold text-base text-black" >Results</p>
                </div>
                <OptionTab options={tabOptions} />
                {data?.map(({ name, category, date, result, classAverage }, index) => (
                    <Result serialNo={index + 1} name={name} date={date} result={result} classAverage={classAverage} category={category} />
                ))}

            </div>
        </>
    );
}

export default Results_Table;