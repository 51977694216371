import Button from "../../common/button/button.component";
import image from "../../../assets/images/image-removebg-preview (16) 1.png";
import circlePattern from "../../../assets/images/cycle pattern.png";
import { Link } from 'react-router-dom';
const ScheduleMeeting = () => {
  return (
    <>
      <div className="w-full px-4  py-24 flex  items-center justify-center relative overflow-hidden lg:px-3 ">
        <img
          src={circlePattern}
          alt="pattern"
          className="absolute object-cover w-full -top-40 -right-60 md:w-2/3 lg:w-2/5 lg:-top-40 lg:-right-60 "
        />
        <div className=" w-full rounded-md bg-light_purple flex flex-col items-center justify-evenly pt-14 pb-14 relative  lg:flex-row lg:py-0 lg:w-11/12  ">
          <div className="w-full flex flex-col items-start px-10 justify-evenly lg:px-10 lg:w-1/2  leading-9 2xl:space-y-5">
            <h2 className=" text-2xl lg:text-4xl 2xl:text-6xl font-bold ">
              Not Sure which plan is right for you?
            </h2>
            <p className="text-normal mt-3 lg:text-xl 2xl:text-4xl font-normal font-poppins lg:mt-7">
              Let’s help you decide
            </p>
            <Link to="/support"
              className={
                " bg-primary_color p-3  mt-4 w-auto self-start lg:self-start text-sm text-white rounded-xl cursor-pointer md:self-start md:text-center lg:w-1/2 lg:p-2 2xl:w-[50%] 2xl:text-2xl"
              }>
              Schedule a meeting now
              </Link>
          </div>
          <div className="w-1/2 hidden flex justify-center items-center lg:block ">
            <img
              src={image}
              alt="schedule_meeting"
              className="w-2/4 object-contain "
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleMeeting;
