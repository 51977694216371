import ContactUs from "../../../components/pages/support/contact_us.component";
import FaqSection from "../../../components/pages/support/faq_section.component";
import SupportHero from "../../../components/pages/support/hero.component";

const Support = () => {
    return ( 
        <>
        <SupportHero/>
        <FaqSection/>
        <ContactUs/>
        </>
     );
}
 
export default Support;