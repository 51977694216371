import { useState } from "react";
import { HorizontalMenu } from "../../../common/icons/icons";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import copy_icon from "../../../../assets/images/document-copy.png"
const LoginCard = ({ serialNo, name, emailAddress, phoneNo, userRole }) => {
    const [toggleMenu, setToggleMenu] = useState(false)
    return (
        <>
            <div className={`w-full mt-5 rounded-md  bg-soft_grey flex p-4 items-center justify-between relative`}>
                <div style={{ width: "5%" }} >
                    <p className="text-sm font-medium">{serialNo}</p>
                </div>
                <div style={{ width: "20%" }} >
                    <p className="text-center text-sm font-medium "  >{name}</p>
                </div>
                <div style={{ width: "25%" }} className="flex flex-row items-center justify-end  gap-3   " >
                    <p className="text-sm font-medium" >{emailAddress}</p>
                    <img src={copy_icon} alt={"copy_icon"} style={{ objectFit: "contain", cursor: "pointer" }} />
                </div>
                <div style={{ width: "30%" }} className="  flex flex-row items-center justify-center  gap-3 " >
                    <p className="p-0 m-0 text-sm font-medium" >{phoneNo}</p>
                    <img src={copy_icon} alt={"copy_icon"} style={{ objectFit: "contain", cursor: "pointer" }} />
                </div>
                <div className="  w-1/5 flex flex-row items-center justify-between " >
                    <p className="text-right text-sm font-medium" >{userRole}</p>
                    <HorizontalMenu cursor={'pointer'} onClick={() => {
                        setToggleMenu(!toggleMenu)
                    }} />
                </div>

                <div className={`${toggleMenu ? 'block' : 'hidden'} bg-white rounded-md p-3 absolute z-10 shadow-lg shadow-shadow_color -right-20 top-0 `} >
                    <p onClick={() => {
                        setToggleMenu(!toggleMenu)
                    }} className="font-bold text-xs mb-4 cursor-pointer text-black">Edit credentials</p>
                    <p onClick={() => {
                        setToggleMenu(!toggleMenu)
                    }} className="font-bold text-xs mb-4  cursor-pointer text-black">Delete user</p>
                    <p onClick={() => {
                        setToggleMenu(!toggleMenu)
                    }} className="font-bold text-xs cursor-pointer text-black">Reset Password</p>
                </div>
            </div >
        </>
    );
}

export default LoginCard;