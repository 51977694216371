// src/redux/slices/otpVerificationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const otpVerificationSlice = createSlice({
  name: 'otpVerification',
  initialState: {
    verificationData: null,
  },
  reducers: {
    setVerificationData: (state, action) => {
      state.verificationData = action.payload;
    },
  },
});

export const { setVerificationData } = otpVerificationSlice.actions;
export default otpVerificationSlice.reducer;
