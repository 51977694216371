import attendance from "../../../../assets/images/solar_calendar-bold.png"
import club from "../../../../assets/images/club.png"
import exam from "../../../../assets/images/ph_exam-fill.png"
import subjects from "../../../../assets/images/subjects.png"
const StudentDataTab = () => {
    const data = [
        {
            img: attendance,
            name: "Attendance",
            number: "231"
        },
        {
            img: exam,
            name: "Days to exam",
            number: "12"
        },
        {
            img: subjects,
            name: "Subjects",
            number: "14"
        },
        {
            img: club,
            name: "Clubs",
            number: "03"
        },
    ]
    return (
        <>

            <div className="w-full flex items-center justify-between mt-20 gap-6" >
                {data.map(({ img, number,name }) => (
                    <div className="w-3/12 bg-soft_grey rounded-md p-5"   >
                        <div className="w-full flex items-center justify-between p-0 " >
                            <img className=" object-contain" src={img} alt="icon" />
                            <p className=" text-6xl text-primary_color font-bold" >{number}</p>
                        </div>
                        <div className="w-full mt-6" >
                            <p className=" text-base font-normal text-left" >{name}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}


export default StudentDataTab