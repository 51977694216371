import React, { useState, useEffect, useRef } from "react";
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import { IoTrashBinOutline } from "react-icons/io5";

const TableFeatures = ({ serialNo, title, status, image, id, onDelete }) => {
    const [toggleStatus, setToggleStatus] = useState(status === 'active');
    const [toggleMenu, setToggleMenu] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setToggleMenu(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleToggleStatus = () => {
        setToggleStatus(!toggleStatus);
    };

    return (
        <div className="w-full mt-5 rounded-xl bg-gray-100 flex p-4 items-center justify-between relative">
            <div className="w-[10%] flex justify-start">
                <p className="text-sm font-medium">{serialNo}</p>
            </div>
            <div className="w-[40%] flex items-center ">
               <img src={image} alt="Title" className="w-[120px] h-12 object-center mr-3" />
                  <p className="text-sm font-medium text-ellipsis">{title}</p>
            </div>
            <div className="w-[30%] flex justify-start">
                <button onClick={handleToggleStatus}>
                    {toggleStatus ? (
                        <FaToggleOn className="text-green text-2xl" />
                    ) : (
                        <FaToggleOff className="text-gray-500 text-2xl" />
                    )}
                </button>
            </div>
            <div className="w-[20%] flex justify-start text-start">
                <IoTrashBinOutline 
                    className="text-red-500 cursor-pointer" 
                    onClick={() => onDelete(id)} // Trigger delete on click
                />
            </div>
        </div>
    );
};

export default TableFeatures;
