import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../../common/button/button.component";
import heroImg from "../../../assets/images/FRONT PAGE copy 2.png";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton"; // Import Skeleton for loading placeholder

const HomeHero = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		// Fetch data from the API
		const fetchData = async () => {
			try {
				const response = await axios.get(
					"https://api.mystarsonline.com/api/Website/list"
				);
				setData(response.data.data[0]); // Assuming the first object in the response array is used
				setLoading(false);
			} catch (err) {
				setError("Error fetching data");
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	if (loading) {
		return (
			<div className="w-full sm:flex sm:flex-col pt-32 px-6 bg-white md:flex md:flex-row md:justify-between md:items-center md:px-5 md:pt-10 md:h-auto lg:pt-20 xl:pt-20 2xl:pt-40 overflow-y-hidden">
				<div className="w-full flex flex-col md:w-3/5 md:pl-4 md:pb-6 md:pt-10 lg:w-1/2 lg:pl-24 lg:pt-20 xl:pt-20 2xl:pt-20">
					<Skeleton height={40} width="80%" />
					<Skeleton height={40} width="60%" />
					<Skeleton height={40} width="40%" />
					<Skeleton height={40} width="30%" />
				</div>
				<div className="w-full md:w-2/5 lg:w-1/2 xl:w-3/6 2xl:w-[45%]">
					<Skeleton height={300} />
				</div>
			</div>
		);
	}

	if (error) {
		return <p className="text-red-600 text-center">{error}</p>;
	}

	const { title, subtitle1, subtitle2, buttonName, buttonLink } = data;

	return (
		// <div className="w-full sm:flex sm:flex-col pt-32 px-6 bg-white md:flex md:flex-row md:justify-between md:items-center md:px-5 md:pt-10 md:h-auto lg:pt-20 xl:pt-20 2xl:pt-40 overflow-y-hidden">
		<div className="w-full  flex flex-col sm:flex-row mt-20 md:mt-40 lg:mt-42 lg:items-start  lg:min-h-[70vh]  px-5 sm:px-12 lg:px-20  lg:pt-10 ">
			{/* <div className="w-full flex flex-col md:w-3/5 md:pl-4 md:pb-6 md:pt-10 lg:w-1/2 lg:pl-24 lg:pt-20 xl:pt-20 2xl:pt-20"> */}
			<div className="w-full flex flex-col items-center sm:items-start   gap-1 sm:gap-2 md:gap-4 h-full ">
				<h1 className="font-bold capitalize text-center sm:text-start md:text-4xl text-4xl lg:text-6xl ">
					{title || "Unleash Stellar  Potential"}

					{/* Fallback to default text */}
				</h1>
				{/* <h1 className="text-center text-2xl mb-3 capitalize font-bold leading-10 md:text-start md:text-3xl lg:text-5xl xl:text-5xl 2xl:text-7xl">
					{title || "Unleash Stellar  Potential"}

				</h1> */}

				{/* new version */}
				<h2 className="font-bold text-center sm:text-start sm:text-2xl capitalize">
					{subtitle1 || "Effortless student management"}
				</h2>

				{/* old version  */}
				{/* <h2 className="text-2xl leading-[1.125] font-normal capitalize text-center md:text-start md:text-3xl lg:text-5xl xl:text-5xl 2xl:text-7xl">
         		 {subtitle1 || "Effortless student management"}
       		 </h2> */}
				<h3 className="text-2xl text-[#9835D6] font-black text-center md:text-start md:text-3xl lg:text-5xl xl:text-5xl 2xl:text-7xl">
					{subtitle2 || "For Educators"}
				</h3>

				<Link
					to={buttonLink || "/pricing"} // Fallback to /pricing if buttonLink is not available
					className="hover:border-[#9835D6] transition-all ease-in duration-500  hover:bg-white hover:text-[#9835D6] hover:border bg-primary_color p-2 w-[16rem] lg:w-[422px] h-[2.5rem] lg:h-[52px]  font-bold text-white rounded-[10px] cursor-pointer text-center flex justify-center items-center"
				>
					{buttonName || "Get Started"}
				</Link>
				{/* <Link
					to={buttonLink || "/pricing"} // Fallback to /pricing if buttonLink is not available
					className="hover:border-[#9835D6] hover:bg-white hover:text-[#9835D6] hover:border bg-primary_color p-2 mt-4 w-48 px-6 self-center text-sm text-white rounded-2xl cursor-pointer text-center md:text-start md:self-start md:w-auto md:py-2 lg:w-46 2xl:px-10 2xl:text-[26px] 2xl:py-4"
				>
					{buttonName || "Get Started"}
				</Link> */}
			</div>
			<div className="w-full  h-full mt-10 sm:mt-0 ">
				{/* <div className="w-full md:w-2/5 lg:w-1/2 xl:w-3/6 2xl:w-[45%]"> */}
				<img
					src={heroImg}
					loading="lazy"
					className="object-cover w-full"
					alt={"hero-img"}
				/>
			</div>
		</div>
	);
};

export default HomeHero;
