import React from 'react'
import { FaUsers } from "react-icons/fa";
import { RiGraduationCapFill } from "react-icons/ri";
const ProductSummary = () => {
  return (
    <div className='pt-10'>
        <h1>Product Summary</h1>
    <div className='grid grid-cols-1 md:grid md:grid-cols-3 gap-3'>
        <div className='flex flex-row justify-between text-center border items-center bg-[#f9fafb] shadow-md p-8 '>
            <span className='text-[#9835D6] text-2xl font-bold'>
            <h1>Free</h1>
            </span>
            <span className='text-[#9835D6] text-3xl font-bold'>
                359
            </span>
        </div>
        <div className='flex flex-row justify-between text-center border items-center bg-[#f9fafb] shadow-md p-8 '>
            <span className='text-2xl text-[#00AF46] font-bold'>
            <h2 className=''>Starter</h2>
            </span>
            <span className='text-[#00AF46] text-3xl font-bold'>
                    120
            </span>
        </div>
        <div className='flex flex-row justify-between text-center border items-center bg-[#f9fafb] shadow-md p-8 '>
            <span className='text-2xl text-[#FF005C] font-bold'>
            <h2 className=''>Standard</h2>
            </span>
            <span className='text-[#FF005C] text-3xl font-bold'>
                    35
            </span>
        </div>
        <div className='flex flex-row justify-between text-center border items-center bg-[#f9fafb] shadow-md p-8 '>
            <span className='text-2xl text-[#FF005C] font-bold'>
            <h2 className=''>Pro</h2>
            </span>
            <span className='text-[#FF005C] text-3xl font-bold'>
                    35
            </span>
        </div>
    </div>
</div>
  )
}

export default ProductSummary