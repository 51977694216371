import UserTypeForm from "../../../components/pages/login/user_type_form.component";
import img from "../../../assets/images/FRONT PAGE copy 1.png"
import LoginForm from "../../../components/pages/login/login_form.component";
const LoginStudent = () => {
  return (
    <>

      {/* <div className="w-full flex items-start justify-evenly " >
        <div className=" w-full flex items-center justify-center md:w-full lg:w-2/5  " >
          <LoginForm message={"Sign In As An Student"} placeholder1={"Email"} placeholder2={'Password'} />
        </div>
        <div className=" hidden md:hidden lg:flex lg:w-3/5">
          <img src={img} className="w-full -bottom-11 relative " alt="login" />
        </div>
      </div> */}
      <div className="w-full h-full flex items-start lg:items-center justify-evenly min-[1025px]:items-center  " >
        <div className=" w-80 top-0 relative flex items-center justify-center  lg:w-300px lg:fixed z-10 md:relative md:w-96 md:top-0 md:left-0 lg:left-24 lg:top-36 " >
          <LoginForm message={"Sign In As An Student"} placeholder1={"Email"} placeholder2={'Password'} />
        </div>
        <div className=" hidden md:hidden lg:flex relative w-full ">
                    <img src={img} className="w-full fixed md:right-20 lg:-right-52 relative min-[1024px]:h-fit max-[ 1280px]:h-fit xl:h-80vh xl:object-contain min-[1281px]:-bottom-20" alt="login" />
                </div>
      </div>

    </>
  );
}

export default LoginStudent;