const OptionTabPayment = ({ options }) => {
    const columnWidths = {
        "S/N": "5%",
        "Amount": "16%",
        "User": "21%",
        "Date": "16%",
        "Plan": "16%",
        "Status": "16%",
        "Action": "10%"
    };

    return (
        <div className="bg-primary_color mt-4 rounded-xl p-3 flex text-sm justify-between w-[700px] md:w-full">
            {options.map(({ option }, index) => (
                <div 
                    key={index} 
                    style={{ width: columnWidths[option] || '15%' }} 
                    className={`option-tab-item text-sm flex items-center`}
                >
                    <p className="text-white text-sm font-bold flex items-center">
                        {option}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default OptionTabPayment;
