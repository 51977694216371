import { useEffect } from "react";
import AppRoute from "./navigation/routes";
import { useLocation } from "react-router-dom";
import GlobalSearchHandler from "./components/search/globalsearch";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    // <div className="App">
    <>
    <GlobalSearchHandler/>
    <AppRoute />
    </>
    // </div>
  );
}

export default App;
