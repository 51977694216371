import { TbCaretDown, TbCaretUpDown } from "react-icons/tb";

const OptionTabAdminUser = ({ options, sortConfig, handleSort }) => {
	const columnWidths = {
		"S/N": "5%",
		School: "21%",
		Status: "16%", // No sorting for Verified
		Students: "16%",
		Content: "16%",
		Email: "16%",
		"Expiry Date": "8%",
	};

	return (
		<div className="  bg-primary_color mt-4 rounded-xl p-3 flex text-sm justify-between w-[700px] md:w-full">
			{options.map(({ option, sortable }, index) => (
				<div
					key={index}
					style={{ width: columnWidths[option] || "15%" }}
					className="option-tab-item text-sm flex items-center"
				>
					<h3
						className="text-white text-xs sm:text-sm  font-bold flex items-center cursor-pointer "
						// className="text-white text-sm 2xl:text-xl font-bold flex items-center cursor-pointer"
						onClick={
							sortable ? () => handleSort(option.toLowerCase()) : undefined
						}
					>
						{sortable &&
							(sortConfig[option.toLowerCase()] === "asc" ? (
								<TbCaretDown className="mr-2 text-[15px]" />
							) : sortConfig[option.toLowerCase()] === "desc" ? (
								<TbCaretDown className="mr-2 rotate-180 text-[15px]" />
							) : (
								<TbCaretUpDown className="mr-2 text-[15px]" />
							))}
						{option}
					</h3>
				</div>
			))}
		</div>
	);
};

export default OptionTabAdminUser;
