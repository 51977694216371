import { useState } from "react";
import SuperAdminSideBar from "../components/pages/superAdmin/layout_components/sidebar.component";
import SuperAdminNavbar from "../components/pages/superAdmin/navbar.component";

const SuperAdminLayout = ({ children }) => {
    const [toggleSideBar, setToggleSideBar] = useState(true);

    return (
        <div className="flex h-screen bg-white">
            <SuperAdminSideBar setToggleSideBar={setToggleSideBar} toggleSideBar={toggleSideBar} />
            <section className={`flex-1 relative flex flex-col overflow-hidden ${toggleSideBar ? "ml-1/5" : ""}`}>
                <div className={`  ${!toggleSideBar ? 'block' : 'hidden'} md:hidden lg:hidden`}  onClick={() => { setToggleSideBar(!toggleSideBar) }} style={{
                    width: "100%",
                    backgroundColor: "#00000092",
                    position: "absolute",
                    height: '100vh',
                    zIndex: 10,
                    left: "0px"
                }} />
                <SuperAdminNavbar setToggleSideBar={setToggleSideBar} toggleSideBar={toggleSideBar} />
                <main className="flex-1 overflow-x-hidden overflow-y-auto p-4">
                    {children}
                </main>
            </section>
        </div>
    );
}

export default SuperAdminLayout;
