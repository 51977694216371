import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import OptionTabAdminTicket from "./option_tab";
import TableInfoTicket from "./Table";
import Paginator from '../../superAdmin/activity_log/paginator.component';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton';
const VerifiedTicket = () => {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // Show 10 tickets per page
    const token = useSelector((state) => state.login.logintoken); // Get token from Redux store

    const tabOptions = [
        { option: "S/N" },
        { option: "Ticket No" },
        { option: "Email" },
        { option: "Subject" },
        { option: "Ticket Status" },
        { option: "Last Updated" },
        { option: "Date Created" },
    ];

    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const response = await axios.get("https://api.mystarsonline.com/api/SupportTicket/get-all-tickets", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTickets(response.data.data); // Set the fetched tickets to state
            } catch (error) {
                console.error("Error fetching tickets:", error);
            } finally {
                setLoading(false); // Stop the loading indicator
            }
        };

        fetchTickets();
    }, [token]);

    // Calculate total pages based on the number of tickets
    const totalPages = useMemo(() => Math.ceil(tickets.length / pageSize), [tickets, pageSize]);

    // Get the tickets for the current page
    const currentTickets = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        return tickets.slice(startIndex, startIndex + pageSize);
    }, [currentPage, tickets]);

    // Handle Next and Previous page navigation
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    if (loading) {
        return <p className='pt-20'><Skeleton count={8} /> </p>;
    }

    return (
        <div className="w-full mt-3 overflow-x-auto">
            <OptionTabAdminTicket options={tabOptions} />
            {currentTickets.length > 0 ? (
                currentTickets.map(({ ticketNumber, email, subject, status, respondedAt, createdAt }, index) => (
                    <TableInfoTicket
                        key={index}
                        serialNo={(currentPage - 1) * pageSize + index + 1} // Calculate serial number based on current page
                        ticket={ticketNumber || "-"}
                        email={email || "-"}
                        subject={subject || "-"}
                        ticketstatus={status || "-"}
                        updated={respondedAt} // Pass raw date, it will be formatted inside TableInfoTicket
                        dateCreated={createdAt} // Pass raw date, it will be formatted inside TableInfoTicket
                    />
                ))
            ) : (
                <p>No tickets found.</p>
            )}

            {/* Pagination Controls */}
            <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                totalItems={tickets.length}
                pageSize={pageSize}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
            />
        </div>
    );
};

export default VerifiedTicket;
