import { createSlice } from "@reduxjs/toolkit";
import { SessionProps, TermProps } from "../types.ts";

const initialState: TermProps = {
  title: "",
  start_date: "",
  end_date: "",
  edit_title: "",
  edit_start_date: "",
  edit_end_date: "",
  terms: []
};

const SessionSlice = createSlice({
  name: "term_slice",
  initialState,
  reducers: {
    termTitleHandler: (state, { payload }) => {
      state.title = payload;
    },
    termStartDateHandler: (state, { payload }) => {
      state.start_date = payload;
    },
    termEndDateHandler: (state, { payload }) => {
      state.end_date = payload;
    },
    editTermTitleHandler: (state, { payload }) => {
      state.edit_title = payload;
    },
    editTermStartDateHandler: (state, { payload }) => {
      state.edit_start_date = payload;
    },
    editTermEndDateHandler: (state, { payload }) => {
      state.edit_end_date = payload;
    },
    createTerm: (state, { payload }) => {
      state.terms = [...state.terms, payload];
    },
    editTerm: (state, { payload }) => {
      const { id, newTerm } = payload;
      const index = state.terms.findIndex((term, index) => index === id);
      if (index !== -1) {
        state.terms[index] = {
          ...state.terms[index],
          ...newTerm
        };
      }
    },
    clearTermState: (state) => {
      state.title = "";
      state.end_date = "";
      state.start_date = "";
      state.edit_title = "";
      state.edit_start_date = "";
      state.edit_end_date = "";
    }
  }
});

export const {
  termStartDateHandler,
  termEndDateHandler,
  createTerm,
  termTitleHandler,
  editTerm,
  editTermEndDateHandler,
  editTermStartDateHandler,
  editTermTitleHandler,
  clearTermState
} = SessionSlice.actions;

export default SessionSlice.reducer;
