import Button from "../../../common/button/button.component";

const Confirmation = ({ setSaveChanges, setShowForm, setShowConfirmation }) => {
    return (
        <>
            <div className="w-full px-14 py-4 " >
                <div className="w-full" >
                    <p className="text-center text-primary_color font-bold  text-xl uppercase " >Confirmation</p>
                </div>
                <div className="w-full mt-5" >
                    <p className="text-center text-black text-sm font-medium" >
                        Are you sure you want to reset this password?
                    </p>
                </div>
                <div className="mt-20 mb-5 px-9 flex items-center justify-center gap-2 " >
                    <Button onClick={() => { setSaveChanges(true); setShowConfirmation(false) }} btnTitle={'Yes, Reset'} className={'w-28 p-2 bg-primary_color rounded text-sm text-white text-center cursor-pointer '} />
                    <Button btnTitle={'Go back'} btnColor={'bg-white'} className={'w-28 p-2 bg-white rounded border cursor-pointer border-primary_color text-sm text-center '} onClick={() => {
                        setShowForm(true)
                        setShowConfirmation(false)
                    }} />
                </div>
            </div>
        </>
    );
}

export default Confirmation;