import { TbCaretDown, TbCaretUpDown } from "react-icons/tb";

const OptionTabActive = ({ options, sortConfig, handleSort }) => {
  const columnWidths = {
    "S/N": "5%",
    "Name": "21%",
    "Status": "16%", // Status remains, but no sorting applied
    "Package": "16%",
    "School": "16%",
    "Joined": "16%",
    "Action": "8%",
  };

  return (
    <div className="bg-primary_color mt-4 rounded-xl p-3 flex text-sm justify-between w-[700px] md:w-full">
      {options.map(({ option, sortable }, index) => (
        <div 
          key={index} 
          style={{ width: columnWidths[option] || "15%" }} 
          className="option-tab-item text-sm flex items-center"
        >
          <p 
            className="text-white text-sm 2xl:text-xl font-bold flex items-center cursor-pointer"
            onClick={sortable ? () => handleSort(option.toLowerCase()) : undefined}
          >
            {sortable && (
              sortConfig[option.toLowerCase()] === "asc" ? (
                <TbCaretDown className="mr-2 text-[15px]" />
              ) : sortConfig[option.toLowerCase()] === "desc" ? (
                <TbCaretDown className="mr-2 rotate-180 text-[15px]" />
              ) : (
                <TbCaretUpDown className="mr-2 text-[15px]" />
              )
            )}
            {option}
          </p>
        </div>
      ))}
    </div>
  );
};

export default OptionTabActive;
