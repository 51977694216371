import React from "react";
import RevenueComponent from "../../../components/pages/adminDashboard/dashboard/total-revenue";
import Chart from "../../../components/pages/adminDashboard/dashboard/doughnut-chart";
import Three from "../../../components/pages/adminDashboard/dashboard/three_cards";
import LineChart from "../../../components/pages/adminDashboard/dashboard/line_chart";
import ProductSummary from "../../../components/pages/adminDashboard/dashboard/product_summary";
const Dashboard = () => {
	document.title = "Dashboard | MyStar  ";
	return (
		<div className=" bg-white border rounded-2xl py-4  lg:px-8  flex gap-4 flex-col  px-2 ">
			{/* <div className=" bg-white border rounded-2xl py-4  lg:px-8  space-y-10  "> */}
			<section className="  grid grid-cols-1  md:grid-cols-2 gap-2     ">
				{/* <section className="  grid grid-cols-1 space-y-10 md:grid  md:grid-cols-2 gap-8  md:space-y-0   overflow-x-hidden"> */}
				<div className="">
					<Chart />
				</div>
				<div>
					<RevenueComponent />
				</div>
			</section>
			<section className="px-2">
				<Three />
			</section>
			<section className="">
				<LineChart />
			</section>
			<section>
				<ProductSummary />
			</section>
		</div>
	);
};

export default Dashboard;
