import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import OptionFeatures from './option_features';
import TableFeatures from './table_features';
import Paginator from "../../../../superAdmin/activity_log/paginator.component"; // Ensure the correct path

const VerifiedFeatures = () => {
    const token = useSelector(state => state.login.logintoken); // Access token from Redux
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [data, setData] = useState([]); // State to hold API data
    const [loading, setLoading] = useState(true); // State to manage loading state
    const pageSize = 5; // Set items per page

    const tabOptions = [
        { option: "S/N" },
        { option: "Title" },
        { option: "Status" },
        { option: "Action" },
    ];

    // Fetch data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.mystarsonline.com/api/Testimonial/list-testimonials', {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                    },
                });

                if (response.status === 200) {
                    console.log("API response data:", response.data.testimonials); // Log the correct data
                    setData(response.data.testimonials); // Set the testimonials data
                } else {
                    console.error("Unexpected API response:", response);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token]); // Fetch data when the component mounts and when the token changes

    // Calculate total pages based on the data length and page size
    const totalPages = useMemo(() => Math.ceil(data.length / pageSize), [data.length, pageSize]);

    // Get data for the current page
    const currentData = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        const slicedData = data.slice(startIndex, startIndex + pageSize);
        console.log("Current page data:", slicedData); // Log current page data
        return slicedData;
    }, [currentPage, data, pageSize]);

    // Handle Next and Previous page navigation
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    // Handle the deletion of a testimonial
    const handleDeleteTestimonial = async (id) => {
        try {
            const response = await axios.delete(`https://api.mystarsonline.com/api/Testimonial/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
            });
            if (response.status === 200) {
                // Remove the deleted testimonial from the state
                setData((prevData) => prevData.filter((item) => item.id !== id));
            } else {
                console.error("Error deleting testimonial:", response);
            }
        } catch (error) {
            console.error("Error deleting testimonial:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Display loading state while fetching data
    }

    return (
        <div className="w-full mt-8 overflow-x-auto">
            <OptionFeatures options={tabOptions} />
            {currentData.map((item, index) => (
                <TableFeatures
                    key={index}
                    serialNo={(currentPage - 1) * pageSize + index + 1} // Calculate serial number based on current page
                    id={item.id} // Pass the testimonial ID
                    title={item.schoolName}  // Using 'role' for title
                    status={"active"}  // Set status manually if not provided
                    image={`https://api.mystarsonline.com${item.pictureUrl}`} // Construct the full image URL
                    onDelete={handleDeleteTestimonial} // Pass the delete handler
                />
            ))}
            {/* Pagination Controls */}
            <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                totalItems={data.length}
                pageSize={pageSize}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
            />
        </div>
    );
};

export default VerifiedFeatures;
