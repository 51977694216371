import Paginator from "../activity_log/paginator.component";
import OptionTab from "../option_tab.component";
import LoginCard from "./login_card.component";

const LoginManagementTable = () => {
    const tabOptions = [
        {
            option: "S/N"
        },
        {
            option: "Name"
        },
        {
            option: "Email address"
        },
        {
            option: "Phone number"
        },
        {
            option: "User Role"
        },
    ];
    const data = [
        {
            name: "Pristia Candra",
            email: "pristia@gmail.com",
            phone_no: "09076543214",
            user_role: "Staff",
        },
        {
            name: "Pristia Candra",
            email: "pristia@gmail.com",
            phone_no: "09076543214",
            user_role: "Parent",
        },
        {
            name: "Pristia Candra",
            email: "pristia@gmail.com",
            phone_no: "09076543214",
            user_role: "Student",
        },
        {
            name: "Pristia Candra",
            email: "pristia@gmail.com",
            phone_no: "09076543214",
            user_role: "Student",
        },


    ];
    return (
        <>

            <div className="w-full " >

                <OptionTab options={tabOptions} />
                {data.map(({ name, email, phone_no, user_role }, index) => (
                    <LoginCard serialNo={index + 1} name={name} emailAddress={email} phoneNo={phone_no} userRole={user_role} />
                ))}
                <Paginator />
            </div>
        </>
    )
}

export default LoginManagementTable;