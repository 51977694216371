import axios from 'axios';

const activateTenant = async (id, token) => {
  try {
    const response = await axios.put(`https://api.mystarsonline.com/api/Signup/activate-tenant/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Network error');
    }
  }
};

const deactivateTenant = async (id, token) => {
  try {
    const response = await axios.put(`https://api.mystarsonline.com/api/Signup/deactivate-tenant/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Network error');
    }
  }
};

export { activateTenant, deactivateTenant };
