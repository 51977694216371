const Assignment = ({ serialNo, name, start_date, end_date, title }) => {
    return (
        <>
            <div className="w-full flex flex-row items-center justify-between p-5 bg-soft_grey mt-4 rounded-md " >
                <div style={{ width: "4%" }} >
                    <p className="text-sm font-medium">{serialNo}</p>
                </div>
                <div style={{ width: "20%" }} >
                    <p className="text-left text-sm font-medium "  >{name}</p>
                </div>
                <div style={{ width: "19%", }} className="flex flex-row items-center justify-start " >
                    <p className="text-sm font-medium" >{start_date}</p>
                </div>
                <div style={{ width: "19%", }} className="  flex flex-row items-center justify-start" >
                    <p className="p-0 m-0 text-sm font-medium left-4  relative"  >{end_date}</p>
                </div>
                <div style={{ width: "19%", }} className="flex flex-row items-center justify-between " >
                    <p className="text-right text-sm font-medium left-4  relative" >{title}</p>
                </div>

            </div>
        </>
    );
}

export default Assignment;