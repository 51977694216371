import { useState } from "react";
import Button from "../../common/button/button.component";
import { EnvelopIcon, HomeIcon, PhoneIcon, UserIcon } from "../../common/icons/icons";
import { Link, useNavigate } from "react-router-dom";

const StepOne = () => {
    const navigate = useNavigate()
    const [form, setForm] = useState([
        {
            name: "First Name",
            value: "",
            icon: <UserIcon size={16} color={'white'} />,
        },
        {
            name: "Last Name",
            value: "",
            icon: <UserIcon size={16} color={'white'} />,
        },
        {
            name: "Middle Name",
            value: "",
            icon: <UserIcon size={16} color={'white'} />,
        },
        {
            name: "Phone Number",
            value: "",
            icon: <PhoneIcon size={16} color={'white'} />,
        },
        {
            name: "Email",
            value: "",
            icon: <EnvelopIcon size={16} color={'white'} />,
        },
        {
            name: "Permanent Home Address",
            value: "",
            icon: <HomeIcon size={16} color={'white'} />,
        },
    ])
    const navigationHandler = () => {
        navigate("/signup/employee/details/step-2")
    }
    return (
        <>
            <div className="w-full h-full " >
                <div className=" w-11/12  md:w-3/5 lg:w-1/2 m-auto relative bg-white bg-opacity-50 rounded-xl py-5 " >
                    <div className="w-full px-6" >
                        <h4 className=" text-center text-xl  md:text-xl lg:text-2xl text-white"  >Employee Sign Up</h4>
                        <p className=" text-base text-center md:text-lg lg:text-lg text-white">Personal information</p>
                    </div>
                    <div className="w-full flex items-center justify-evenly flex-wrap px-5 mt-2" >
                        {form.map(({ name, icon }) => (
                            <div className=" w-46% flex items-center justify-between border-white border md:w-46% lg:w-45%  rounded-md p-3 mt-2 "  >
                                <div className=" w-1/6 " >
                                    {icon}
                                </div>
                                <div className=" w-11/12 " >
                                    <input className="w-full outline-none text-white placeholder-white " style={{ background: "none" }} placeholder={name} />
                                </div>
                            </div>
                        ))}
                        <div className="w-full flex items-center justify-evenly mt-10 " >
                            <Button onClick={navigationHandler} className=" w-2/5 bg-white cursor-pointer rounded-md p-2 flex border border-primary_color items-center justify-center mb-5 text-primary_color text-base md:text-lg lg:text-lg   font-semibold hover:bg-primary_color hover:text-white" btnTitle={'Proceed'} />
                            <Button className=" w-2/5 bg-white cursor-pointer rounded-md p-2 flex border border-primary_color items-center justify-center mb-5 text-primary_color text-base md:text-lg lg:text-lg   font-semibold hover:bg-primary_color hover:text-white" btnTitle={'Cancel'} />
                        </div>
                    </div>

                    <div className="text-center text-sm md:text-base lg:text-lg text-white mb-5 cursor-pointer" >
                        <p>Step 1 of 2</p>
                    </div>
                    <div className="w-full flex items-center justify-center" >
                    <Link className="text-center text-sm md:text-base lg:text-base text-white mb-5 cursor-pointer" to={'/login'} >Alread have an account? Sign In!</Link>
                </div>
                </div>
            </div>
        </>
    );
}

export default StepOne;