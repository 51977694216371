import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const LineChart = () => {
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'No. of Payments',
                data: [20, 40, 30, 50, 60, 50, 70, 80, 75, 70, 85, 80],
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 2,
                pointRadius: 0, // Hides the points
                tension: 0.4 // Smoothens the line
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false // Hides the legend
            },
            tooltip: {
                enabled: false // Disables tooltips
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false // Hides the Y-axis grid lines
                },
                ticks: {
                    color: '#374151', // Adjusts text color
                    font: {
                        size: 14
                    }
                }
            },
            x: {
                grid: {
                    display: false // Hides the X-axis grid lines
                },
                ticks: {
                    color: '#374151', // Adjusts text color
                    font: {
                        size: 14
                    }
                }
            }
        }
    };

    return (
        <div className="  p-5 bg-white rounded-2xl space-y-2 shadow-md border">
            <h1 className='text-center 2xl:text-2xl'>Payments</h1>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                <Line data={data} options={options} />
            </div>
            <h1 className='text-center 2xl:text-2xl'>Months</h1>
        </div>
    );
};

export default LineChart;
