import { useNavigate } from "react-router-dom"
import { BackIcon2 } from "../../common/icons/icons"

const TitleBar = ({ width, title, showBackIcon }) => {
    const goBack = useNavigate()
    return (
        <>
            <div className={`${width ? width : 'w-full'} flex flex-row items-center justify-start gap-2`} >
                {showBackIcon && <BackIcon2 cursor={'pointer'} onClick={() => { goBack(-1) }} color={"#9835D6"} size={35} />}
                <h3 className={"font-bold text-2xl text-grey"} >{title}</h3>
            </div>
        </>
    )
}

export default TitleBar