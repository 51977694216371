import { createSlice } from "@reduxjs/toolkit";
import { SessionProps } from "../types.ts";

const initialState: SessionProps = {
  title: "",
  start_date: "",
  end_date: "",
  edit_title: "",
  edit_start_date: "",
  edit_end_date: "",
  sessions: []
};

const SessionSlice = createSlice({
  name: "session_slice",
  initialState,
  reducers: {
    titleHandler: (state, { payload }) => {
      state.title = payload;
    },
    startDateHandler: (state, { payload }) => {
      state.start_date = payload;
    },
    endDateHandler: (state, { payload }) => {
      state.end_date = payload;
    },
    editTitleHandler: (state, { payload }) => {
      state.edit_title = payload;
    },
    editStartDateHandler: (state, { payload }) => {
      state.edit_start_date = payload;
    },
    editEndDateHandler: (state, { payload }) => {
      state.edit_end_date = payload;
    },
    createSession: (state, { payload }) => {
      state.sessions = [...state.sessions, payload];
    },
    editSession: (state, { payload }) => {
      const { id, newSession } = payload;
      const index = state.sessions.findIndex((session, index) => index === id);
      if (index !== -1) {
        state.sessions[index] = {
          ...state.sessions[index],
          ...newSession
        };
      }
    },
    clearSessionState: (state) => {
      state.title = "";
      state.end_date = "";
      state.start_date = "";
      state.edit_title = "";
      state.edit_start_date = "";
      state.edit_end_date = "";
    }
  }
});

export const {
  startDateHandler,
  endDateHandler,
  createSession,
  titleHandler,
  editSession,
  editEndDateHandler,
  editStartDateHandler,
  editTitleHandler,
  clearSessionState
} = SessionSlice.actions;

export default SessionSlice.reducer;
