import { contactList } from "./contact_list";
import playStore from "../../../assets/images/playStore.png";
import appleStore from "../../../assets/images/appleStore.png";
import Logo from "../logo/logo.component";
import {
  FaceBookIcon,
  InstagramIcon,
  LinkedIn,
  TwitterIcon
} from "../icons/icons";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer className="w-full bg-background px-10 py-24 md:py-32 lg:py-40  md:px-24 lg:px-24 relative ">
        <section className="lg:w-full flex-col flex md:flex-row md:items-center md:justify-between lg:flex-row lg:items-baseline lg:justify-between pb-24 md:flex-wrap  ">
          <div className="lg:w-1/4 md:w-2/5 w-full">
            <h5 className="text-start text-normal font-normal text-black mb-3  ">
              Solution
            </h5>
            <ul className="w-2/3">
              <li className="text-start text-sm mb-5 font-normal text-grey border-b-shadow_color border-b-2  pb-5">
                Lorem ipsum
              </li>
              <li className="text-start text-sm mb-5 font-normal text-grey border-b-shadow_color pb-5 border-b-2 ">
                Lorem ipsum
              </li>
              <li className="text-start text-sm mb-5 font-normal text-grey border-b-shadow_color pb-5 border-b-2 ">
                Lorem ipsum
              </li>
              <li className="text-start text-sm mb-5 font-normal text-grey border-b-shadow_color pb-5 border-b-2 ">
                Lorem ipsum
              </li>
              <li className="text-start text-sm mb-5 font-normal text-grey border-b-shadow_color pb-5 border-b-2 ">
                Lorem ipsum
              </li>
              <li className="text-start text-sm mb-5 font-normal text-grey border-b-shadow_color pb-5 border-b-2 ">
                Lorem ipsum
              </li>
              <li className="text-start text-sm mb-5 font-normal text-grey border-b-shadow_color pb-5 border-b-2 ">
                Lorem ipsum
              </li>
            </ul>
          </div>
          <div className="lg:w-1/4 md:w-2/5 md:mt-5 w-full">
            <h5 className="text-start text-normal font-normal text-black mb-3">
              Company
            </h5>
            <ul className="w-2/3">
              {contactList.map(({ name, path }) => (
                <Link key={name + Math.random()}  to={path ? path : "/"} className=" no-underline text-grey " >
                  <li key={name + Math.random()} className="text-start text-sm mb-5 font-normal text-grey border-b-shadow_color pb-5 border-b-2 ">
                    {name}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className=" w-full lg:w-1/4 md:w-2/5 ">
            <h5 className="text-start text-normal font-normal text-black mb-3">
              Contact
            </h5>
            <p className="text-start text-sm font-normal text-black mb-3 w-40">
              Lorem ipsum dolor sit amet ons ectetur. Lorem at purus donec Lorem
              ipsum dolor sit amet onsectetur.
            </p>
            <p className="text-start text-sm font-normal text-black mt-32 sm:mt-10">
              lorem@smsabuja.com
            </p>
          </div>

          <div className=" lg:w-1/4  flex flex-col justify-center lg:items-center md:w-2/5 md:items-start w-full items-start mt-5 ">
            <h5 className="text-center text-normal font-normal text-black mb-3">
              Download the <br /> My Star App
            </h5>
            <img
              src={playStore}
              alt="playStore"
              className="my-6 cursor-pointer"
            />
            <img src={appleStore} alt="appleStore" className="cursor-pointer" />
          </div>
        </section>
        <section
          className=" py-14 w-full absolute bottom-0 flex flex-col
         items-center justify-between left-0 md:flex-row md:flex md:px-14 lg:px-24 lg:flex lg:flex-row "
        >
          <Logo />
          <p className="text-xs text-black font-light sm:mt-5">
            ©Copyright 2023 , My star online, Nigeria.
          </p>
          <div className="w-auto flex items-center justify-center gap-2">
            <LinkedIn
              size={18}
              className={"cursor-pointer hover:text-primary_color"}
            />
            <FaceBookIcon
              size={18}
              className={"cursor-pointer hover:text-primary_color"}
            />
            <InstagramIcon
              size={18}
              className={"cursor-pointer hover:text-primary_color"}
            />
            <TwitterIcon
              size={18}
              className={"cursor-pointer hover:text-primary_color"}
            />
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
