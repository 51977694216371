const OptionTab = ({ options }) => {
    const width = (100 / options?.length)
    return (
        <>
            <div className=" bg-primary_color mt-4 rounded-md p-3 flex flex-row items-center justify-between " >
                {options.map(({ option }) => (
                    <div style={{ width: option?.includes("S/N") ? width - 15 + "%" : width + "%", display: "flex", alignItems: "center", justifyContent: "flex-start" }} >
                        <p key={option} className="text-white text-base font-bold" >
                            {option}
                        </p>
                    </div>
                ))}
            </div>
        </>
    );
}

export default OptionTab;