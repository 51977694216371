const convertToCSV = (objArray) => {
    const header = Object.keys(objArray[0]).join(',');
    const rows = objArray.map(obj => Object.values(obj).join(','));
    return `${header}\n${rows.join('\n')}`;
  };

  export const downloadCSV = (data) => {
    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(data);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `activitylogs.csv`);
    document.body.appendChild(link);
    link.click();
  };