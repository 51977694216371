import * as Tabs from "@radix-ui/react-tabs";
import { useState, useEffect } from "react";
import EditDetails from "../../../components/pages/adminDashboard/user/edit/edit_details";
import Verified from "../../../components/pages/adminDashboard/user/verified/verified_tab";
import Paginator from "../../../components/pages/superAdmin/activity_log/paginator.component";
import CreateDetails from "../../../components/pages/adminDashboard/user/create/create_details";
import UnVerified from "../../../components/pages/adminDashboard/user/unverified/unverified_tab";

const UserPc = () => {
	const [activeTab, setActiveTab] = useState("tab1");
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (isModalOpen) {
			// Disable scrolling
			document.body.style.overflow = "hidden";
		} else {
			// Enable scrolling
			document.body.style.overflow = "auto";
		}

		// Cleanup on component unmount
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isModalOpen]);

	const activeStyle = {
		color: "#9835D6",
		borderBottom: "2px solid #9835D6",
	};

	const handleAddUserClick = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};
	return (
		<div className="w-full  bg-white px-2 sm:px-4 rounded-xl pt-5 sm:pt-10 ">
			<Tabs.Root
				className="TabsRoot "
				defaultValue="tab1"
				onValueChange={(value) => setActiveTab(value)}
			>
				<div className="flex flex-col sm:justify-between sm:items-center  sm:flex-row gap-2 ">
					<Tabs.List
						className="space-x-5 flex flex-row  border-b-2 w-5/6 sm:text-lg font-bold"
						aria-label="Manage"
					>
						<Tabs.Trigger
							style={activeTab === "tab1" ? activeStyle : {}}
							className="hover:text-primary_color cursor-pointer text-grey"
							value="tab1"
						>
							Verified
						</Tabs.Trigger>
						<Tabs.Trigger
							style={activeTab === "tab2" ? activeStyle : {}}
							className="hover:text-primary_color cursor-pointer text-grey"
							value="tab2"
						>
							Unverified
						</Tabs.Trigger>
					</Tabs.List>
					<div
						className="rounded-[5px] border border-1 p-2  border-grey cursor-pointer w-24"
						// className="md:border  border px-2 text-center    md:p-1 md:rounded-xl border-grey cursor-pointer"
						onClick={handleAddUserClick}
					>
						<h1 className=" font-bold text-xs  text-grey ">
							{/* <h1 className=" font-bold text-[12px] md:text-base 2xl:text-2xl text-grey "> */}
							+ Add User
						</h1>
					</div>
				</div>

				<Tabs.Content className="" value="tab1">
					<div>
						<Verified />
					</div>
				</Tabs.Content>
				<Tabs.Content className="" value="tab2">
					<div>
						<UnVerified />
					</div>
				</Tabs.Content>
			</Tabs.Root>

			{isModalOpen && (
				<div
					style={{
						position: "fixed",
						inset: "0",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: "50",
					}}
				>
					<div>
						<CreateDetails onClose={handleCloseModal} />
					</div>
				</div>
			)}
		</div>
	);
};
export default UserPc;
