import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Confirm from '../../confirmcreatemodal/confirm'; // Assuming Confirm is in the same folder

const Create = ({ onClose, onSave }) => {
  const token = useSelector(state => state.login.logintoken); // Access token from Redux
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(''); // Store selected color
  const [showConfirm, setShowConfirm] = useState(false); // State for the confirm modal
  const [isFormValid, setIsFormValid] = useState(false); // State for form validation

  const [formData, setFormData] = useState({
    name: '',
    currency: '', // Empty by default, user will input
    description: '',
    idealFor: '',
    keyFeatures: '',
    trialDuration: 0,
    application: 'MyStarWeb', // Autofilled with MyStarWeb
    maxStudentCapacity: '',
    monthlyAmount: '',
    annualAmount: '',
    colorCode: ''
  });

  const colors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];

  const toggleColorPicker = () => {
    setOpenColorPicker(!openColorPicker);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setFormData({ ...formData, colorCode: color });
    setOpenColorPicker(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Format the amount fields with commas while typing
    if (name === 'monthlyAmount' || name === 'annualAmount') {
      const formattedValue = formatNumber(value);
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Helper function to format number with commas
 const formatNumber = (value) => {
  if (value === '') {
    return ''; // Return an empty string if the input is empty
  }

  const numberValue = value.replace(/,/g, ''); // Remove commas

  if (!isNaN(numberValue)) {
    return parseFloat(numberValue).toLocaleString(); // Add commas back if it's a valid number
  }

  return value; // Return the original value if it's not a valid number
};

  // Form validation to check if required fields are filled
  useEffect(() => {
    const validateForm = () => {
      const requiredFields = [
        formData.name,
        formData.currency,
        formData.description,
        formData.idealFor,
        formData.keyFeatures,
        formData.maxStudentCapacity,
        formData.monthlyAmount,
        formData.annualAmount,
        formData.colorCode,
      ];

      // Check if all required fields are filled
      setIsFormValid(requiredFields.every(field => field !== ''));
    };

    validateForm();
  }, [formData]);

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form behavior
    setShowConfirm(true); // Show confirm modal when the form is submitted
  };

  const handleConfirmSubmit = async () => {
    setShowConfirm(false); // Close the confirm modal after confirmation

    try {
      // Prepare data before submission: uppercase package name and currency
      const processedFormData = {
        ...formData,
        name: formData.name.toUpperCase(), // Convert package name to uppercase
        currency: formData.currency.toUpperCase(), // Convert currency to uppercase
        monthlyAmount: parseInt(formData.monthlyAmount.replace(/,/g, ''), 10) * 100, // Multiply by 100 and remove commas
        annualAmount: parseInt(formData.annualAmount.replace(/,/g, ''), 10) * 100, // Multiply by 100 and remove commas
      };

      // Make API post call with Authorization header
      const response = await axios.post(
        'https://api.mystarsonline.com/api/Subscription/create-WebPlan',
        processedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}` // Attach token from Redux
          }
        }
      );

      if (response.status === 200) {
        // Show success toast
        toast.success("Plan created successfully!");

        // Close the modal after the success toast
        onSave(); // Call onSave to handle form submission
        onClose(); // Close the modal
      }
    } catch (error) {
      console.error("Error creating plan:", error);

      // Show error toast
      toast.error("Error creating plan. Please try again.");
    }
  };

  return (
    <div className="bg-white rounded-2xl p-6 max-w-2xl mx-auto shadow-md h-auto">
      <div className="flex justify-end">
        <span className="bg-primary_color rounded-full p-2 cursor-pointer inline-flex items-center justify-center">
          <AiOutlineClose className="text-white" onClick={onClose} />
        </span>
      </div>

      <div className="mb-3">
        <p className="text-center text-2xl font-bold text-primary_color">Create A New Package</p>
        <p className="text-center text-sm font-bold text-black pt-2">Create a new Subscription Package</p>
      </div>

      <form onSubmit={handleFormSubmit} className="space-y-4 max-h-[60vh] overflow-y-auto scrollbar-thin scrollbar-thumb-primary_color scrollbar-track-white">
        <div className="flex space-x-8 flex-row items-center">
          <div className='w-[340px]'>
            <label className="block text-sm font-semibold text-black mb-1">Package Name</label>
            <div className='border border-black rounded-xl'>
              <input 
                type="text" 
                className="w-full p-2" 
                placeholder="Enter Package Name" 
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='flex space-x-3 items-center pt-5'>
              <div>Package Color:</div>
              <span className='flex text-center items-center bg-gray-200 px-2 rounded-2xl space-x-2 cursor-pointer' onClick={toggleColorPicker}>
                <GrStatusGoodSmall style={{ color: selectedColor }} />
                <span>{selectedColor || "Color"}</span>
              </span>
            </div>
            {openColorPicker && (
              <div className="flex space-x-2 pt-3 ">
                {colors.map(color => (
                  <span 
                    key={color}
                    style={{ backgroundColor: color }}
                    className="w-4 h-4 rounded-full cursor-pointer"
                    onClick={() => handleColorSelect(color)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Currency</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Currency (e.g., NGN, USD)"
              name="currency"
              value={formData.currency}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Description</label>
          <div className='border border-black rounded-xl'>
            <textarea
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows='1'
              style={{ overflow: 'hidden' }}
              onInput={(e) => {
                e.target.style.height = 'auto'; // Reset the height
                e.target.style.height = `${e.target.scrollHeight}px`; // Set it to scrollHeight
              }}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Ideal For</label>
          <div className='border border-black rounded-xl'>
            <textarea 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Ideal For"
              name="idealFor"
              value={formData.idealFor}
              onChange={handleInputChange}
              rows='1'
              style={{ overflow: 'hidden' }}
              onInput={(e) => {
                e.target.style.height = 'auto'; // Reset the height
                e.target.style.height = `${e.target.scrollHeight}px`; // Set it to scrollHeight
              }}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Key Features</label>
          <div className='border border-black rounded-xl'>
            <textarea 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Key Features"
              name="keyFeatures"
              value={formData.keyFeatures}
              onChange={handleInputChange}
              rows='1'
              style={{ overflow: 'hidden' }}
              onInput={(e) => {
                e.target.style.height = 'auto'; // Reset the height
                e.target.style.height = `${e.target.scrollHeight}px`; // Set it to scrollHeight
              }}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Trial Duration (Days)</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Trial Duration"
              name="trialDuration"
              value={formData.trialDuration}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Application</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              name="application"
              value={formData.application}
              readOnly // Autofilled with MyStarWeb
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Max Student Capacity</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter student capacity eg 50 , 10"
              name="maxStudentCapacity"
              value={formData.maxStudentCapacity}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Monthly Amount</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Monthly Amount"
              name="monthlyAmount"
              value={formData.monthlyAmount}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='w-[340px]'>
          <label className="block text-sm font-semibold text-black mb-1">Annual Amount</label>
          <div className='border border-black rounded-xl'>
            <input 
              type="text" 
              className="w-full p-2" 
              placeholder="Enter Annual Amount"
              name="annualAmount"
              value={formData.annualAmount}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-3 mt-4">
          <button
            type="submit"
            className={`cursor-pointer bg-primary_color text-white px-3 py-1 rounded-xl ${!isFormValid && 'opacity-50 cursor-not-allowed'}`}
            disabled={!isFormValid}
          >
            Create
          </button>
          <span className='border rounded-xl border-primary_color'>
            <button type="button" className="cursor-pointer text-black px-3 py-1" onClick={onClose}>Cancel</button>
          </span>
        </div>
      </form>

      {showConfirm && (
        <Confirm
          onClose={() => setShowConfirm(false)}
          onConfirm={handleConfirmSubmit}
        />
      )}

      {/* ToastContainer to show toasts */}
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default Create;
