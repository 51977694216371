// src/components/GlobalSearchHandler.js

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GlobalSearchHandler = () => {
  const searchQuery = useSelector((state) => state.globalSearch.query);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
  
      // Example redirection logic
      if (lowerCaseQuery.includes('john')) {
        navigate('/admin/user');
      } else if (lowerCaseQuery.includes('package')) {
        navigate('/admin/package');
      } else if (lowerCaseQuery.includes('ticket')) {
        navigate('/admin/ticket');
      } else if (lowerCaseQuery.includes('report')) {
        navigate('/admin/report');
      }
    }
  }, [searchQuery, navigate]);
  

  return null; // This component doesn't need to render anything
};

export default GlobalSearchHandler;
