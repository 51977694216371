import About from "../../../components/pages/startracker/about";
import StarHero from "../../../components/pages/startracker/hero";

const StarTracker = () => {
  return (
    <>
      <div className=" w-full h-full bg-white ">
        <StarHero/>
        <About/>
      </div>
    </>
  );
};

export default StarTracker;
