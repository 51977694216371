import StartForm from "../../../components/pages/sign_up/start_form.component";
import img from "../../../assets/images/graduands.png"
const SignUpStart = () => {
    return (
        <>
             <div className="w-full h-full flex items-start lg:items-center justify-evenly min-[1025px]:items-start  " >
            <div className=" w-full left-0 relative  flex items-center justify-center md:left-0 md:relative md:w-full lg:absolute lg:z-10 lg:w-2/5 lg:left-4 min-[1281px]:top-1/4" style={{
            }} >
                   <StartForm />
            </div>
            <div className=" hidden md:hidden lg:block" style={{
            }}>
                <img src={img} className="w-full relative min-[1024px]:h-fit max-[ 1280px]:h-fit max-[1280px]:-bottom-14  xl:object-contain min-[1281px]:-bottom-20 min-[1281px]:h-777px min-[1281px]:absolute min-[1281px]:left-0"  alt="signup" />
            </div>
        </div>
        </>
    );
}

export default SignUpStart;