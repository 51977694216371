import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    query: '',       // The search query entered by the user
    results: [],     // The search results fetched from the API
    redirectTo: null // The page the user should be redirected to based on the search
};

const globalSearchSlice = createSlice({
    name: 'globalSearch',
    initialState,
    reducers: {
        setSearchQuery: (state, action) => {
            state.query = action.payload;
        },
        setSearchResults: (state, action) => {
            state.results = action.payload;
        },
        setRedirectTo: (state, action) => {
            state.redirectTo = action.payload;
        },
        clearSearch: (state) => {
            state.query = '';
            state.results = [];
            state.redirectTo = null;
        }
    }
});

export const { setSearchQuery, setSearchResults, setRedirectTo, clearSearch } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
