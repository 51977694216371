import React from 'react';

const OptionFeatures = ({ options }) => {
    const columnWidths = {
        "S/N": "10%",
        "Title": "40%",
        "Status": "30%",
        "Action": "20%"
    };

    return (
        <div className="bg-primary_color mt-4 rounded-xl p-3 flex text-sm justify-between w-full">
            {options.map(({ option }, index) => (
                <div 
                    key={index} 
                    style={{ width: columnWidths[option] || '15%' }} 
                    className={`option-tab-item text-sm flex items-center`}
                >
                    <p className="text-white text-sm font-bold flex items-center">
                        {option}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default OptionFeatures;
