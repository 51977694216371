const Privacy = () => {
    return (
        <>

            <div className="px-10 w-full h-full md:px-24 py-6 pt-20">
                <div className="w-full mt-5 flex justify-center items-center " >
                    <h1 className={"text-4xl text-c text-black font-bold"} >Privacy Policy</h1>
                </div>

                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        Introduction
                    </p>
                    <p className="text-sm leading-7 mt-2 text-black font-medium ">
                        Welcome to MyStar! This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information when you use our mobile application, available on the Google Play Store and Apple App Store (hereinafter referred to as "the App").
                        By using the App, you consent to the practices described in this Privacy Policy. Please take a moment to read this Privacy Policy carefully to ensure that you fully understand it. If you do not agree with our practices, please do not use the App.
                    </p>
                </div>
                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        Information We Collect
                    </p>
                    <div className="w-full mt-2" >
                        <p className=" text-base leading-6 text-black font-bold mt-2 ">
                            2.1 Personal Information
                        </p>
                        <p className="text-sm text-black font-normal mt-2" > We may collect the following types of personal information from users of the App:
                        </p>
                        <ul className="w-full mt-2" >
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >Name
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                Email address

                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                Phone number
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                Date of birth
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                Profile picture
                            </li>
                        </ul>
                    </div>
                    <div className="w-full mt-2" >
                        <p className=" text-base leading-6 text-black font-bold mt-2 ">
                            2.2 Non-Personal Information
                        </p>
                        <p className="text-sm text-black font-normal mt-2" > In addition to personal information, we may also collect non-personal information such as:
                        </p>
                        <ul className="w-full mt-2" >
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >Device information (e.g., device type, operating system version)
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                Usage data (e.g., pages visited within the App, time spent on the App)

                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                Location information (only if you enable location services)
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        How We Use Your Information
                    </p>
                    <div className="w-full mt-2" >
                        <p className="text-sm text-black font-normal mt-2" > We may use your personal and non-personal information for the following purposes:
                        </p>
                        <ul className="w-full mt-2" >
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >To provide you with the services and features of the App.
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                To personalize and improve your experience on the App.

                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                To respond to your requests, comments, or questions.
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                To send you important updates and notifications related to the App.
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                To monitor and analyze the usage of the App.
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                To comply with legal obligations or protect our rights and interests.
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        Disclosure of Your Information
                    </p>
                    <div className="w-full mt-2" >
                        <p className="text-sm text-black font-normal mt-2" > We may share your personal information with third parties under the following circumstances:
                        </p>
                        <ul className="w-full mt-2" >
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >With your consent.
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                To comply with legal obligations or in response to a lawful request.

                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                To protect the rights, privacy, safety, or property of [School Mobile App Name], its users, or the public.
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                In connection with a merger, acquisition, or sale of all or a portion of our assets.
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        Security
                    </p>
                    <div className="w-full mt-2" >
                        <p className="text-sm text-black font-normal mt-2" >We take the security of your information seriously and implement reasonable measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of data transmission or storage is 100% secure, and we cannot guarantee absolute security.
                        </p>
                    </div>
                </div>
                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        Children's Privacy
                    </p>
                    <div className="w-full mt-2" >
                        <p className="text-sm text-black font-normal mt-2" >Our App is intended for use by individuals over the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have inadvertently collected personal information from a child under 13, please contact us, and we will promptly delete such information.
                        </p>
                    </div>
                </div>

                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        Your Choices
                    </p>
                    <div className="w-full mt-2" >
                        <p className="text-sm text-black font-normal mt-2" >You can exercise certain rights and choices regarding your personal information, including:
                        </p>
                        <ul className="w-full mt-2" >
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >Accessing and updating your personal information.
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                Deleting your personal information (subject to applicable legal requirements).

                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                Opting out of receiving promotional emails or notifications.
                            </li>
                            <li className=" list-disc left-5 relative text-black text-sm mb-3 " >
                                To exercise these rights or make choices about your personal information, please contact us using the contact information provided below.
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        Changes to this Privacy Policy
                    </p>
                    <div className="w-full mt-2" >
                        <p className="text-sm text-black font-normal mt-2" >We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated Privacy Policy on our website or within the App, along with the effective date of the changes.
                        </p>
                    </div>
                </div>
                <div className="w-full mt-6" >
                    <p className="text-xl text-black font-bold " >
                        Contact Us
                    </p>
                    <div className="w-full mt-2" >
                        <p className="text-sm text-black font-normal mt-2" >If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at:</p>
                        <p className="text-sm text-black font-normal mt-2" >[Contact Information]</p>
                        <p className="text-sm text-black font-normal mt-2" >Thank you for using [School Mobile App Name]! Your privacy and trust are essential to us, and we are committed to protecting your personal information.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Privacy;