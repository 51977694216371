import ProfileImage from "./profle_image.component";

const ProfileDetails = () => {
    return (
        <>
            <div className=" w-full bg-soft_grey rounded-md flex items-start justify-between "   >
                <ProfileImage />
                <div className="w-3/4 pt-3 px-9 " >
                    <div className="w-full mb-1" >
                        <p className=" text-primary_color text-sm text-left" > Overview </p>
                    </div>


                    <div className="w-full flex items-center justify-evenly mb-4" >
                        <div className="w-1/2" >
                            <p className=" text-base font-bold text-left text-black" > Mary Employee </p>
                        </div>
                        <div className="w-1/2 flex items-center justify-start gap-20" >
                            <p className=" font-medium text-sm text-ash" >Bg</p>
                            <p className=" font-bold text-sm text-black" >A+</p>
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-evenly mb-4 " >
                        <div className="w-1/2" >
                            <p className=" font-bold text-sm text-ash">SMS021, Female </p>
                        </div>
                        <div className="w-1/2 flex items-center justify-start gap-7" >
                            <p className=" font-medium text-sm text-ash">Genotype</p>
                            <p className=" font-bold text-sm text-black">AA</p>
                        </div>
                    </div>

                    <div className="w-full flex items-center justify-evenly mb-4 " >
                        <div className="w-1/2 flex items-center justify-start gap-5">
                            <p className=" font-medium text-sm text-ash m-0 p-0">Class</p>
                            <p className=" font-bold text-sm text-black m-0 p-0 ">Grade 1</p>
                        </div>
                        <div className="w-1/2 flex items-center justify-start gap-16" >
                            <p className=" font-medium text-sm text-ash">D.0.B</p>
                            <p className=" font-bold text-sm text-black">12/12/2008</p>
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-evenly mb-4 " >
                        <div className="w-1/2 flex items-center justify-start gap-5">
                            <p className=" font-medium text-sm text-ash m-0 p-0">Arm</p>
                            <p className=" font-bold text-sm text-black m-0 p-0 ">Arm 1</p>
                        </div>
                        <div className="w-1/2 flex items-center justify-start gap-14" >
                            <p className=" font-medium text-sm text-ash">Email</p>
                            <p className=" font-bold text-sm text-black">Mary@gmail.com</p>
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-evenly mb-4 " >
                        <div className="w-1/2 flex items-center justify-start gap-5">
                            <p className=" font-medium text-sm text-ash m-0 p-0">Phone</p>
                            <p className=" font-bold text-sm text-black m-0 p-0 ">08146172428</p>
                        </div>
                        <div className="w-1/2 flex items-center justify-start gap-10" >
                            <p className=" font-medium text-sm text-ash">Address</p>
                            <p className=" font-bold text-sm text-black">F01, Kubwa, Abuja</p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProfileDetails;