import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../../common/button/button.component";
import { LockIcon, MailIcon } from "../../common/icons/icons";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { HiOutlineMail } from "react-icons/hi";
import { CiLock } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

const ResetForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to track button loading

  const navigate = useNavigate();

  // Get the email and token from the Redux store
  const email = useSelector((state) => state.forgetPassword.forgetPasswordEmail);
  const token = useSelector((state) => state.otpVerification.verificationData?.token || '');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading to true when request starts

    if (!token) {
      toast.error("Token is missing. Please try again.");
      setIsLoading(false); // Return button to normal state

      return;
      
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post('https://api.mystarsonline.com/api/Auth/ResetPassword', {
        email,
        token,
        newPassword,
        confirmPassword,
      });

      const message = response.data.message || "Password Reset Successfully";

      toast.success(message);

      setTimeout(() => {
        navigate('/login');
      }, 2000);

    } catch (error) {
      const errorMessage = error.response?.data?.message || "Password reset failed";
      toast.error(errorMessage);
      setIsLoading(false); // Return button to normal state

    }
    setIsLoading(false); // Return button to normal state

  };

  return (
    <>
   <ToastContainer position='top-center' autoClose={2000} theme='light' pauseOnHover={false}   />  
      <div className=''>
        <form className="bg-[#616161] shadow-2xl opacity-60 rounded-xl py-7 px-8 border md:py-7 md:px-8 xl:py-8 xl:px-10 2xl:py-20 2xl:px-20" onSubmit={handleSubmit}>
          <div className="flex flex-col justify-center text-center pt-5 mb-2 md:pt-0">
            <h4 className="font-bold text-center mb-6 text-2xl md:text-2xl xl:text-3xl 2xl:text-5xl text-[#fff]">Reset Password</h4>
            <span className='flex flex-col justify-center text-[#fff] font-thin text-base md:text-base xl:text-lg 2xl:text-3xl'>
              <p>Please Choose Your </p>
              <p>New Password</p>
            </span>
          </div>
          <div className="w-full flex items-center flex-col mb-2 mt-4 space-y-4 md:space-y-4 xl:space-y-3 2xl:space-y-6">
            <div className="w-full">
              <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                <div className="w-1/6 2xl:text-3xl">
                  <HiOutlineMail color="black" />
                </div>
                <div className="w-full">
                  <input
                    type="email"
                    value={email}
                    readOnly
                    className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px] bg-transparent"
                    placeholder="Email Address"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                <div className="w-1/6 2xl:text-3xl">
                  <CiLock color="black" />
                </div>
                <div className="w-full flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px] bg-transparent"
                    placeholder="Enter New Password"
                    required
                  />
                  <div className="cursor-pointer ml-2 2xl:text-3xl" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaRegEyeSlash color="black" /> : <FaRegEye color="black" />}
                  </div>
                </div>
              </div>
              <div className="flex flex-col text-xs 2xl:text-[18px] 2xl:pt-4 text-[#fff] mt-1">
                <span>
                  Password must contain at least one capital letter,
                </span>
                <span>
                  one number, one special character, and be 8 characters long.
                </span>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                <div className="w-1/6">
                  <CiLock color="black" />
                </div>
                <div className="w-full flex items-center">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px] bg-transparent"
                    placeholder="Confirm Password"
                    required
                  />
                  <div className="cursor-pointer ml-2" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <FaRegEyeSlash color="black" /> : <FaRegEye color="black" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Button with spinner */}
          <div className="w-full flex items-center justify-center pt-3 mb-1 cursor-pointer">
                        <div className={`w-full border border-primary_color rounded-xl p-1 text-sm flex items-center text-center text-primary_color font-semibold transition-all duration-300 md:text-sm xl:text-base 2xl:text-xl ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-white hover:bg-black hover:text-white'}`}>
                            <Button
                                type="submit"
                                className="w-full"
                                btnTitle={isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <svg className="animate-spin h-5 w-5 text-primary_color" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0h-2a6 6 0 00-12 0H4z"></path>
                                        </svg>
                                        <span>Loading...</span>
                                    </div>
                                ) : "Proceed"}
                                disabled={isLoading} // Disable button when loading
                            />
                        </div>
                    </div>
        </form>
      </div>
    </>
  );
}

export default ResetForm;
