import React from 'react';

const AssignmentHistory = () => {
    const subjects = [
        'English',
        'Math',
        'Science',
        'Biology',
        'Logic',
        'Verbal Reasoning',
        'ICT',
        'Art',
        'Statistics',
        'History',
    ];
    const completed = [
        '4',
        '3',
        '5',
        '3',
        '6',
        '2',
        '4',
        '1',
        '5',
        '3',
    ];
    const pending = [
        '0',
        '1',
        '0',
        '0',
        '0',
        '1',
        '1',
        '2',
        '0',
        '0',
    ];



    return (
        <>
            <div className=" w-2/5 rounded-md bg-soft_grey p-4">
                <div className="w-full">
                    <p className="text-primary_color text-left font-bold text-lg">Progress Report</p>
                </div>

                <div className='w-full mt-4' >
                    <div className='w-full flex items-center justify-between' >
                        <p className=' text-black text-base font-semibold' >Subject</p>
                        <p className=' text-black text-base font-semibold' >Completed</p>
                        <p className=' text-black text-base font-semibold' >Pending</p>
                    </div>
                    <div className='w-full flex items-center justify-between' >
                        <div className=' w-1/3' >
                            <ul className='p-0 m-0 w-full flex flex-col items-start ' >
                                {subjects.map((item, index) => (
                                    <li className=' list-none text-sm mt-3' key={index} >{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className=' w-1/3' >
                            <ul className='p-0 m-0 w-full flex flex-col items-center' >
                                {completed.map((item, index) => (
                                    <li className=' list-none text-sm mt-3 ' key={index} >{item}</li>

                                ))}
                            </ul>
                        </div>
                        <div className=' w-1/3' >
                            <ul className='p-0 m-0 w-full flex flex-col items-center' >
                                {pending.map((item, index) => (
                                    <li className=' list-none text-sm mt-3' key={index} >{item}</li>

                                ))}
                            </ul>
                        </div>

                    </div>


                </div>

            </div>
        </>
    );
};

export default AssignmentHistory;




