import React, { useEffect } from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const Confirm = ({ onClose, onConfirm }) => {
  useEffect(() => {
    // Prevent scrolling on the parent element
    document.body.style.overflow = 'hidden';

    return () => {
      // Re-enable scrolling when the modal is closed
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full shadow-lg animate-fade-in">
        <div className="flex justify-center mb-4">
          <AiOutlineExclamationCircle className="text-primary_color text-4xl" />
        </div>
        <div className="text-center">
          <p className="text-lg font-semibold text-black">New Subscription Package!</p>
          <p className="text-sm text-gray-600 mt-2">
            Are you sure you want to create a new package? 
          </p>
        </div>
        <div className="flex justify-between items-center mt-6">
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 font-semibold focus:outline-none cursor-pointer"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-primary_color cursor-pointer text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-opacity-90 focus:outline-none transition-all duration-300 group"
          >
            <span>Yes</span>
            <span className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2">→</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
