import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../../common/button/button.component";
import { CloseIcon } from "../../../common/icons/icons";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks.ts";
import { clearSessionState, editSession, editTitleHandler, editStartDateHandler, editEndDateHandler } from "../../../../redux/slices/session_slice.ts";

const EditSession = ({ setOpenEditor, title, startDate, endDate, index }) => {
    const [edit, setEdit] = useState(false)
    const dispatch = useAppDispatch();
    const editTitle = useAppSelector(({ session }) => session.edit_title)
    const editStartDate = useAppSelector(({ session }) => session.edit_start_date)
    const editEndDate = useAppSelector(({ session }) => session.edit_end_date)

    const titleCallBack = useCallback((e) => {
        dispatch(editTitleHandler(e.target.value))
    }, [editTitle])
    const startDateCallBack = useCallback((e) => {
        dispatch(editStartDateHandler(e.target.value))
    }, [editStartDate])
    const endDateCallBack = useCallback((e) => {
        dispatch(editEndDateHandler(e.target.value))
    }, [editEndDate])
    const handleEdit = () => {
        const data = { title: editTitle, start_date: editStartDate, end_date: editEndDate }
        dispatch(editSession({ id: index, newSession: data }))
        dispatch(clearSessionState())
        setOpenEditor(false)
    }

    useEffect(() => {
        if (editTitle !== title || editStartDate !== startDate || editEndDate !== endDate) {
            setEdit(true)
        }
    }, [title, startDate, endDate, index, editTitle, editStartDate, editEndDate])
    return (
        <>
            <div className="bg-white rounded-md py-3 lg:w-3/5 relative  shadow-md " >
                <div className="p-2 flex items-center justify-center absolute rounded-50% top-4 right-10 bg-primary_color" >
                    <CloseIcon cursor={'pointer'} color={'white'} onClick={() => {
                        setOpenEditor(false)
                    }} />
                </div>
                <div className="w-full my-7" >
                    <h3 className="text-xl font-bold text-primary_color text-center" >Edit Session</h3>
                    <p className="text-lg font-bold text-black text-center" >Edit new session for your school</p>
                </div>
                <div className="w-full flex items-center justify-start gap-2  px-9 " >
                    <h3 className="text-lg font-bold text-primary_color text-left" >Session Data</h3>
                    <hr style={{ height: ".7px" }} className="flex-1 flex  bg-primary_color border-none" />
                </div>
                <div className="w-full px-9 mt-5 flex items-center justify-evenly gap-3" >
                    <div className=" w-1/2" >
                        <label className=" text-sm font-semibold text-black" >Session Title</label>
                        <input value={editTitle} onChange={(e) => titleCallBack(e)} style={{ border: '1px solid #e3e3e3' }} className="w-full rounded-md p-3  outline-none " />
                    </div>
                    <div className=" w-1/4" >
                        <label className=" text-sm font-semibold text-black" >Start Date</label>
                        <input value={editStartDate} onChange={(e) => startDateCallBack(e)} style={{ border: '1px solid #ededed' }} type="date" className="w-full rounded-md p-3  outline-none " />
                    </div>
                    <div className=" w-1/4" >
                        <label className=" text-sm font-semibold text-black" >End Date</label>
                        <input value={editEndDate} onChange={(e) => endDateCallBack(e)} style={{ border: '1px solid #ededed' }} type="date" className="w-full rounded-md p-3  outline-none " />
                    </div>
                </div>
                <div className="mt-40 mb-5 px-9 flex items-center justify-end gap-2 " >
                    <Button onClick={() => { handleEdit() }} disabled={!edit} btnTitle={'Save'} className={`w-28 p-2 ${edit ? "bg-primary_color" : "bg-grey"} rounded text-sm text-white`} />
                    <Button btnTitle={'Cancel'} btnColor={'bg-white'} className={'w-28 p-2 bg-white rounded border border-primary_color text-sm '} onClick={() => {
                        setOpenEditor(false)
                        dispatch(clearSessionState())
                    }} />
                </div>
            </div>
        </>
    );
}

export default EditSession;