import ProfileImage from "../../parent/profile/profle_image.component";


const StudentProfileTab = () => {
    return (
        <>
            <div className=" w-3/4 bg-soft_grey rounded-md flex items-stretch justify-between  "   >
                <ProfileImage />
                <div className=" w-5/6 pt-3 px-9 flex items-stretch justify-between gap-10 " >
                    <div className="w-3/5" >
                        <div className="w-full mb-1" >
                            <p className=" text-primary_color text-sm text-left" > Overview </p>
                        </div>
                        <div className="w-full flex items-center justify-evenly mb-4" >
                            <div className="w-1/2" >
                                <p className=" text-base font-extrabold text-left text-black" > Mary Student </p>
                            </div>
                            <div className="w-1/2 flex items-center justify-start gap-14" >
                                <p className=" font-medium text-xs text-ash" >Bg</p>
                                <p className=" font-bold text-xs text-black" >A+</p>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-evenly mb-4 " >
                            <div className="w-1/2" >
                                <p className=" font-bold text-xs text-ash">SMS021, Female </p>
                            </div>
                            <div className="w-1/2 flex items-center justify-start gap-3" >
                                <p className=" font-medium text-xs text-ash">Genotype</p>
                                <p className=" font-bold text-xs text-black">AA</p>
                            </div>
                        </div>

                        <div className="w-full flex items-center justify-evenly mb-4 " >
                            <div className="w-1/2 flex items-center justify-start gap-5">
                                <p className=" font-medium text-xs text-ash m-0 p-0">Class</p>
                                <p className=" font-bold text-xs text-black m-0 p-0 ">Grade 1</p>
                            </div>
                            <div className="w-1/2 flex items-center justify-start gap-9" >
                                <p className=" font-medium text-xs text-ash">D.0.B</p>
                                <p className=" font-bold text-xs text-black">12/12/2008</p>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-evenly mb-4 " >
                            <div className="w-1/2 flex items-center justify-start gap-5">
                                <p className=" font-medium text-xs text-ash m-0 p-0">Arm</p>
                                <p className=" font-bold text-xs text-black m-0 p-0 ">Arm 1</p>
                            </div>
                            <div className="w-1/2 flex items-center justify-start gap-9" >
                                <p className=" font-medium text-xs text-ash">Email</p>
                                <p className=" font-bold text-xs text-black">Mary@gmail.com</p>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-evenly mb-4 " >

                        </div>
                    </div>


                    <div className="w-2/5 px-3  border-l" >
                        <div className="w-full mb-1" >
                            <p className=" text-primary_color text-sm text-left" > Parent/Gurdian </p>
                        </div>
                        <div className="w-full mt-4  mb-4 " >
                            <div className="w-full flex items-center justify-start gap-5 mb-4 ">
                                <p className=" font-medium text-xs text-ash m-0 p-0">Name</p>
                                <p className=" font-bold text-xs text-black m-0 p-0 ">John Parent</p>
                            </div>
                            <div className="w-full flex items-center justify-start gap-5 mb-4" >
                                <p className=" font-medium text-xs text-ash">Address</p>
                                <p className=" font-bold text-xs text-black">F01, Kubwa, Abuja</p>
                            </div>
                            <div className="w-full flex items-center justify-start gap-5">
                                <p className=" font-medium text-xs text-ash m-0 p-0">Phone</p>
                                <p className=" font-bold text-xs text-black m-0 p-0 ">08146172428</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default StudentProfileTab;