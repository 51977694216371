import { useState } from "react";
// import Modal from "../../../components/common/modal/modal.component";
// import ProfileDetails from "../../../components/pages/superAdmin/profile/profile_details.component";
// import ProfileTable from "../../../components/pages/superAdmin/profile/profile_table.component";
// import TitleBar from "../../../components/pages/superAdmin/title_bar.component";
import { IoIosArrowRoundForward } from "react-icons/io"
import TitleBar from "../../../components/pages/superAdmin/title_bar.component";
import ProfileDetails from "../../../components/pages/parent/profile/profile_details.component";
import ProfileTable from "../../../components/pages/parent/profile/profile_table.component";
import Modal from "../../../components/common/modal/modal.component";
import UpdateProfile from "../../../components/pages/parent/profile/update_profile.component";
import ResetPassword from "../../../components/pages/parent/profile/reset_password.component";
// import UpdateProfile from "../../../components/pages/superAdmin/profile/update_profile.component";
// import ResetPassword from "../../../components/pages/superAdmin/profile/reset_password.component";
const ParentProfilePage = () => {
    const [updateProfileModal, setUpdateProfileModal] = useState(false)
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    const Actions = [
        {
            name: "Update Profile",
            color: "#A63DF6",
            operation: () => {
                setUpdateProfileModal(true)
            }

        },
        {
            name: "Reset Password",
            color: "#D126FC",
            operation: () => {
                setResetPasswordModal(true)
            }

        },
    ]
    return (
        <>
            <div className="w-full my-5" >
                <TitleBar title={'Profile Details'} />

                <div className="w-full flex items-start justify-between mt-4 gap-5 " >
                    <div className=" w-3/4" >
                        <ProfileDetails />
                        <ProfileTable />
                    </div>
                    <div className=" w-1/4 " >
                        <div className="w-full " >
                            {Actions.map(({ name, color, operation }, index) => (
                                <div
                                    onClick={operation}
                                    key={index}
                                    style={{ backgroundColor: color }}
                                    className={` w-full md:w-2/5 lg:w-64 rounded-md p-5 mb-4 relative cursor-pointer`}
                                >
                                    <p className="text-white text-sm uppercase">{name}</p>
                                    <div className="w-full flex justify-end items-center mt-12 bottom-0 relative">
                                        <IoIosArrowRoundForward
                                            color="white"
                                            size={30}
                                            cursor={"pointer"}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Modal showModal={updateProfileModal} setShowModal={setUpdateProfileModal} >
                <UpdateProfile setShowModal={setUpdateProfileModal} />
            </Modal>
            <Modal showModal={resetPasswordModal} setShowModal={setResetPasswordModal} >
                <ResetPassword setShowModal={setResetPasswordModal} />
            </Modal>
        </>
    );
}

export default ParentProfilePage;