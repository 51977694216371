import { IoCaretBackOutline, IoCaretForwardOutline } from "react-icons/io5";

const Paginator = ({ currentPage, totalPages, totalItems, onNextPage, onPrevPage, pageSize }) => {
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems); // Ensure we don't go beyond total items

  return (
    <>
      <div style={{ justifyContent: "flex-end" }} className="w-full flex flex-row items-center gap-3 mt-8 mb-20">
        <div
          className="border border-primary_color p-1 rounded-md"
          onClick={onPrevPage}
          style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
        >
          <IoCaretBackOutline color="#9835D6" size={12} />
        </div>
        <div>
          <p className="text-sm text-black font-semi-bold">
            {startItem}-{endItem} of {totalItems}
          </p>
        </div>
        <div
          className="border border-primary_color p-1 rounded-md"
          onClick={onNextPage}
          style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
        >
          <IoCaretForwardOutline color="#9835D6" size={12} />
        </div>
      </div>
    </>
  );
};

export default Paginator;
