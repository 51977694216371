import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditModal from './edit_modal';

const EditDetails = ({ onClose, onSave, tenantData, tenantId }) => {
  // Initialize the states with the passed tenantData
  const [firstName, setFirstName] = useState(tenantData.firstName || '');
  const [lastName, setLastName] = useState(tenantData.lastName || '');
  const [middleName, setMiddleName] = useState(tenantData.middleName || ''); // Allows middleName to be empty
  const [schoolName, setSchoolName] = useState(tenantData.schoolName || '');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // State for confirmation modal

  const token = useSelector((state) => state.login.logintoken);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsConfirmModalOpen(true); // Open confirmation modal instead of directly submitting
  };

  const confirmEdit = async () => {
    // Construct the updated tenant details
    const updatedTenantDetails = {
      schoolName,
      firstName,
      lastName,
      middleName, // This will allow an empty string to be sent if middleName is cleared
    };

    try {
      const response = await axios.put(
        `https://api.mystarsonline.com/api/Signup/edit-tenant/${tenantId}`,
        updatedTenantDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.status) {
        toast.success(response.data.message || 'Tenant details updated successfully!', {
          onClose: () => {
            setTimeout(() => {
              onClose();
            }, 2000);
          },
        });
      } else {
        toast.error(response.data.message || 'Failed to update tenant details.', {
          onClose: () => {
            setTimeout(() => {
              onClose();
            }, 2000);
          },
        });
      }

      if (onSave) {
        onSave(response.data);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || 'An error occurred while updating tenant details.',
        {
          onClose: () => {
            setTimeout(() => {
              onClose();
            }, 2000);
          },
        }
      );
    } finally {
      setIsConfirmModalOpen(false); // Close the confirmation modal
    }
  };

  return (
    <>
      <div className="bg-white rounded-2xl p-6 mx-auto shadow-md h-auto 2xl:h-[46vh] 2xl:w-[50vh] 2xl:p-10">
        <div className="flex justify-end">
          <span
            onClick={onClose}
            className="bg-primary_color rounded-full p-2 cursor-pointer inline-flex items-center justify-center"
          >
            <AiOutlineClose className="text-white" />
          </span>
        </div>
        <div className="mb-3">
          <p className="text-center text-lg 2xl:text-3xl font-bold text-primary_color">Edit User Details</p>
          <p className="text-center text-sm 2xl:text-xl 2xl:pt-5 font-bold text-gray-700">Edit User Details</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4 2xl:space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 2xl:gap-10">
            <div>
              <label className="block text-sm 2xl:text-xl font-semibold text-black mb-1 2xl:mb-2">First Name</label>
              <div className="border border-black rounded-xl">
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  className="w-full p-2 2xl:p-3"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm 2xl:text-xl font-semibold text-black mb-1 2xl:mb-2">Last Name</label>
              <div className="border border-black rounded-xl">
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  className="w-full p-2 2xl:p-3"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 2xl:gap-10">
            <div>
              <label className="block text-sm 2xl:text-xl font-semibold text-black mb-1 2xl:mb-2">Middle Name</label>
              <div className="border border-black rounded-xl">
                <input
                  type="text"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  placeholder="Middle Name"
                  className="w-full p-2 2xl:p-3"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm 2xl:text-xl font-semibold text-black mb-1 2xl:mb-2">School Name</label>
              <div className="border border-black rounded-xl">
                <input
                  type="text"
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  placeholder="School Name"
                  className="w-full p-2 2xl:p-3"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-4">
            <button
              type="button"
              className="cursor-pointer bg-primary_color text-white px-6 py-1 rounded-xl"
              onClick={onClose}
            >
              Cancel
            </button>
            <div className="border border-primary_color px-6 py-1 cursor-pointer rounded-xl inline-block">
              <button type="submit" className="text-primary_color w-full h-full">
                Save
              </button>
            </div>
          </div>
        </form>
        <ToastContainer position="top-center" autoClose={2000} theme="light" pauseOnHover={false} />
      </div>

      {isConfirmModalOpen && (
        <div
          style={{
            position: 'fixed',
            inset: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 50,
          }}
        >
          <EditModal onConfirm={confirmEdit} onClose={() => setIsConfirmModalOpen(false)} />
        </div>
      )}
    </>
  );
};

export default EditDetails;
