import React from "react";
import { totalrevenue } from "./total_revenue";
import { VscTriangleUp } from "react-icons/vsc";
import { VscTriangleDown } from "react-icons/vsc";

function RevenueComponent() {
	const { currentMonth, previousMonth, change } = totalrevenue;
	const isPositiveChange = change.percentage >= 0;

	return (
		<div
			className="border rounded-2xl pl-4 lg:pl-10 flex flex-col justify-between  shadow-md  h-full w-full py-2 sm:py-10 col-span-1  text-start" // Fixed height of 354px and width of 460px using Tailwind CSS
			// className="border rounded-2xl px-16 flex flex-col justify-center shadow-md space-y-8 h-[354px] 2xl:h-[369px] w-full" // Fixed height of 354px and width of 460px using Tailwind CSS
		>
			<div className="text-sm font-bold  ">
				{/* <div className="text-sm 2xl:text-2xl font-medium text-gray-500"> */}
				{currentMonth.month} Revenue
			</div>
			<div className="flex flex-col justify-between  h-full gap-4 sm:gap-0 sm:py-10 my-5 sm:my-0">
				<div className="text-2xl font-bold mt-2">
					{currentMonth.revenue.toLocaleString()} {currentMonth.currency}
				</div>
				<div
					className={`text-4xl font-semibold mt-4 flex items-center ${
						//made the arrows same line as text
						isPositiveChange ? "text-green-600" : "text-red-600"
					}`}
				>
					{change.percentage}%{" "}
					<span className="h-full">
						{isPositiveChange ? <VscTriangleUp /> : <VscTriangleDown />}
					</span>
				</div>
			</div>
			<div className="w-full h-auto">
				<span className=""></span>
			</div>
			<div className="text-xs   text-gray-500 ">
				{/* <div className="text-sm 2xl:text-2xl font-medium text-gray-500 pt-5 border-t-2"> */}
				{previousMonth.month} Revenue
			</div>
			<div className="text-xl font-bold">
				{previousMonth.revenue.toLocaleString()} {previousMonth.currency}
			</div>
		</div>
	);
}

export default RevenueComponent;
