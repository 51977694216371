import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import Button from "../../common/button/button.component";
import { loginUser } from '../../../redux/slices/login_slice';
import { HiOutlineMail } from "react-icons/hi";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { setResubscribeToken } from '../../../redux/slices/resubscribe_slice'; 

const UserTypeForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state for button
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginError = useSelector((state) => state.login.error);

    // Get the resubscribe token from the Redux store
    const resubscribetoken = useSelector((state) => state.resubscribe.resubscribetoken);

    useEffect(() => {
        console.log('Resubscribe Token:', resubscribetoken);
    }, [resubscribetoken]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Start loading when the request is sent

        const resultAction = await dispatch(loginUser({ email, password }));
    
        if (resultAction.payload && resultAction.payload.code === 200) {
            const user = resultAction.payload.data;
    

             // Save the token to the resubscribe slice
             dispatch(setResubscribeToken(user.token)); // Assuming token is from user.token

            // Check for active subscription first
            if (user && user.hasActiveSubscription === false) {
                toast.error('You do not have an active subscription. Redirecting to pricing page.');
                setTimeout(() => navigate('/pricing'), 2000); // Redirect to /pricing if subscription is inactive
                return; // Stop further execution
            }
    
            // Proceed with role-based redirection if subscription is active
            toast.success('Login Successfully!');
            setTimeout(() => {
                if (loginUser.fulfilled.match(resultAction)) {
                    if (user && Array.isArray(user.roles)) {
                        if (user.roles.includes('Super Admin')) {
                            navigate('/admin/dashboard');
                        } else if (user.roles.includes('Admin')) {
                            navigate('/employee/dashboard');
                        } else if (user.roles.includes('Parent')) {
                            navigate('/login/parent/dashboard/profile');
                        } else if (user.roles.includes('Student')) {
                            navigate('/login/student/dashboard/profile');
                        }
                    }
                }
            }, 2000);
        } else if (resultAction.payload && resultAction.payload.code === 400) {
            toast.error('The email or password you entered is incorrect. Please try again!');
            setIsLoading(false); // Return button to normal state

        } else if (loginError) {
            toast.error(loginError); 
            setIsLoading(false); // Return button to normal state

        }
    };
    
    return (
        <>
            <ToastContainer position='top-center' autoClose={2000} theme='light' pauseOnHover={false} /> 
            <div className=''>
                <form className="bg-[#616161] shadow-2xl opacity-60 rounded-xl py-7 px-5 border md:py-7 md:px-8 xl:py-6 xl:px-10 2xl:py-20 2xl:px-20" onSubmit={handleSubmit}>
                    <div className="flex flex-col justify-center text-center pt-5 mb-2 md:pt-0">
                        <h4 className="font-bold text-center mb-6 text-2xl md:text-2xl xl:text-3xl 2xl:text-5xl text-[#fff]">Log In</h4>
                        <span className='flex flex-col justify-center text-[#fff] font-thin text-base md:text-base lg:text-base xl:text-lg 2xl:text-4xl'>
                            <p>Please enter your email address </p>
                            <p>address and password</p>
                        </span>
                    </div>
                    <div className="w-full flex items-center flex-col mb-2 mt-4 space-y-4 md:space-y-4 xl:space-y-3 2xl:space-y-6">
                        <div className="w-full">
                            <div className="w-full flex items-center bg-gray-100 border-gray-200 rounded-xl justify-between border p-2 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <HiOutlineMail color="black" />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} 
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px]"
                                        placeholder="Email Address"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-full flex items-center justify-between bg-gray-100 border-gray-200 rounded-xl p-2 2xl:p-4">
                                <div className="w-1/6 2xl:text-3xl">
                                    <CiLock color="black" />
                                </div>
                                <div className="w-full flex items-center">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full outline-none text-black placeholder-grey text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[25px] bg-transparent"
                                        placeholder="Enter Your Password"
                                        required
                                    />
                                    <div className="cursor-pointer ml-2 2xl:text-2xl" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <FaRegEyeSlash color="black" /> : <FaRegEye color="black" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row space-x-3 justify-between w-full items-center text-center md:space-x-4 xl-space-x-6 2xl:space-x-8">
                            <div className="flex flex-row items-center">
                                <label className="relative flex items-center pr-1 rounded-full cursor-pointer" htmlFor="check">
                                    <input
                                    type="checkbox"
                                    className="h-3 w-3 2xl:h-4 2xl:w-4 cursor-pointer appearance-none rounded-lg border bg-white border-black transition-all peer"
                                    id="check"
                                    />
                                    <span className="absolute text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-1/4 -translate-y-2/4 -translate-x-1/4 peer-checked:opacity-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 2xl:h-4 2xl:w-4" viewBox="0 0 20 20" fill="currentColor"
                                        stroke="currentColor" strokeWidth="1">
                                        <path fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"></path>
                                    </svg>
                                    </span>
                                </label>
                                <label className="text-[#fff] cursor-pointer font-light select-none text-[12px] md:text-[15px] lg:text-[15px] xl:text-[15px] 2xl:text-[22px]" htmlFor="check">
                                    Remember Me
                                </label>
                            </div>
                            <Link to="/forgetpassword">
                                <button className="cursor-pointer font-light text-[12px] md:text-[15px] lg:text-[15px] xl:text-[15px] 2xl:text-[22px] text-[#fff]">
                                    Forgot Password
                                </button>
                            </Link>
                        </div>
                    </div>
                    {/* Button with spinner */}
                    <div className="w-full flex items-center justify-center pt-3 mb-1 cursor-pointer">
                        <div className={`w-full border border-primary_color rounded-xl p-1 text-sm flex items-center text-center text-primary_color font-semibold transition-all duration-300 md:text-sm xl:text-base 2xl:text-xl ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-white hover:bg-black hover:text-white'}`}>
                            <Button
                                type="submit"
                                className="w-full"
                                btnTitle={isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <svg className="animate-spin h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0h-2a6 6 0 00-12 0H4z"></path>
                                        </svg>
                                        <span>Loading...</span>
                                    </div>
                                ) : "Proceed"}
                                disabled={isLoading} // Disable button when loading
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-center pt-4">
                        <Link className="text-center text-xs md:text-sm xl:text-[15px] 2xl:text-[22px] text-[#fff] cursor-pointer" to="/signup">
                            Don’t have an account? <span className='text-[#fff]'>Sign up!</span>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default UserTypeForm;
