import {
  BiLogoFacebookCircle,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiMenu,
  BiMessageAlt,
  BiSearch
} from "react-icons/bi";
import { MdAdd, MdCancel, MdCheckCircle, MdClose, MdLocationOn, MdMail, MdOutlineChangeCircle, MdOutlineCircle, MdOutlinePlayCircle, MdPeople } from "react-icons/md"
import { FaArrowCircleLeft, FaEnvelope, FaHashtag, FaPhone } from "react-icons/fa"
import { RiPencilLine } from "react-icons/ri";
import { IoIosArrowDown, IoIosArrowRoundBack, IoIosArrowUp, IoMdLock, } from "react-icons/io"
import { IoEyeSharp, IoEyeOffSharp, IoNotificationsOutline, IoEllipsisVertical, IoDocumentText } from "react-icons/io5"
import { RiCalendar2Fill, RiGraduationCapFill } from "react-icons/ri"
import { PiBag, PiHandsPraying } from "react-icons/pi"
import { CgRing } from "react-icons/cg"
import { FaUser } from "react-icons/fa6"
import { GoHomeFill } from "react-icons/go"
export const MenuIcon = ({ size, color, onClick, style, className }) => {
  return (
    <BiMenu
      size={size}
      color={color}
      onClick={onClick}
      className={className}
      style={style}
    />
  );
};
export const LinkedIn = ({ size, color, onClick, style, className }) => {
  return (
    <BiLogoLinkedin
      size={size}
      color={color}
      onClick={onClick}
      className={className}
      style={style}
    />
  );
};
export const FaceBookIcon = ({ size, color, onClick, style, className }) => {
  return (
    <BiLogoFacebookCircle
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const InstagramIcon = ({ size, color, onClick, style, className }) => {
  return (
    <BiLogoInstagram
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const TwitterIcon = ({ size, color, onClick, style, className }) => {
  return (
    <BiLogoTwitter
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const CancelIcon = ({ size, color, onClick, style, className }) => {
  return (
    <MdCancel
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const CheckIcon = ({ size, color, onClick, style, className }) => {
  return (
    <MdCheckCircle
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const MailIcon = ({ size, color, onClick, style, className }) => {
  return (
    <MdMail
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const BackIcon = ({ size, color, onClick, style, className }) => {
  return (
    <FaArrowCircleLeft
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const BackIcon2 = ({ size, color, onClick, style, className, cursor }) => {
  return (
    <IoIosArrowRoundBack
      size={size}
      color={color}
      cursor={cursor}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const LockIcon = ({ size, color, onClick, style, className }) => {
  return (
    <IoMdLock
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const EyeOpenIcon = ({ size, color, onClick, style, className }) => {
  return (
    <IoEyeSharp
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const EyeCloseIcon = ({ size, color, onClick, style, className }) => {
  return (
    <IoEyeOffSharp
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const PrayingIcon = ({ size, color, onClick, style, className }) => {
  return (
    <PiHandsPraying
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const LocationIcon = ({ size, color, onClick, style, className }) => {
  return (
    <MdLocationOn
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const GradCapIcon = ({ size, color, onClick, style, className }) => {
  return (
    <RiGraduationCapFill
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const PeopleIcon = ({ size, color, onClick, style, className }) => {
  return (
    <MdPeople
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const SuitCaseIcon = ({ size, color, onClick, style, className }) => {
  return (
    <PiBag
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const EnvelopIcon = ({ size, color, onClick, style, className }) => {
  return (
    <FaEnvelope
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const HashtagIcon = ({ size, color, onClick, style, className }) => {
  return (
    <FaHashtag
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const RingIcon = ({ size, color, onClick, style, className }) => {
  return (
    <CgRing
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const CalendarIcon = ({ size, color, onClick, style, className }) => {
  return (
    <RiCalendar2Fill
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const UserIcon = ({ size, color, onClick, style, className }) => {
  return (
    <FaUser
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const PhoneIcon = ({ size, color, onClick, style, className }) => {
  return (
    <FaPhone
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const HomeIcon = ({ size, color, onClick, style, className }) => {
  return (
    <GoHomeFill
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const PlayIcon = ({ size, color, onClick, style, className }) => {
  return (
    <MdOutlinePlayCircle
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const AddIcon = ({ size, color, onClick, style, className }) => {
  return (
    <MdAdd
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const CloseIcon = ({ size, color, onClick, style, className, cursor }) => {
  return (
    <MdClose
      cursor={cursor}
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const SearchIcon = ({ size, color, onClick, style, className }) => {
  return (
    <BiSearch
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const NotificationIcon = ({ size, color, onClick, style, className }) => {
  return (
    <IoNotificationsOutline
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const MessageIcon = ({ size, color, onClick, style, className }) => {
  return (
    <BiMessageAlt
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const DropDownIcon = ({ size, color, onClick, style, className }) => {
  return (
    <IoIosArrowDown
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const DropUpIcon = ({ size, color, onClick, style, className }) => {
  return (
    <IoIosArrowUp
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const ChangeIcon = ({ size, color, onClick, style, className }) => {
  return (
    <MdOutlineChangeCircle
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const HorizontalMenu = ({ size, color, onClick, style, className, cursor }) => {
  return (
    <IoEllipsisVertical
      cursor={cursor}
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const PenIcon = ({ size, color, onClick, style, className, cursor }) => {
  return (
    <RiPencilLine
      cursor={cursor}
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
export const DocumentIcon = ({ size, color, onClick, style, className, cursor }) => {
  return (
    <IoDocumentText
      cursor={cursor}
      size={size}
      color={color}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};
