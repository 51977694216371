import React from 'react';
import { AddIcon, CloseIcon } from "../../common/icons/icons";

class Faq extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openFaqIndex: null
    };
  }

  toggleHandler = (index) => {
    this.setState(prevState => ({
      openFaqIndex: prevState.openFaqIndex === index ? null : index
    }));
  };

  render() {
    const { openFaqIndex } = this.state;
    return (
      <div>
        <div className="w-full mb-6 border-b-grey border-b pb-4 2xl:pt-6">
          <div className="w-full flex items-center justify-between py-4" onClick={() => this.toggleHandler(0)}>
            <p className="text-base font-bold 2xl:text-2xl">What is the difference between the subscription plans?</p>
            {openFaqIndex === 0 ? <CloseIcon size={16} color={'black'} /> : <AddIcon size={16} color={'black'} />}
          </div>
          <div className={`w-full ${openFaqIndex === 0 ? 'max-h-fit' : 'max-h-0'} overflow-hidden transition-max-h`}>
            <p className="text-left text-xs 2xl:text-xl text-black leading-6">
              Our subscription plans are designed to cater to schools of varying sizes and needs. The Basic plan is ideal for small schools, while the Silver, Gold, and Platinum plans offer progressively more advanced features for larger schools and those requiring functionalities like course management, fee management, parent portals, and learning platform integration.
            </p>
          </div>
        </div>

        <div className="w-full mb-6 border-b-grey border-b pb-4">
          <div className="w-full flex items-center justify-between py-4" onClick={() => this.toggleHandler(1)}>
            <p className="text-base font-bold 2xl:text-2xl">Do you offer a free trial?</p>
            {openFaqIndex === 1 ? <CloseIcon size={16} color={'black'} /> : <AddIcon size={16} color={'black'} />}
          </div>
          <div className={`w-full ${openFaqIndex === 1 ? 'max-h-fit' : 'max-h-0'} overflow-hidden transition-max-h`}>
            <p className="text-left text-xs 2xl:text-xl text-black leading-6">
              Yes, we offer a limited-time free trial for the Basic and Silver plans. This is a great way to experience the software firsthand and see how it can benefit your school.
            </p>
          </div>
        </div>

        <div className="w-full mb-6 border-b-grey border-b pb-4">
          <div className="w-full flex items-center justify-between py-4" onClick={() => this.toggleHandler(2)}>
            <p className="text-base font-bold 2xl:text-2xl">What kind of support do you offer?</p>
            {openFaqIndex === 2 ? <CloseIcon size={16} color={'black'} /> : <AddIcon size={16} color={'black'} />}
          </div>
          <div className={`w-full ${openFaqIndex === 2 ? 'max-h-fit' : 'max-h-0'} overflow-hidden transition-max-h`}>
            <p className="text-left text-xs 2xl:text-xl text-black leading-6">
              We provide comprehensive support throughout the entire process, from pre-implementation to ongoing use. This includes needs assessment, system configuration, training resources, go-live support, staff training, user Q&A sessions, ongoing technical support via phone, email, and a ticketing system, a knowledge base with additional resources, and regular check-ins.
            </p>
          </div>
        </div>

        <div className="w-full mb-6 border-b-grey border-b pb-4">
          <div className="w-full flex items-center justify-between py-4" onClick={() => this.toggleHandler(3)}>
            <p className="text-base font-bold 2xl:text-2xl">Is the software secure?</p>
            {openFaqIndex === 3 ? <CloseIcon size={16} color={'black'} /> : <AddIcon size={16} color={'black'} />}
          </div>
          <div className={`w-full ${openFaqIndex === 3 ? 'max-h-fit' : 'max-h-0'} overflow-hidden transition-max-h`}>
            <p className="text-left text-xs 2xl:text-xl text-black leading-6">
              SM School Management ERP is a secure, cloud-based platform that utilizes industry-standard security measures to protect your data.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
