import user_icon from "../../../assets/images/no_user.png"
import Button from "../../../components/common/button/button.component";
import { deleteAccount } from "../../../services/delete_account";
const DeleteAccount = () => {
    const percentages = ["90%", "90%", "90%", "90%", "90%", "90%", "70%"]
    return (
        <>

            <div className="w-full h-screen flex flex-1 justify-center items-center " >
                <div className={" w-96 rounded-md bg-white shadow m-auto px-10 pt-10 "}  >
                    <div className="w-full" >
                        <h3 className="text-2xl font-bold text-center" >Delete Profile?</h3>
                        <p className="text-sm text-light_grey font-bold text-center mt-2" >Deleting your profile will remove all personal data</p>
                    </div>
                    <div style={{ background: "#f0f0f0" }} className="w-full mt-4 rounded-md  flex flex-col justify-center items-start px-3 py-5 " >
                        <img src={user_icon} style={{ objectFit: "contain" }} className="w-14 h-14 mx-auto mb-3 " />
                        {percentages.map((item) => (
                            <div key={Math.random()} style={{ width: item, height: '5px', background: "#dedede", borderRadius: 10, marginBottom: 12 }} />
                        ))}
                    </div>

                    <div className="w-full mt-5 flex flex-col items-center " >
                        <Button onClick={()=>deleteAccount()} btnTitle={'Delete'} className={'bg-primary_color  rounded-md w-3/4 mb-3 text-white shadow p-2'} />
                        <Button btnTitle={'Cancel'} className={'bg-none rounded-md mb-2  w-3/4 text-black p-3 font-bold'} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeleteAccount;