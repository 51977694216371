import profile_image from "../../../../assets/images/profile_image.png"
import { PenIcon } from "../../../common/icons/icons";
const ProfileImage = () => {
    return (
        <>
            <div className=" w-2/6 relative "  >
                <img src={profile_image} className="  object-contain w-full h-full" alt="profile_image" />
                <div className="absolute z-10 p-2 bg-primary_color rounded top-0  flex items-center justify-center gap-3 cursor-pointer w-24 " >
                    <p className=" text-sm text-white" >Edit</p>
                    <PenIcon color={'white'} size={18} />
                </div>
            </div>
        </>
    );
}

export default ProfileImage;