import axios from 'axios';

export const register = async (userData) => {
    try {
        const response = await axios.post('https://api.mystarsonline.com/api/Signup/create-tenant', userData);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};
