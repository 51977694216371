import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const TermsAndConditions = ({ onClose }) => {
  return (
    <div className="bg-white rounded-2xl p-6 mx-auto shadow-md h-auto max-w-xl lg:max-w-lg 2xl:max-w-4xl ">
      <div className="flex justify-end">
        <span className="bg-purple-600 rounded-full p-2 cursor-pointer inline-flex items-center justify-center">
          <AiOutlineClose className="text-white" onClick={onClose} />
        </span>
      </div>
      <div className="mb-3">
        <h2 className="text-center text-xl lg:text-2xl 2xl:text-3xl font-bold text-primary_color">TERMS & CONDITION</h2>
      </div>
      <div className="text-left space-y-4 2xl:space-y-6 text-sm text-gray-800 overflow-y-auto scrollbar-thin  scrollbar-thumb-primary_color scrollbar-track-white" style={{ maxHeight: '300px' }}>
        <ol className="list-decimal list-inside space-y-2">
          <li>
            <strong>Acceptance of Terms:</strong> By using MyStar School Management System, you agree to comply with and be bound by these terms and conditions.
          </li>
          <li>
            <strong>User Responsibilities:</strong> Users are responsible for maintaining the confidentiality of their accounts. Any unauthorized use must be reported immediately.
          </li>
          <li>
            <strong>Data Accuracy:</strong> Users are responsible for the accuracy of the information entered. MyStar is not liable for any inaccuracies or consequences arising from them.
          </li>
          <li>
            <strong>System Integrity:</strong> Users agree not to disrupt the integrity or security of the system. Unauthorized access or attempts will result in account termination.
          </li>
          <li>
            <strong>Privacy:</strong> MyStar respects user privacy. Data is handled according to our Privacy Policy.
          </li>
          <li>
            <strong>Updates and Modifications:</strong> MyStar reserves the right to update or modify the system and its terms. Users will be notified of significant changes.
          </li>
          <li>
            <strong>Termination:</strong> MyStar may terminate access for non-compliance with these terms.
          </li>
        </ol>
        <p className="mt-4 ">
          By using MyStar, you acknowledge and agree to these terms and conditions.
        </p>
      </div>
      <div className="flex justify-center mt-6">
        <button
          className="bg-purple-600 text-white px-4 py-2 rounded-xl cursor-pointer"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TermsAndConditions;
