import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

function Chart() {
	const data = {
		labels: ["Active", "Inactive"],
		datasets: [
			{
				label: "Schools Status",
				data: [65, 35], // Example data values for Active and Inactive
				backgroundColor: ["#4caf50", "#f44336"], // Green for active, red for inactive
				borderWidth: 1,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		cutout: "90%", // Adjust this percentage to change the thickness
		plugins: {
			legend: {
				position: "bottom",
				labels: {
					usePointStyle: true,
					pointStyle: "circle",
				},
			},
		},
	};

	return (
		<div className="border rounded-2xl shadow-md gap-4 py-2  sm:py-10   flex flex-col justify-between h-full col-span-1">
			{/* <div className="border rounded-2xl shadow-md grow "> */}
			<div className="text-start pl-4 lg:pl-10 ">
				{/* <div className='text-center 2xl:text-3xl font-bold'> */}
				Active vs Inactive Schools
			</div>
			{/* <div className="pt-10 h-[330px]   md:h-[330px] "> */}
			<div className=" h-full relative  flex max-h-[40rem]  justify-center items-center  lg:max-h-max  ">
				<Doughnut data={data} options={options} className="" />
				{/* <div className="absolute  rounded-full border border-gray-400 aspect-square w-[80px] sm:w-[100px] h-auto top-[30%] sm:top-[25%] ">
					{" "}
				</div> */}
			</div>
		</div>
	);
}

export default Chart;
