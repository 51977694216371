import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressReport = () => {
    const subjects = [
        'English',
        'Math',
        'Science',
        'Biology',
        'Logic',
        'Verbal Reasoning',
        'ICT',
        'Art',
        'Statistics',
        'History',
    ];

    const progressData = [
        80, // Progress for English
        75, // Progress for Math
        90, // Progress for Science
        65, // Progress for Biology
        85, // Progress for Logic
        70, // Progress for Verbal Reasoning
        95, // Progress for ICT
        60, // Progress for Art
        88, // Progress for Statistics
        72, // Progress for History
    ];

    return (
        <>
            <div className=" w-3/5 rounded-md bg-soft_grey p-4">
                <div className="w-full">
                    <p className="text-primary_color text-left font-bold text-lg">Progress Report</p>
                </div>

                {/* Subject Progress Table */}


                <div className='w-full mt-4' >
                    <div className='w-full flex items-center justify-between' >
                        <p className=' text-black text-base font-semibold' >Subject</p>
                        <p className=' text-black text-base font-semibold' >Progress</p>
                    </div>
                    <div className='w-full flex items-center justify-between' >
                        <div className='w-1/2' >
                            <ul className='p-0 m-0 w-full flex flex-col items-start ' >
                                {subjects.map((item, index) => (
                                    <li className=' list-none text-sm mt-3' key={index} >{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className='w-1/2' >
                            <ul className='p-0 m-0 w-full flex flex-col items-end' >
                                {subjects.map((item, index) => (
                                    <li className=' list-none text-sm gap-3 flex mt-3' >{progressData[index]}%
                                        <TubeProgressBar percentage={progressData[index]} /></li>

                                ))}
                            </ul>
                        </div>

                    </div>


                </div>

            </div>
        </>
    );
};

export default ProgressReport;



const TubeProgressBar = ({ percentage }) => {
    return (
        <div className='w-full flex items-center' >
            <div className="relative bg-light_grey rounded-full h-1 overflow-hidden w-32 ">
                <div
                    className="absolute left-0 top-0 bg-green h-full transition-width duration-500"
                    style={{ width: `${percentage}%` }}
                >

                </div>
            </div>
        </div>
    );
};

